import { differenceInMinutes, isPast } from '@awork/_shared/functions/date-fns-wrappers'
import { Plan, PlanId, PlanPeriod, PlanPrefix, PlanStatus, PlanTier, PlanVersion } from './subscription.types'
import {
  businessFeatureRestrictions,
  connectFeatureRestrictions,
  FeatureRestrictions,
  teamFeatureRestrictions
} from './feature-restriction.types'

// Object model for the workspaces plan subscription
export interface IQSubscription {
  workspaceId: string
  planId: PlanId
  initialPlanId?: PlanId
  status: PlanStatus
  previousStatus: PlanStatus
  trialEnd: Date
  contractEnd: Date
  remainingUsers: number
  remainingGuests: number
  bookedSeats: number
  usedUserSeats: number
  usedGuestSeats: number
  totalProjects: number
  usedPaidTrial: boolean
  customDomainEnabled?: boolean
  referralCode?: string
}

export class QSubscription {
  workspaceId: string
  planId: PlanId
  initialPlanId?: PlanId
  status: PlanStatus
  previousStatus: PlanStatus
  trialEnd: Date
  contractEnd: Date
  remainingUsers: number
  remainingGuests: number
  bookedSeats: number
  usedUserSeats: number
  usedGuestSeats: number
  totalProjects: number
  usedPaidTrial: boolean
  customDomainEnabled?: boolean
  referralCode?: string

  constructor(subscription: IQSubscription) {
    Object.assign(this, subscription)

    if (this.contractEnd) {
      this.contractEnd = new Date(this.contractEnd)
    }

    if (this.trialEnd) {
      this.trialEnd = new Date(this.trialEnd)
    }
  }

  /**
   * Returns whether the plan has a specific status
   * @param {PlanStatus} planStatus
   * @returns {boolean}
   */
  isPlanStatus(planStatus: PlanStatus): boolean {
    return this.status && this.status === planStatus
  }

  /**
   * Returns whether the plan has a specific previous status
   * @param {PlanStatus} planStatus
   * @returns {boolean}
   */
  isPreviousPlanStatus(planStatus: PlanStatus): boolean {
    return this.previousStatus && this.previousStatus === planStatus
  }

  /**
   * Returns whether the plan has a specific plan
   * @param {Plan} plan
   * @returns {boolean}
   */
  isPlan(plan: Plan): boolean {
    if (plan === Plan.Free) {
      return this.planId && this.planId === PlanId.QLegacyFreeMonthly
    }

    if (plan === Plan.LegacyEnterprise) {
      return this.planId && this.planId.includes(`-${Plan.LegacyEnterprise}-`)
    }

    return this.planId && this.planId.includes(plan)
  }

  /**
   * Returns whether the plan has a specific initial plan id
   * @param {Plan} plan
   * @returns {boolean}
   */
  isInitialPlan(plan: Plan): boolean {
    return this.initialPlanId && this.initialPlanId.includes(plan)
  }

  /**
   * Returns whether the plan has initial plan connect
   * @returns {boolean}
   */
  isInitialPlanConnect(): boolean {
    const matchesAnyConnectPlan = [
      Plan.Connect,
      Plan.ConnectEUR2025,
      Plan.ConnectGBP2025,
      Plan.ConnectGBP2025,
      Plan.ConnectUSD2025
    ].some(plan => this.isInitialPlan(plan))

    return matchesAnyConnectPlan
  }

  /**
   * Returns whether the plan has a specific plan tier
   * @param {PlanTier} planTier
   * @returns {boolean}
   */
  isPlanTier(planTier: PlanTier): boolean {
    return this.getPlanTier() === planTier
  }

  /**
   * Returns whether the plan is part of a specific plan version
   * @param {PlanVersion} planVersion
   * @returns {boolean}
   */
  isPlanVersion(planVersion: PlanVersion): boolean {
    const planVersions = {
      [PlanVersion.Legacy]: [
        Plan.Free,
        Plan.NewFree,
        Plan.Internal,
        Plan.LegacyStandard,
        Plan.LegacyEnterprise,
        Plan.LegacyBuhl
      ],
      [PlanVersion.Current]: [Plan.Team, Plan.Business, Plan.Enterprise, Plan.Connect]
    }

    return planVersions[planVersion].includes(this.getPlan())
  }

  /**
   * Returns whether the subscription plan is one of the connect plans
   * @returns {boolean}
   */
  get isConnectPlan(): boolean {
    const connectPlans = [
      Plan.Connect,
      Plan.ConnectEUR2025,
      Plan.ConnectCHF2025,
      Plan.ConnectUSD2025,
      Plan.ConnectGBP2025
    ]

    return connectPlans.some(plan => this.isPlan(plan))
  }

  get isPaidPlan(): boolean {
    return !this.isAnyFreePlan && !this.isConnectPlan
  }

  get isAnyFreePlan(): boolean {
    return this.isPlan(Plan.NewFree) || this.isPlan(Plan.Free)
  }

  get isInternalPlan(): boolean {
    return this.isPlan(Plan.Internal)
  }

  get isBasicPlan(): boolean {
    return this.isPlan(Plan.Team) || this.isAnyFreePlan || this.isConnectPlan
  }

  get isCancelled(): boolean {
    return !this.isPlan(Plan.Internal) && this.isPlanStatus(PlanStatus.Cancelled)
  }

  get hasValidContract(): boolean {
    const isCancelled = this.isCancelled && !this.isPreviousPlanStatus(PlanStatus.InTrial)
    const contractEnded = this.contractEnd && isPast(this.contractEnd)

    return !isCancelled && !contractEnded
  }

  /**
   * Gets the trial remaining days if the trial is used and the current plan is paid
   */
  get trialEndDays(): number {
    if (this.usedPaidTrial && !this.isAnyFreePlan && this.trialEnd) {
      const minutesLeft = differenceInMinutes(this.trialEnd, new Date())
      return minutesLeft > 0 ? Math.ceil(minutesLeft / 60 / 24) : 0
    } else {
      return null
    }
  }

  /**
   * Determines if the the workspace can use the premium trial
   */
  get isTrialEligible(): boolean {
    return (this.isPlan(Plan.NewFree) || this.isConnectPlan) && !this.trialEnd
  }

  /**
   * Determines if the workspace can use a referral code
   **/
  get isReferralEligible(): boolean {
    const hasReferralCode = !!this.referralCode
    return (
      this.isPlan(Plan.NewFree) ||
      this.isPlanStatus(PlanStatus.InTrial) ||
      this.isConnectPlan ||
      (this.getPlanPeriod() === 1 && hasReferralCode)
    )
  }

  /**
   * Determines if the basic connect free trial was used
   * @returns {boolean}
   */
  get usedBasicConnectTrial(): boolean {
    return (this.isConnectPlan && this.usedPaidTrial) || this.isBasicConnectInTrial
  }

  /**
   * Determines if the basic connect is in trial
   * @returns {boolean}
   */
  get isBasicConnectInTrial(): boolean {
    return this.isPlan(Plan.Business) && this.isPlanStatus(PlanStatus.InTrial) && this.isInitialPlanConnect()
  }

  /**
   * Determines if the basic connect trial (Business Trial) is expired
   * @returns {boolean}
   */
  get isBasicConnectTrialExpired(): boolean {
    return (
      this.isPlan(Plan.Business) &&
      this.isPlanStatus(PlanStatus.Cancelled) &&
      this.isPreviousPlanStatus(PlanStatus.InTrial) &&
      this.isInitialPlanConnect()
    )
  }

  /**
   * Determines if the plan has a volume discount
   */
  get isVolumeDiscountPlan(): boolean {
    return this.planId?.includes('-volume-')
  }

  /**Map
   * Gets a plan id based on a plan and a period
   * @param {Plan} plan
   * @param {number} period
   * @returns {string}
   */
  getPlanIdByPeriod(plan: Plan, period: number): string {
    const planPrefix = this.planId.includes('awork-') ? 'awork' : 'q'

    if (plan === Plan.Free) {
      return `${planPrefix}-${plan}-1`
    }

    if (this.isPlanVersion(PlanVersion.Current)) {
      return `${plan}-${period}`
    }

    return `${planPrefix}-${plan}-${period}`
  }

  /**
   * Gets the plan based on the plan id
   * @returns {Plan}
   */
  getPlan(): Plan {
    if (!this.planId) {
      return undefined
    }

    if (!this.planId.includes('-')) {
      return this.planId as unknown as Plan
    }

    const splitPlanId = this.planId.split('-')

    const isLegacyPlan = [
      Plan.LegacyStandard,
      Plan.LegacyEnterprise,
      Plan.LegacyBuhl,
      Plan.Free,
      Plan.NewFree,
      Plan.Internal
    ].includes(splitPlanId[1] as Plan)

    return (isLegacyPlan ? splitPlanId[1] : `${splitPlanId[0]}-${splitPlanId[1]}`) as Plan
  }

  /**
   * Gets the plan period based on the current plan
   * @returns {PlanPeriod}
   */
  getPlanPeriod(): PlanPeriod {
    if (!this.planId || !this.planId.includes('-')) {
      return PlanPeriod.Monthly
    }

    return parseInt(this.planId.split('-').pop()) as PlanPeriod
  }

  /**
   * Gets the plan tier based on the current plan
   * @returns {PlanTier}
   */
  getPlanTier(): PlanTier {
    const mapPlanToPlanTier: { [key in Plan]: PlanTier } = {
      [Plan.Free]: PlanTier.Free,
      [Plan.NewFree]: PlanTier.Free,
      [Plan.Internal]: PlanTier.Free,
      [Plan.LegacyBuhl]: PlanTier.Team,
      [Plan.LegacyStandard]: PlanTier.Business,
      [Plan.LegacyEnterprise]: PlanTier.Enterprise,
      [Plan.Team]: PlanTier.Team,
      [Plan.Business]: PlanTier.Business,
      [Plan.Enterprise]: PlanTier.Enterprise,
      [Plan.Connect]: PlanTier.Team,
      [Plan.ConnectEUR2025]: PlanTier.Team,
      [Plan.ConnectGBP2025]: PlanTier.Team,
      [Plan.ConnectUSD2025]: PlanTier.Team,
      [Plan.ConnectCHF2025]: PlanTier.Team,
      [Plan.AworkBusiness]: PlanTier.Business
    }

    return mapPlanToPlanTier[this.getPlan()] ?? PlanTier.Team
  }

  /**
   * Returns whether the specified feature is enabled for the current plan
   * @param {FeatureRestrictions} feature
   * @returns {boolean}
   */
  isFeatureEnabled(feature: FeatureRestrictions): boolean {
    if (
      !this.isPlanVersion(PlanVersion.Current) ||
      this.isPlanTier(PlanTier.Enterprise) ||
      this.isPlanStatus(PlanStatus.InTrial)
    ) {
      return true
    }

    return this.isPlan(Plan.Business)
      ? !businessFeatureRestrictions.includes(feature)
      : !teamFeatureRestrictions.includes(feature)
  }

  /**
   * Return a new subscription based on the plan id
   * @param {PlanId} planId
   * @returns {QSubscription}
   */
  static fromPlanId(planId: PlanId): QSubscription {
    return new QSubscription({ planId } as IQSubscription)
  }

  /**
   * Returns a new QSubscription instance based on a plan
   * @param {Plan} plan
   * @param {PlanPeriod} period
   * @param {PlanPrefix} prefix
   * @returns {QSubscription}
   */
  static fromPlan(plan: Plan, period = PlanPeriod.Monthly, prefix = PlanPrefix.Q): QSubscription {
    const isLegacyPlan = [Plan.LegacyStandard, Plan.LegacyEnterprise, Plan.Free, Plan.NewFree, Plan.Internal].includes(
      plan
    )

    const planId = isLegacyPlan ? `${prefix}-${plan}-${period}` : `${plan}-${period}`
    return new QSubscription({ planId } as IQSubscription)
  }

  /**
   * Determines the required plan tier of a feature
   * @param {FeatureRestrictions} feature
   */
  static getRequiredPlanTierForFeature(feature: FeatureRestrictions): PlanTier {
    if (teamFeatureRestrictions.includes(feature)) {
      return PlanTier.Business
    }

    if (businessFeatureRestrictions.includes(feature)) {
      return PlanTier.Enterprise
    }

    if (connectFeatureRestrictions.includes(feature)) {
      return PlanTier.Business
    }

    return null
  }
}

export interface QSubscriptionDetails {
  workspaceName: string
  nextBilling: Date
  upcomingChanges: QSubscriptionUpcomingChanges
  workspaceId: string
  planId: PlanId
  status: PlanStatus
  contractEnd: Date
  bookedSeats: number
  mrr?: number
  discount?: number
}

export interface QSubscriptionUpcomingChanges {
  planId: PlanId
  bookedSeats: number
}
