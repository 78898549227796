import { patchState, signalStore, WritableStateSource } from '@ngrx/signals'
import {
  addEntities,
  EntityId,
  EntityMap,
  removeAllEntities,
  removeEntities,
  setAllEntities,
  updateEntities,
  withEntities
} from '@ngrx/signals/entities'
import { computed, inject, Signal, signal, Type } from '@angular/core'
import { Observable } from 'rxjs'
import { toObservable } from '@angular/core/rxjs-interop'
import { coerceArray, isFunction } from '@awork/core/state/signal-store/helpers'
import { isStoredEntityOutdated, syncStore } from '@awork/core/state/signal-store/sync-store'
import { EntitySignalStoreHistory } from '@awork/core/state/signal-store/entitySignalStoreHistory'
import { SignalStoreService } from '@awork/core/state/signal-store/signalStore.service'
import { updateState, withDevtools } from '@angular-architects/ngrx-toolkit'
import { withEntityStorage } from '@awork/core/state/signal-store/withStorage'
import { withNoop } from '@awork/core/state/signal-store/withNoop'
import { EntityProps, GenericEntity, SyncOptions } from '@awork/core/state/signal-store/types'

type StoreType<Entity> = Type<
  {
    entityMap: Signal<EntityMap<Entity>>
    ids: Signal<EntityId[]>
    entities: Signal<Entity[]>
  } & WritableStateSource<{
    entityMap: EntityMap<Entity>
    ids: EntityId[]
  }>
>

export type Mapper<T> = (entity: T) => T

export interface StoreOptions<Entity> {
  name: string
  resettable?: boolean
  entityConstructor?: Mapper<Entity>
  persistDelay?: number
  persistActiveState?: boolean
  excludePersistProps?: (keyof Entity)[]
}

/**
 * Signal store for entities.
 * Provides functions to set, add, update and delete entities.
 *
 * ### Usage example
 * ```ts
 * @Injectable({ providedIn: 'root' })
 * export class UserStore extends EntitySignalStore<User> {
 *   constructor() {
 *     super({
 *       resettable: true,
 *       name: 'user',
 *       entityConstructor: user => new User(user, 'User store')
 *     })
 *   }
 * }
 * ```
 */
export class EntitySignalStore<Entity extends GenericEntity> {
  private StoreFactory: StoreType<Entity>
  private store: InstanceType<typeof this.StoreFactory>

  private storeOptions: StoreOptions<Entity>

  private activeId = signal<string>(null)
  protected isLoading = signal(true)
  private error = signal(null)

  entities$: Observable<Entity[]>
  entitiesMap$: Observable<EntityMap<Entity>>
  isLoading$: Observable<boolean>
  error$: Observable<Error>

  readonly history: EntitySignalStoreHistory<Entity>

  private signalStoreService = inject(SignalStoreService<Entity>)

  private readonly DEFAULT_STORE_OPTIONS: Partial<StoreOptions<Entity>> = {
    resettable: true,
    persistActiveState: true,
    entityConstructor: entity => entity
  }

  private integrateDevtools: boolean

  constructor(storeOptions?: StoreOptions<Entity>) {
    this.applyStoreOptions(storeOptions)
    this.createStore()
    this.initObservables()

    this.history = new EntitySignalStoreHistory(this)
    this.signalStoreService.registerStore(this.storeOptions.name, this)
  }

  /**
   * Applies the store options, merging them with the default store options
   * @param {StoreOptions<Entity>} storeOptions
   */
  private applyStoreOptions(storeOptions: StoreOptions<Entity>): void {
    this.storeOptions = {
      ...this.DEFAULT_STORE_OPTIONS,
      ...storeOptions
    }
  }

  /**
   * Creates the signal store
   */
  private createStore(): void {
    this.integrateDevtools = localStorage.getItem('awReduxDevtools') === 'true'

    this.StoreFactory = signalStore(
      { protectedState: false },
      this.integrateDevtools ? withDevtools(this.storeOptions.name) : withNoop(),
      withEntities<Entity>(),
      withEntityStorage(this, {
        key: this.storeOptions.name,
        persistDelay: this.storeOptions.persistDelay,
        persistActiveState: this.storeOptions.persistActiveState,
        excludeProps: this.storeOptions.excludePersistProps
      })
    )
    this.store = new this.StoreFactory()
  }

  /**
   * Initializes the observables used for RxJS interop
   */
  private initObservables(): void {
    this.entities$ = toObservable(this.store.entities)
    this.entitiesMap$ = toObservable(this.store.entityMap)
    this.isLoading$ = toObservable(this.isLoading)
    this.error$ = toObservable(this.error)
  }

  /**
   * Gets the entity constructor
   * @returns {Mapper<Entity>}
   */
  getEntityConstructor(): Mapper<Entity> {
    return this.storeOptions.entityConstructor
  }

  /**
   * Gets the store options
   * @returns {StoreOptions<Entity>}
   */
  getStoreOptions(): StoreOptions<Entity> {
    return this.storeOptions
  }

  get entities(): Signal<Entity[]> {
    return this.store.entities
  }

  get mappedEntities(): Signal<Entity[]> {
    return computed(() => this.store.entities().map(this.storeOptions.entityConstructor))
  }

  get entityMap(): Signal<Record<string, Entity>> {
    return this.store.entityMap
  }

  get ids(): Signal<string[]> {
    return this.store.ids as Signal<string[]>
  }

  /**
   * Hook that is called before adding an entity to the store
   * @param {Entity} newEntity
   * @returns {Entity}
   * @internal
   */
  preAddEntity(newEntity: Entity): Entity {
    return newEntity
  }

  /**
   * Applies the preAddEntity hook to the given entities before adding them to the store
   * @param {Entity[]} entities
   * @returns {Entity[]}
   */
  private applyPreAddEntity(entities: Entity[]): Entity[] {
    return entities.map(this.preAddEntity)
  }

  /**
   * Hook that is called before updating an entity in the store
   * @param {Entity} prevEntity
   * @param {Partial<Entity>} nextEntity
   * @returns {Partial<Entity>}
   * @internal
   */
  preUpdateEntity(prevEntity: Entity, nextEntity: Partial<Entity>): Partial<Entity> {
    return nextEntity
  }

  /**
   * Applies the preUpdateEntity hook to the given entity before updating it in the store
   * @param {Entity} prevEntity
   * @param {Partial<Entity> | ((entity: Entity) => Partial<Entity>)} newState
   * @returns {Partial<Entity>}
   */
  private applyPreUpdateEntity(
    prevEntity: Entity,
    newState: Partial<Entity> | ((entity: Entity) => Partial<Entity>)
  ): Partial<Entity> {
    const nextEntity = isFunction(newState) ? newState(prevEntity) : newState
    const mergedNextEntity = { ...prevEntity, ...nextEntity }
    return this.preUpdateEntity(prevEntity, mergedNextEntity)
  }

  /**
   * Fills the store with the given entities.
   * Also sets the loading state to false.
   * @param {Entity[]} entities
   * @param {string} action
   */
  set(entities: Entity[], action = this.storeOptions.name + ': set'): void {
    const newEntities = this.applyPreAddEntity(entities)

    this.integrateDevtools
      ? updateState(this.store, action, setAllEntities(newEntities))
      : patchState(this.store, setAllEntities(newEntities))

    this.setLoading(false)
  }

  /**
   * Sets the active entity id
   * @param {string} id
   */
  setActive(id: string): void {
    this.activeId.set(id)
  }

  /**
   * Gets the active entity id
   * @returns {Signal<string>}
   */
  getActive(): Signal<string> {
    return this.activeId
  }

  /**
   * Sets the loading state
   * @param {boolean} isLoading
   */
  setLoading(isLoading: boolean): void {
    this.isLoading.set(isLoading)
  }

  /**
   * Gets the loading state
   * @returns {Signal<boolean>}
   */
  getIsLoading(): Signal<boolean> {
    return this.isLoading
  }

  /**
   * Sets the error state
   * @param {Error} error
   */
  setError(error: Error): void {
    this.error.set(error)
  }

  /**
   * Gets the error state
   * @returns {Signal<Error>}
   */
  getError(): Signal<Error> {
    return this.error
  }

  /**
   * Adds the given entity or entities to the store
   * @param {Entity | Entity[]} entity
   * @param {string} action
   */
  add(entity: Entity | Entity[], action = this.storeOptions.name + ': add'): void {
    const newEntities = this.applyPreAddEntity(coerceArray(entity))

    this.integrateDevtools
      ? updateState(this.store, action, addEntities(newEntities))
      : patchState(this.store, addEntities(newEntities))
  }

  /**
   * Updates the entity or entities in the store
   *
   * @example
   * update('1', { value: 'newValue' })
   * update(['1', '2'], { value: 'newValue' })
   * update('1', entity => ({ value: entity.value + 'newValue' }))
   * update(entity => entity.value === 'value1', { value: 'newValue' })
   */
  update(id: string | string[], newState: Partial<Entity>, action?: string): void
  update(id: string | string[], newStateFn: (entity: Entity) => Partial<Entity>, action?: string): void
  update(
    id: string | string[],
    newStateOrFn: Partial<Entity> | ((entity: Entity) => Partial<Entity>),
    action?: string
  ): void
  update(predicate: (entity: Entity) => boolean, newState: Partial<Entity>, action?: string): void
  update(predicate: (entity: Entity) => boolean, newStateFn: (entity: Entity) => Partial<Entity>, action?: string): void
  update(
    idOrPredicate: string | string[] | ((entity: Entity) => boolean),
    newStateOrFn: Partial<Entity> | ((entity: Entity) => Partial<Entity>),
    action = this.storeOptions.name + ': update'
  ): void {
    let ids: string[]

    if (isFunction(idOrPredicate)) {
      ids = this.store
        .entities()
        .filter(idOrPredicate)
        .map(entity => entity.id)
    } else {
      ids = coerceArray(idOrPredicate)
    }

    if (!ids.length) {
      return
    }

    this.integrateDevtools
      ? updateState(
          this.store,
          action,
          updateEntities({
            ids,
            changes: prevEntity => this.applyPreUpdateEntity(prevEntity, newStateOrFn)
          })
        )
      : patchState(
          this.store,
          updateEntities({
            ids,
            changes: prevEntity => this.applyPreUpdateEntity(prevEntity, newStateOrFn)
          })
        )
  }

  /**
   * Adds or updates the entity or entities in the store
   * @param {string | string[]} id
   * @param {Partial<Entity> | ((entity: Entity) => Partial<Entity>)} newStateOrFn
   * @param {string} action
   *
   * @example
   * upsert('1', { value: 'newValue' })
   * upsert(['1', '2'], { value: 'newValue' })
   * upsert('1', entity => ({ value: entity.value + 'newValue' }))
   */
  upsert(
    id: string | string[],
    newStateOrFn: Partial<Entity> | ((entity: Entity) => Partial<Entity>),
    action = this.storeOptions.name + ': upsert'
  ): void {
    const ids = coerceArray(id)

    if (!ids.length) {
      return
    }

    const entityMap = this.store.entityMap()
    const predicate = (isUpdate: boolean) => (id: string) => (isUpdate ? !!entityMap[id] : !entityMap[id])

    const updateIds = ids.filter(predicate(true))
    const newEntities = ids.filter(predicate(false)).map(id => {
      return {
        id,
        ...(isFunction(newStateOrFn) ? newStateOrFn(this.store.entityMap()[id]) : newStateOrFn)
      } as Entity
    })

    this.update(updateIds, newStateOrFn, action)
    this.add(newEntities, action)
  }

  /**
   * Adds or updates the entities in the store
   * @param {Entity[]} entities
   * @param {string} action
   */
  upsertMany(entities: Entity[], action = this.storeOptions.name + ': upsertMany'): void {
    const entityMap = this.store.entityMap()
    const predicate = (isUpdate: boolean) => (id: string) => (isUpdate ? !!entityMap[id] : !entityMap[id])

    const updatedEntitiesMap = new Map<string, Entity>()
    const updateIds: string[] = []
    const newEntities: Entity[] = []

    entities.forEach(entity => {
      if (predicate(false)(entity.id)) {
        newEntities.push(entity)
        return
      } else {
        updatedEntitiesMap.set(entity.id, entity)
        updateIds.push(entity.id)
      }
    })

    this.update(updateIds, entity => updatedEntitiesMap.get(entity.id), action)
    this.add(newEntities, action)
  }

  /**
   * Removes the entity or entities from the store
   *
   * @example
   * remove('1')
   * remove(['1', '2'])
   * remove(entity => entity.value === 'value1')
   */
  remove(id: string | string[], action?: string): void
  remove(predicate: (entity: Entity) => boolean, action?: string): void
  remove(
    idOrPredicate: string | string[] | ((entity: Entity) => boolean),
    action = this.storeOptions.name + ': remove'
  ): void {
    const ids = isFunction(idOrPredicate)
      ? this.store
          .entities()
          .filter(idOrPredicate)
          .map(entity => entity.id)
      : coerceArray(idOrPredicate)

    if (!ids.length) {
      return
    }

    this.integrateDevtools
      ? updateState(this.store, action, removeEntities(ids))
      : patchState(this.store, removeEntities(ids))
  }

  /**
   * Syncs the entities with the store, adding, updating and removing entities as needed
   * @param {Entity[]} entitiesToSync
   * @param {Entity[]} storedEntities
   * @param {SyncOptions<Entity>} options
   */
  sync(entitiesToSync: Entity[], storedEntities: Entity[], options?: SyncOptions<Entity>): void {
    syncStore(entitiesToSync, storedEntities, this, options)
  }

  /**
   * Syncs a single entity with the store, adding or updating it as needed
   * @param {Entity} entity
   * @param {EntityProps<Entity>} compareProp
   * @param {string} action
   */
  syncEntity(
    entity: Entity,
    compareProp: EntityProps<Entity> = 'resourceVersion',
    action = this.storeOptions.name + ': syncEntity'
  ): void {
    this.upsert(
      entity.id,
      storedEntity => {
        if (isStoredEntityOutdated(storedEntity, entity, compareProp)) {
          return entity
        } else {
          return storedEntity
        }
      },
      action
    )
  }

  /**
   * Resets the store by removing all entities
   * @param {string} action
   */
  reset(action = this.storeOptions.name + ': reset'): void {
    this.integrateDevtools
      ? updateState(this.store, action, removeAllEntities())
      : patchState(this.store, removeAllEntities())

    this.activeId.set(null)
    this.isLoading.set(true)
    this.error.set(null)
  }
}
