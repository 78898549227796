import { Project } from '@awork/features/project/models/project.model'
import { ITimeBookingBase, TimeBookingBase } from '@awork/features/planner/models/time-booking.model.base'

export interface IUserTimeBooking {
  userId: string
  timeBookings: ITimeBooking[]
}

export interface ITimeBooking {
  id: string
  description: string
  duration: number
  project?: Project // Only for FE
}

export class TimeBooking extends TimeBookingBase implements ITimeBooking {
  id: string
  description: string
  duration: number
  project?: Project // Only for FE

  constructor(timeBooking: Partial<ITimeBooking & ITimeBookingBase>) {
    super(timeBooking)
  }
}
