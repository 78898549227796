import { ProjectBase } from '@awork/features/project/models/project.model.base'
import { TaskBase } from '@awork/features/task/models/task.model.base'

export interface ITimeBookingBase {
  id: string
  userId: string
  projectId: string
  project?: ProjectBase
  startDate: Date | string
  endDate: Date | string
  duration: number
  description?: string
  laneOrder: number
  autoLaneOrder: number
  createdOn: Date | string
  entityType: string
}

export class TimeBookingBase implements ITimeBookingBase {
  id: string
  userId: string
  projectId: string
  project?: ProjectBase
  task?: TaskBase
  startDate: Date
  endDate: Date
  duration: number
  description?: string
  laneOrder: number
  autoLaneOrder: number
  createdOn: Date | string
  entityType: string

  constructor(timeBooking: Partial<ITimeBookingBase>) {
    Object.assign(this, timeBooking)

    this.entityType = 'TimeBooking'

    if (typeof timeBooking.startDate === 'string') {
      this.startDate = new Date(timeBooking.startDate)
    }

    if (typeof timeBooking.endDate === 'string') {
      this.endDate = new Date(timeBooking.endDate)
    }

    if (typeof timeBooking.createdOn === 'string') {
      this.createdOn = new Date(timeBooking.createdOn)
    }
  }

  get startOn(): Date {
    return this.startDate ? new Date(this.startDate) : undefined
  }

  set startOn(value: Date) {
    this.startDate = value
  }

  get endOn(): Date {
    return this.endDate ? new Date(this.endDate) : undefined
  }

  set endOn(value: Date) {
    this.endDate = value
  }

  /**
   * Validates the properties for Api Requests
   * @returns {boolean}
   */
  get isValid(): boolean {
    const { userId, projectId, startDate, endDate, duration } = this
    return !!userId && !!projectId && !!startDate && !!endDate && duration >= 0
  }
}
