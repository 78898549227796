<figure *ngIf="user" class="avt" [ngClass]="'avt--' + size">
  <div class="aw-2-pos--relative">
    <img
      *ngIf="imageUrl; else initialsTemplate"
      class="avt__image"
      priority
      [ngSrc]="imageUrl"
      [attr.alt]="user.fullName"
      [width]="imgSize"
      [height]="imgSize"
      (error)="onError()"
    />

    <img
      *ngIf="user?.isExternal && size !== sizes.xs && size !== sizes.xxs"
      priority
      class="avt__workspace-image"
      [ngClass]="'avt__workspace-image--' + size"
      [ngSrc]="user.getWorkspaceImage()"
      [width]="subAvatarPixelSizes[size]"
      [height]="subAvatarPixelSizes[size]"
    />
  </div>

  <ng-template #initialsTemplate>
    <aw-avatar-initials [initials]="initials" [size]="size" />
  </ng-template>

  <figcaption
    *ngIf="showText"
    class="avt__text"
    [class.avt__text--multiline]="multiline"
  >
  <span *ngIf="showSecondaryText && user.position" class="avt__text__secondary">{{ user.position }}</span>
  <span
      class="avt__text__primary"
      [class.avt__text__link]="link"
      [ngStyle]="{ '-webkit-line-clamp': lines }"
      [style.--avt-text-color]="textColor ? 'var(--color-' + textColor + ')' : 'currentColor'"
      [attr.title]="user.fullName"
      (click)="linkClick($event)"
    >
      {{ user.fullName }}
    </span>
    <span
      *ngIf="tertiaryText"
      class="avt__text__secondary"
      [ngStyle]="{ '-webkit-line-clamp': lines }"
      [attr.title]="tertiaryText"
      (click)="linkClick($event)"
    >
      {{ tertiaryText }}
    </span>
  </figcaption>
</figure>

<figure
  *ngIf="!user"
  class="avt avt--deleted"
  [ngClass]="'avt--' + size"
  [style.height.px]="avatarPixelSizes[size]"
  [style.width.px]="avatarPixelSizes[size]"
>
  <i class="aw-2-icn aw-2-icn--{{ size }} aw-2-txt--blue">person</i>
</figure>
