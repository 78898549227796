import { computed, Injectable, Signal } from '@angular/core'
import { UserStore } from '@awork/features/user/state/user.store'
import { Observable, filter, map } from 'rxjs'
import { User } from '@awork/features/user/models/user.model'
import { AppQuery } from '@awork/core/state/app.query'
import type { LogService } from '@awork/_shared/services/log-service/log.service'
import { Absence } from '@awork/features/user/models/absence.model'
import { BrowserService } from '@awork/_shared/services/browser-service/browser.service'
import { SettingsQuery } from '@awork/framework/state/settings.query'
import { getLocalDateFromUTCString } from '@awork/_shared/functions/date-operations'
import { EntitySignalQuery } from '@awork/core/state/signal-store/entitySignalQuery'
import { deepEqual } from '@awork/_shared/functions/lodash'

export type UserStatuses = 'active' | 'deactivated' | 'invited'

export interface UserFilterOptions {
  onlyActive?: boolean
  includeExternal?: boolean
  usersToFilter?: string[]
}

export interface DateRangeFilter {
  startDate: Date
  endDate: Date
}

export interface UserRoleMapping {
  isDeactivated: boolean
  userId: string
  roleId: string
  name: string
}

export enum UserColumn {
  Teams = 'teams',
  Tags = 'tags',
  Email = 'email',
  Status = 'status',
  Capacity = 'capacity',
  Role = 'role',
  Position = 'position'
}

@Injectable({ providedIn: 'root' })
export class UserQuery extends EntitySignalQuery<User> {
  static instance: UserQuery // Used to query the store in models
  private logService: LogService

  constructor(
    protected store: UserStore,
    private appQuery: AppQuery,
    private settingsQuery: SettingsQuery,
    private browserService: BrowserService
  ) {
    super(store)

    UserQuery.instance = this
  }

  /**
   * Gets the visible columns in list
   * @returns {Signal<UserColumn[]>}
   */
  queryColumns(): Signal<UserColumn[]> {
    const defaultLargeList = [
      UserColumn.Tags,
      UserColumn.Role,
      UserColumn.Teams,
      UserColumn.Email,
      UserColumn.Status,
      UserColumn.Capacity
    ]

    return computed(() => {
      const listColumns = this.settingsQuery.queryListColumns('user')
      const largeListColumns = (listColumns()?.largeList as UserColumn[]) || defaultLargeList

      return largeListColumns
    })
  }

  /**
   * Selects current user
   * @param {boolean} filterSet - if true, only returns if user is set
   * @returns {Observable<User>}
   */
  selectCurrentUser(filterSet = true): Observable<User> {
    return this.selectActive().pipe(
      filter(user => !!user || !filterSet),
      map(user => this.mapEntity(user))
    )
  }

  /**
   * Selects current user
   * @param {boolean} filterSet - if true, only returns if user is set
   * @returns {Signal<User>}
   */
  queryCurrentUser(filterSet = true): Signal<User> {
    return computed(
      () => {
        const user = this.queryActive()

        if (!!user || !filterSet) {
          return this.mapEntity(user())
        }

        return user()
      },
      { equal: deepEqual }
    )
  }

  /**
   * Gets current user
   */
  getCurrentUser(): User {
    const currentUser = this.getActive()

    if (currentUser) {
      return this.mapEntity(currentUser)
    } else {
      if (this.appQuery.getIsLoggedIn()) {
        this.logService?.sendLogDNA(
          'ERROR',
          'Current user is null',
          {
            stack: JSON.stringify({
              IsLoggedIn: true,
              LastRoute: this.appQuery.getLastRoute(),
              IsInMSTeams: this.appQuery.getIsInMSTeams(),
              Traces: this.appQuery.getTraces()
            })
          },
          true
        )
      }

      // For the current user fallback I need to add some minimal information so it doesn't pops the onboarding screen
      return new User({ id: 'INVALID_ID', firstName: 'INVALID_NAME' }, 'UserQuery.getCurrentUser')
    }
  }

  /**
   * Sets the logService to avoid circular dependencies
   * @param {LogService} logService
   */
  setLogService(logService: LogService): void {
    this.logService = logService
  }

  /**
   * Selects user by id
   * @param {string} id
   */
  selectUser(id: string): Observable<User> {
    return this.selectEntity(id).pipe(map(user => this.mapEntity(user)))
  }

  /**
   * Gets user by id
   * @param {string} id
   * @returns {Signal<User>}
   */
  queryUser(id: string): Signal<User> {
    return computed(() => {
      const user = this.queryEntity(id)

      return this.mapEntity(user())
    })
  }

  /**
   * Gets user by id
   * @param {string} id
   */
  getUser(id: string): User {
    const user = this.getEntity(id)

    return this.mapEntity(user)
  }

  /**
   * Selects users by id
   * @param {string[]} ids
   * @param {string} searchQuery
   * @param {boolean} fullNameSort
   * @param {boolean} includeExternal
   * @returns {Observable<User[]>}
   */
  selectUsersById(
    ids: string[],
    searchQuery?: string,
    fullNameSort?: boolean,
    includeExternal = false
  ): Observable<User[]> {
    return this.selectAll({
      sortBy: (userA, userB) => this.getSort(userA, userB, fullNameSort),
      filterBy: user =>
        ids.includes(user.id) && this.searchFilter(user, searchQuery) && this.filterExternalUsers(user, includeExternal)
    }).pipe(map(users => this.mapEntities(users)))
  }

  /**
   * Gets users by id
   * @param ids
   */
  getUsersByIds(ids: string[], includeExternal = false): User[] {
    const users = this.getAll({
      sortBy: (userA, userB) => this.getSort(userA, userB),
      filterBy: user => ids.includes(user.id) && this.filterExternalUsers(user, includeExternal)
    })

    return this.mapEntities(users)
  }

  /**
   * Gets users by ids
   * @param {string[]} ids
   * @returns {Signal<User[]>}
   */
  queryUsersById(ids: string[]): Signal<User[]> {
    return computed(() => {
      const users = this.queryMany(ids)
      return this.mapEntities(users())
    })
  }

  /**
   * Gets all users
   * @param {boolean} includeExternal
   */
  getAllUsers(includeExternal = false): User[] {
    const users = this.getAll({
      filterBy: user => this.filterExternalUsers(user, includeExternal),
      sortBy: (userA, userB) => this.getSort(userA, userB)
    })

    return this.mapEntities(users)
  }

  /**
   * Selects all users
   * @param {number} limit
   * @param {string} searchQuery
   * @param {boolean} includeExternal
   * @param {UserRoleMapping[]} roles
   */
  selectAllUsers(
    limit?: number,
    searchQuery?: string,
    includeExternal = false,
    roles?: UserRoleMapping[]
  ): Observable<User[]> {
    return this.selectAll({
      sortBy: (userA, userB) => this.getSort(userA, userB),
      filterBy: user => this.searchFilter(user, searchQuery, roles) && this.filterExternalUsers(user, includeExternal),
      limitTo: limit
    }).pipe(map(users => this.mapEntities(users)))
  }

  /**
   * Query all users using Signals
   * @param {number} limit
   * @param {string} searchQuery
   * @param {boolean} includeExternal
   * @param {UserRoleMapping[]} roles
   * @returns {Signal<User[]>} - A signal containing the list of users.
   */
  queryAllUsers(
    limit?: number,
    searchQuery?: string,
    includeExternal: boolean = false,
    roles?: UserRoleMapping[]
  ): Signal<User[]> {
    const users = this.queryAll({
      sortBy: (userA: User, userB: User) => this.getSort(userA, userB),
      filterBy: (user: User) =>
        this.searchFilter(user, searchQuery, roles) && this.filterExternalUsers(user, includeExternal),
      limitTo: limit
    })

    return computed(() => this.mapEntities(users()))
  }

  /**
   * Query all users using Signals
   * @param {Object} [args] - The arguments for querying users.
   * @param {number} [args.limit] - The maximum number of users to return.
   * @param {string} [args.searchQuery] - The search query to filter users.
   * @param {boolean} [args.includeExternal=false] - Whether to include external users.
   * @param {UserRoleMapping[]} [args.roles] - The roles to filter users.
   * @param {string[]} [args.teamIds] - The team IDs to filter users.
   * @param {string[]} [args.tagNames] - The tag names to filter users.
   * @param {string[]} [args.userIds] - The user IDs to filter users.
   * @returns {Signal<User[]>} - A signal containing the list of users.
   */
  queryPlannerUsers(
    args: {
      limit?: number
      searchQuery?: string
      includeExternal?: boolean
      roles?: UserRoleMapping[]
      teamIds?: string[]
      tagNames?: string[]
      userIds?: string[]
    } = {}
  ): Signal<User[]> {
    const {
      limit,
      searchQuery = '',
      includeExternal = false,
      roles = [],
      teamIds = [],
      tagNames = [],
      userIds = []
    } = args

    const users = this.queryAll({
      sortBy: (userA, userB) => this.getSort(userA, userB),
      filterBy: user =>
        this.filterByTeamOrUserIds(user, { teamIds, userIds }) &&
        this.searchFilter(user, searchQuery, roles) &&
        this.filterExternalUsers(user, includeExternal) &&
        this.filterByTags(user, tagNames),
      limitTo: limit
    })

    return computed(() => this.mapEntities(users()))
  }

  /**
   * Selects users by team
   * @param {string[]} teamIds
   * @returns {Signal<User[]>}
   */
  queryUsersByTeams(teamIds: string[], limit?: number): Signal<User[]> {
    const users = this.queryAll({
      sortBy: (userA, userB) => this.getSort(userA, userB),
      filterBy: user => this.filterByTeams(user, teamIds),
      limitTo: limit
    })

    return computed(() => this.mapEntities(users()))
  }

  /**
   * Gets users by id
   * @param {string[]} ids
   * @param {boolean} includeExternal
   * @returns {Signal<User[]>}
   */
  queryUsersByIds(ids: string[], includeExternal: boolean = false): Signal<User[]> {
    const users = this.queryAll({
      filterBy: user => ids.includes(user.id) && this.filterExternalUsers(user, includeExternal)
    })

    return computed(() => this.mapEntities(users()))
  }

  /**
   * Selects the users by status
   * @param {UserStatuses | UserStatuses[]} status
   * @param {number} limit
   * @param {string} searchQuery
   * @param {string[]} excludedUserIds
   * @param {boolean} includeExternal
   * @param {UserRoleMapping[]} roles
   */
  selectByStatus(
    status?: UserStatuses | UserStatuses[],
    limit?: number,
    searchQuery?: string,
    excludedUserIds?: string[],
    includeExternal = false,
    roles?: UserRoleMapping[]
  ): Observable<User[]> {
    const query$ = status
      ? this.selectAll({
          filterBy: user => {
            return (
              this.statusFilter(user, status) &&
              this.searchFilter(user, searchQuery, roles) &&
              this.excludedUserFilters(user, excludedUserIds) &&
              this.filterExternalUsers(user, includeExternal)
            )
          },
          sortBy: (userA, userB) => this.getSort(userA, userB),
          limitTo: limit
        })
      : this.selectAllUsers(limit, searchQuery)

    return query$.pipe(map(users => this.mapEntities(users)))
  }

  /**
   * Gets the count of users
   * @param {UserStatuses | UserStatusesp[]} status
   * @param {string} searchQuery
   * @param {User[]} filterUsers
   * @returns {number}
   */
  getUsersCount(status?: UserStatuses | UserStatuses[], searchQuery?: string, filterUsers?: User[]): number {
    return this.getCount(
      user =>
        this.statusFilter(user, status) &&
        this.searchFilter(user, searchQuery) &&
        this.filterExternalUsers(user, false) &&
        (!filterUsers || !filterUsers.filter(fu => !!fu).some(fu => fu.id === user.id))
    )
  }

  /**
   * Selects users with at least one absence (within the date range if set)
   * @param {DateRangeFilter} dateRange
   * @param {UserFilterOptions} options
   * @param {boolean} options.onlyActive
   * @param {boolean} options.includeExternal
   * @returns {Observable<User[]>}
   */
  selectUsersWithAbsences(dateRange?: DateRangeFilter, options?: UserFilterOptions): Observable<User[]> {
    const { includeExternal, usersToFilter } = options || {}
    const onlyActive = options?.onlyActive ?? true

    return this.selectAll({
      filterBy: user =>
        (!onlyActive || !user.isDeactivated) &&
        user.absences &&
        !!user.absences.length &&
        (!dateRange || user.absences.some(absence => this.isWithinDateRange(absence, dateRange))) &&
        this.filterExternalUsers(user, includeExternal) &&
        (!usersToFilter?.length || usersToFilter.includes(user.id))
    }).pipe(
      map(users =>
        users.map(user => {
          user = new User(user, 'UserQuery.selectUsersWithAbsences')

          // Get only the absences within the date range
          if (dateRange) {
            user.absences = user.absences.filter(absence => this.isWithinDateRange(absence, dateRange))
          }

          return user
        })
      )
    )
  }

  /**
   * Gets users assigned to a holiday region
   * @param {string} holidayRegionId
   * @param {boolean} includeExternal
   */
  getUsersByHolidayRegion(holidayRegionId: string, includeExternal = false): User[] {
    const users = this.getAll({
      sortBy: (userA, userB) => this.getSort(userA, userB),
      filterBy: user => user.holidayRegion?.id === holidayRegionId && this.filterExternalUsers(user, includeExternal)
    })
    return this.mapEntities(users)
  }

  /** Gets users assigned to a workspace absence region
   * @param {string} workspaceAbsenceId
   * @param {boolean} includeExternal
   */
  getUsersByWorkspaceAbsence(workspaceAbsenceId: string, includeExternal = false): User[] {
    const users = this.getAll({
      sortBy: (userA, userB) => this.getSort(userA, userB),
      filterBy: user =>
        user.workspaceAbsence?.id === workspaceAbsenceId && this.filterExternalUsers(user, includeExternal)
    })

    return this.mapEntities(users)
  }

  // -------------------- Helper Functions -------------------- //

  /**
   * Checks if the absence dates are between dateRange
   * @param {Absence} absence
   * @param {DateRangeFilter} dateRange
   * @returns {boolean}
   */
  private isWithinDateRange(absence: Absence, dateRange: DateRangeFilter): boolean {
    absence.startOn = getLocalDateFromUTCString(absence.startOn)
    absence.endOn = getLocalDateFromUTCString(absence.endOn)
    return (
      absence.startOn.getTime() <= dateRange.endDate.getTime() &&
      absence.endOn.getTime() >= dateRange.startDate.getTime()
    )
  }

  /**
   * Filter used for quick status filters
   * @param { User } user
   * @param { UserStatuses | UserStatuses[]} status
   */
  private singleStatusFilter(user: User, status: UserStatuses | UserStatuses[]): boolean {
    switch (status) {
      case 'active':
        return user.status?.invitationAccepted && !user.isDeactivated
      case 'deactivated':
        return user.isDeactivated
      case 'invited':
        return !user.isDeactivated
      default:
        return true
    }
  }

  private statusFilter(user: User, status: UserStatuses | UserStatuses[]): boolean {
    if (Array.isArray(status)) {
      return status.some(s => this.singleStatusFilter(user, s))
    }

    return this.singleStatusFilter(user, status)
  }

  /**
   * Filter used for search by name
   * @param { User } user
   * @param { string } searchQuery
   * @param { UserRoleMapping[] } roles
   */
  searchFilter(user: User, searchQuery: string, roles?: UserRoleMapping[]): boolean {
    if (!searchQuery) {
      return true
    }

    user = new User(user, 'UserQuery.searchFilter')

    const compareString = searchQuery.toLowerCase()
    const fullName = user.fullName?.toLowerCase()
    const firstName = user.firstName?.toLowerCase()
    const lastName = user.lastName?.toLowerCase()
    const teams = user.teams?.map(team => team?.name?.toLowerCase()).join()
    const role = roles ? roles?.find(role => role?.userId === user.id)?.name?.toLowerCase() : []
    const email = user.email?.toLowerCase()
    const tags = user.tags?.map(tag => tag?.name?.toLowerCase()).join()
    const position = user.position?.toLowerCase()

    if (searchQuery.length > 1) {
      return (
        fullName?.includes(compareString) ||
        firstName?.includes(compareString) ||
        lastName?.includes(compareString) ||
        teams?.includes(compareString) ||
        role?.includes(compareString) ||
        email?.includes(compareString) ||
        tags?.includes(compareString) ||
        position?.includes(compareString)
      )
    }

    return (
      fullName?.startsWith(compareString) || firstName?.startsWith(compareString) || lastName?.startsWith(compareString)
    )
  }

  /**
   * Filter used to exclude specific users
   * @param {User} user
   * @param {string[]} userIds
   * @returns {boolean}
   * @private
   */
  private excludedUserFilters(user: User, userIds: string[]): boolean {
    if (!userIds) {
      return true
    } else {
      return !userIds.includes(user.id)
    }
  }

  /**
   * Returns whether the user should be filtered according to the includeExternal flag
   * @param {User} user
   * @param {boolean} includeExternal
   * @returns {boolean}
   */
  private filterExternalUsers(user: User, includeExternal: boolean): boolean {
    return includeExternal || !user.isExternal
  }

  /**
   * Filter users by teams
   * @param {User} user
   * @param {string[]} teamIds
   * @returns {boolean}
   */
  private filterByTeams(user: User, teamIds?: string[]): boolean {
    if (!teamIds?.length) {
      return true
    }

    return user.teams?.some(team => teamIds.includes(team.id)) || false
  }

  /**
   * Filter users by tags
   * @param {User} user
   * @param {string[]} tagNames
   * @returns {boolean}
   */
  private filterByTags(user: User, tagNames?: string[]): boolean {
    if (!tagNames?.length) {
      return true
    }

    return user.tags?.some(tag => tagNames.includes(tag.name)) ?? false
  }

  /**
   * Filter users by user IDs
   * @param {User} user
   * @param {string[]} userIds
   * @returns {boolean}
   */
  private filterByUserIds(user: User, userIds?: string[]): boolean {
    if (!userIds?.length) {
      return true
    }
    return userIds.includes(user.id)
  }

  /**
   * Filter users by team IDs or user IDs
   * @param {User} user - The user to filter
   * @param {Object} filters - The filters to apply
   * @param {string[]} filters.teamIds - The team IDs to filter by
   * @param {string[]} filters.userIds - The user IDs to filter by
   * @returns {boolean}
   */
  private filterByTeamOrUserIds(user: User, filters: { teamIds?: string[]; userIds?: string[] }): boolean {
    const { teamIds = [], userIds = [] } = filters

    if (!teamIds.length && !userIds?.length) {
      return true
    }

    if (!userIds.length && teamIds.length) {
      return this.filterByTeams(user, teamIds)
    }

    if (!teamIds.length && userIds.length) {
      return this.filterByUserIds(user, userIds)
    }

    return this.filterByUserIds(user, userIds) || this.filterByTeams(user, teamIds)
  }

  /**
   * Gets the sort used for the user queries
   * Sorting: lastName Asc, firstName Asc
   * @param userA
   * @param userB
   * @param {boolean} fullNameSort
   */
  private getSort(userA: User, userB: User, fullNameSort?: boolean): number {
    if (fullNameSort) {
      userA = new User(userA, 'UserQuery.getSort')
      userB = new User(userB, 'UserQuery.getSort')
      return userA.fullName.localeCompare(userB.fullName)
    }

    if (userA.lastName && userA.lastName !== userB.lastName) {
      return userA.lastName.localeCompare(userB.lastName)
    } else if (userA.firstName && userA.firstName !== userB.firstName) {
      return userA.firstName.localeCompare(userB.firstName)
    }

    return 0
  }
}
