<article
  *ngIf="visible"
  #modal
  class="modal"
  [ngClass]="['modal--' + size, 'modal--' + variant]"
  [class.is-active]="visible"
  [class.no-scroll]="preventScrolling"
  [class.hideOnPrint]="printService.isPrinting"
  [style.zIndex]="zIndex"
>
  <section
    class="modal__dialogue"
    [style.maxWidth.px]="width || 'unset'"
    [@popUp]="visible"
    (@popUp.done)="animationDone()"
  >
    <div
      *ngIf="showHeader"
      class="modal__header"
      [class.modal__header--with-close-btn]="showCloseButton"
      [class.modal__header--center]="titleSize === sizes.l"
      [class.modal__header--small]="size === sizes.s"
      [class.modal__header--no-background]="!showHeaderBackground"
    >
      <ng-content select="[feature-restriction]" />

      <ng-container *ngIf="title">
        <h1 *ngIf="titleSize === sizes.l" class="modal__title modal__title--l aw-2-txt--center">{{ title }}</h1>
        <h3 *ngIf="titleSize === sizes.m" class="modal__title">{{ title }}</h3>
      </ng-container>

      <ng-content select="[subtitle]" />

      <aw-close-button
        *ngIf="showCloseButton"
        class="modal__close-button"
        [size]="sizes.s"
        [awTooltip]="q.translations.common.close"
        [actionTooltip]="true"
        (click)="hide(true)"
      />
    </div>

    <div class="modal__body" [class.aw-2-flx]="flexBody">
      <ng-content />
    </div>

    <div class="modal__footer">
      <ng-content select="[modal-footer]" />
    </div>
  </section>
</article>
