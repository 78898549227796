<div
  #progressBar
  class="progress-bar"
  [class.progress-bar--flex]="overflow()"
  [class.progress-bar--light]="lightColor()"
  [class.progress-bar--red]="overflow() ? false : totalProgress() > 100"
  [class.progress-bar--has-overflow]="overflow() && totalProgress() > 100"
  [class.progress-bar--has-striped-overflow]="overflow() && totalProgress() > 100 && secondaryProgress() > 0"
  [style.--progress]="progress()"
  [style.--secondary-progress]="secondaryProgress()"
  [style.--overflow]="overflowWidth()"
  [ngClass]="'progress-bar--' + color() + ' progress-bar--' + size()"
>
  @if (label()) {
    <div class="progress-bar__label">{{ label() }}</div>
  }
  <div class="progress-bar__bar">
    @if (progress() > 0 || secondaryProgress() > 0) {
      <div class="progress-bar__progress" [attr.data-progress]="progress()"></div>
    }
  </div>
</div>
