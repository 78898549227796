import { effect, Injectable, untracked } from '@angular/core'
import { apiEndpoint } from '@awork/environments/environment'
import {
  IDesktopAppUserSetting,
  INpsUserSetting,
  UserSetting,
  UserSettingValue
} from '@awork/_shared/models/user-setting.model'
import {
  BehaviorSubject,
  debounceTime,
  filter,
  map,
  Observable,
  ReplaySubject,
  Subject,
  tap,
  withLatestFrom
} from 'rxjs'
import { HttpResponse } from '@angular/common/http'
import { ApiClient } from '@awork/_shared/services/api-client/ApiClient'
import {
  IProjectTaskListSetting,
  IProjectViewSetting,
  ITaskViewListsSetting
} from '@awork/framework/models/view-setting.model'
import { SettingsState, SettingsStore, SortByOptions } from '@awork/framework/state/settings.store'
import { AppQuery } from '@awork/core/state/app.query'
import { LogService } from '@awork/_shared/services/log-service/log.service'
import { GroupByOptions } from '@awork/_shared/models/group-by-options.model'
import { parseJSON } from '@awork/_shared/functions/lodash'
import { SortByOption } from '@awork/_shared/models/sort-by-option.model'
import { PlannerVersion } from '@awork/features/planner/models/planner-settings-model'
import { SettingsQuery } from '@awork/framework/state/settings.query'

/* eslint-enable max-len */

export interface ILastUsedEntityTypeSetting {
  workTypeIdTasks?: string
  workTypeIdTimes?: string
}

@Injectable({ providedIn: 'root' })
export class UserSettingsService {
  private url: string
  private viewSetting: UserSetting
  lastUsedEntityType: ILastUsedEntityTypeSetting = {}

  lastUsedWorkTypeIdForTimes$ = new ReplaySubject<string>(1)
  lastUsedWorkTypeIdForTasks$ = new ReplaySubject<string>(1)

  private saveProjectTaskListViewSettings$: Subject<{ value: string; id: string }> = new Subject<{
    value: string
    id: string
  }>()

  private saveUserSettingsQueue$ = new Subject<keyof SettingsState>()
  private fetchedUserSettings$ = new BehaviorSubject<Array<keyof SettingsState>>([])

  constructor(
    private apiClient: ApiClient,
    private settingsStore: SettingsStore,
    private settingsQuery: SettingsQuery,
    private appQuery: AppQuery,
    private logService: LogService
  ) {
    this.url = `${apiEndpoint}/me/settings`

    effect(() => {
      const isLoggedIn = this.appQuery.query('isLoggedIn')

      if (isLoggedIn()) {
        // Fetch the last used entity types
        untracked(() => this.fetchLastUsedEntityTypes())
      }
    })

    this.saveProjectTaskListViewSettings$.pipe(debounceTime(2000)).subscribe(setting => {
      this.setUserSetting('projectTaskList', setting.value, 'projects', setting.id).subscribe()
    })

    this.initSaveUserSettingsQueue()
  }

  /**
   * Initializes the save user settings queue.
   * Saves the user settings with a debounce time of 2 seconds.
   * It will only save the settings if it was fetched before to prevent overwriting.
   */
  private initSaveUserSettingsQueue(): void {
    this.saveUserSettingsQueue$
      .pipe(
        debounceTime(2000),
        withLatestFrom(this.fetchedUserSettings$),
        filter(([key, fetchedKeys]) => fetchedKeys.includes(key))
      )
      .subscribe(([key]) => {
        const storedValue = this.settingsQuery.query(key)
        const value = JSON.stringify(storedValue())

        this.setUserSetting(key, value).subscribe()
      })
  }

  get userViewSetting(): UserSetting {
    return this.viewSetting
  }

  set userViewSetting(viewSetting: UserSetting) {
    this.viewSetting = viewSetting
  }

  /**
   * Makes an API call to request the user settings
   * @returns {Observable<UserSetting[]>} - The user settings, is null if there is some error (ex.: not found)
   */
  getUserSettings(): Observable<UserSetting[]> {
    return this.apiClient.get<UserSetting[]>(`${this.url}`)
  }

  /**
   * Makes an API call to request the user setting by key
   * @param {string} key
   * @returns {Observable<UserSetting>} - The user setting, is null if there is some error (ex.: key not found)
   */
  getUserSetting(key: string): Observable<HttpResponse<UserSetting>> {
    return this.apiClient.get<HttpResponse<UserSetting>>(`${this.url}/${key}`, { observe: 'response' })
  }

  /**
   * Makes an API call to request the user setting by key and returns the parsed value
   * @param {string} key
   * @returns {Observable<UserSetting>} - The user setting, is null if there is some error (ex.: key not found)
   */
  getUserSettingValue(key: string): Observable<UserSettingValue> {
    return this.apiClient.get<UserSetting>(`${this.url}/${key}`).pipe(
      map(response => {
        const value = {}

        try {
          return JSON.parse(response?.value)
        } catch (e) {
          return value
        }
      })
    )
  }

  /**
   * Makes an API call to request the nps user setting by key
   * @returns {Observable<INpsUserSetting>} - The Nps setting, is null if there is some error (ex.: key not found)
   */
  getNpsUserSetting(): Observable<INpsUserSetting> {
    return this.apiClient.get<UserSetting>(`${this.url}/npsRating`).pipe(
      map(response => {
        const npsRatingSettingJSON = parseJSON(response?.value)

        if (!npsRatingSettingJSON) {
          return null
        }

        return {
          lastActionType: npsRatingSettingJSON.lastActionType,
          nextReminder: npsRatingSettingJSON.nextReminder ? new Date(npsRatingSettingJSON.nextReminder) : null
        }
      })
    )
  }

  /**
   * Makes an API call to request the desktop app user setting by key
   * @returns {Observable<IDesktopAppUserSetting>}
   */
  getDesktopAppSettings(): Observable<IDesktopAppUserSetting> {
    return this.apiClient.get<UserSetting>(`${this.url}/desktopApp`).pipe(
      map(response => {
        const desktopAppSettingJSON = parseJSON(response?.value)

        if (!desktopAppSettingJSON) {
          return null
        }

        return {
          openLinksInDesktopApp: desktopAppSettingJSON?.openLinksInDesktopApp,
          desktopAppInstalled: desktopAppSettingJSON?.desktopAppInstalled
        }
      })
    )
  }

  /**
   * Makes an API call to request the user setting by key
   * @returns {Observable<UserSetting>}
   */
  fetchUserViewSetting(): Observable<UserSetting> {
    return this.getUserSetting('userViewPersistence').pipe(
      map(response => {
        let settingValue: object = {}

        if (response.body) {
          try {
            settingValue = JSON.parse(response.body.value)
          } catch (_) {}
        }

        this.settingsStore.updateViewSettings(settingValue)
        return response.body
      })
    )
  }

  /**
   * Makes an API call to request the user setting for plannerView
   * @returns {Observable<UserSetting>}
   */
  fetchUserPlannerViewSetting(): Observable<UserSetting> {
    return this.getUserSetting('plannerView').pipe(
      map(response => {
        let settingValue: object = {}

        if (response.body) {
          try {
            settingValue = JSON.parse(response.body.value)
          } catch (_) {}
        }

        this.settingsStore.updateViewSettings(settingValue)
        return response.body
      })
    )
  }

  /**
   * Makes an API call to get the user setting with specified key and entity id
   * @param {string} entityName
   * @param {string} entityId
   * @param {string} key
   */
  getUserSettingById(entityName: string, entityId: string, key: string): Observable<HttpResponse<UserSetting>> {
    return this.apiClient
      .get<HttpResponse<UserSetting>>(`${this.url}/${entityName}/${entityId}/${key}`, {
        observe: 'response'
      })
      .pipe(
        map(response => {
          const userSetting: UserSetting = response && response.body ? response.body : null
          let parsedSetting

          if (response?.status === 200 && userSetting?.value) {
            try {
              parsedSetting = JSON.parse(userSetting.value)
            } catch (_) {}
          }

          switch (key) {
            case 'projectTaskList':
              const defaultProjectTaskListSetting: IProjectTaskListSetting = {
                projectId: entityId,
                showDone: false,
                lists: []
              }

              const projectTaskListSetting = parsedSetting?.projectId ? parsedSetting : defaultProjectTaskListSetting

              this.settingsStore.updateProjectTaskListViewSettings(projectTaskListSetting)
              break
            case 'taskViewLists':
              const defaultTaskViewListsSetting: ITaskViewListsSetting = {
                id: entityId,
                groupOption: GroupByOptions.project
              }

              const taskViewListsSetting = parsedSetting?.id ? parsedSetting : defaultTaskViewListsSetting

              this.settingsStore.updateTaskViewListsSettings(taskViewListsSetting)
              break
            case 'taskListWidget':
              const defaultTaskListWidgetSetting: ITaskViewListsSetting = {
                id: entityId,
                groupOption: GroupByOptions.upcomingDaily,
                sortingOption: {
                  value: SortByOptions.dueOn,
                  orderBy: 'desc'
                } as SortByOption
              }

              const taskListWidgetSetting = parsedSetting?.id ? parsedSetting : defaultTaskListWidgetSetting

              this.settingsStore.updateTaskListWidgetSettings(taskListWidgetSetting)
              break
            case 'project':
              const defaultProjectViewSetting: IProjectViewSetting = {
                projectId: entityId
              }

              const projectViewSetting = parsedSetting?.projectId ? parsedSetting : defaultProjectViewSetting

              this.settingsStore.updateProjectViewSettings(projectViewSetting)
              break
            case 'entityTimes':
              if (parsedSetting?.entityId) {
                this.settingsStore.updateEntityTimesSettings(parsedSetting)
              }

              break
          }

          return response
        })
      )
  }

  /**
   * Makes an API call to set or update the user setting with the key and value
   * @param {string} key
   * @param {string} value
   * @param {string} entityId
   * @param {string} entityName
   * @returns {Observable<string>}
   */
  setUserSetting(key: string, value: string, entityName?: string, entityId?: string): Observable<UserSetting> {
    return this.apiClient.post<UserSetting>(`${this.url}`, {
      key,
      value,
      entityId,
      entityName
    })
  }

  /**
   * Fetches the user settings for the specified key
   * @param {keyof SettingsState} key
   * @returns {Observable<SettingsState[keyof SettingsState]>}
   */
  fetchUserSettings<K extends keyof SettingsState>(key: K): Observable<SettingsState[K]> {
    return this.getUserSetting(key).pipe(
      // Save the fetched user settings key
      tap(() => {
        const fetchedUserSettings = this.fetchedUserSettings$.getValue()

        if (!fetchedUserSettings.includes(key)) {
          fetchedUserSettings.push(key)
          this.fetchedUserSettings$.next(fetchedUserSettings)
        }
      }),
      map(response => {
        if (response.body) {
          try {
            const parsedValue = JSON.parse(response.body.value)
            this.settingsStore.updateSettings(key, parsedValue)

            return parsedValue
          } catch (error) {
            this.logService.sendLogDNA('ERROR', `Cannot parse user setting: ${error}`, error)
          }
        }
      })
    )
  }

  /**
   * Saves the user settings with the specified key and value.
   * The API call is debounced by 2 seconds to avoid multiple calls in a short time.
   * @param {keyof SettingsState} key
   * @param {SettingsState[keyof SettingsState]} value
   * @returns {void}
   */
  saveUserSettings<K extends keyof SettingsState>(key: K, value: SettingsState[K]): void {
    this.settingsStore.updateSettings(key, value)

    this.saveUserSettingsQueue$.next(key)
  }

  /**
   * Makes an API call to delete the user setting with specified key and entity id
   * @param {string} entityName
   * @param {string} entityId
   * @param {string} key
   */
  deleteUserSettingById(entityName: string, entityId: string, key: string): Observable<string> {
    return this.apiClient.delete<string>(`${this.url}/${entityName}/${entityId}/${key}`)
  }

  /**
   * Makes an API call to delete the user setting by key
   * @param {string} key
   * @returns {Observable<string>}
   */
  deleteUserSetting(key: string): Observable<string> {
    return this.apiClient.delete<string>(`${this.url}/${key}`)
  }

  // ########### LAST USED ENTITY TYPE SETTING ##############
  /**
   * Fetches the last used entity type user setting
   */
  private fetchLastUsedEntityTypes(): void {
    this.getUserSetting('lastUsedEntityType').subscribe(res => {
      if (res && res.body) {
        try {
          const value: ILastUsedEntityTypeSetting = JSON.parse(res.body.value)
          if (value) {
            this.lastUsedEntityType = value

            // emit events
            this.lastUsedWorkTypeIdForTasks$.next(value.workTypeIdTasks)
            this.lastUsedWorkTypeIdForTimes$.next(value.workTypeIdTimes)
          }
        } catch (error) {
          this.logService.sendLogDNA('ERROR', `Cannot parse entity type setting: ${error}`, error)
        }
      }
    })
  }

  /**
   * Returns the last used work type id
   * @returns {string} - The last used work type id
   */
  getLastWorkTypeId(entity: 'time' | 'task'): string {
    if (entity === 'time') {
      return this.lastUsedEntityType.workTypeIdTimes
    } else {
      return this.lastUsedEntityType.workTypeIdTasks
    }
  }

  /**
   * Sets the last used entity type user setting
   * @param {'tasks' | 'timeTrackings'} entity - The entity type whose last used type is to be updated
   * @param {string} entityTypeId - The last used entity type id
   */
  setLastUsedWorkTypeSetting(workTypeId: string, entity: 'time' | 'task'): void {
    // Update the last used entity type
    if (entity === 'time') {
      this.lastUsedEntityType.workTypeIdTimes = workTypeId
      this.lastUsedWorkTypeIdForTimes$.next(workTypeId)
    } else {
      this.lastUsedEntityType.workTypeIdTasks = workTypeId
      this.lastUsedWorkTypeIdForTasks$.next(workTypeId)
    }

    // Save the user setting for the last used entity type
    const stringifiedValue: string = JSON.stringify(this.lastUsedEntityType)
    this.setUserSetting('lastUsedEntityType', stringifiedValue).subscribe()
  }

  /**
   * Set the Planner View Setting
   * @param {PlannerView} plannerView
   * @returns {void}
   */
  setPlannerViewSetting(plannerVersion: PlannerVersion): void {
    this.settingsStore.updatePlannerViewSettings({
      key: 'plannerView',
      value: plannerVersion
    })
    this.setUserSetting('plannerView', plannerVersion)
  }

  /**
   * Maps a User Setting response to a string array
   * @param {HttpResponse<UserSetting>} response
   * @returns {string[]}
   */
  private mapArrayResponse(response: HttpResponse<UserSetting>): string[] {
    const responseBody = response.body
    if (responseBody) {
      return responseBody.value.split(',')
    } else {
      return []
    }
  }

  /**
   * Adds an id to the start of a list of ids,
   * throwing out old elements if it exceeds the max length
   * @param {string[]} ids
   * @param {string} id
   * @param {number} maxLength
   * @returns {string}
   */
  private addRecentId(ids: string[], id: string, maxLength: number): string {
    const index = ids.indexOf(id, 0)
    if (index > -1) {
      // If the id is already in the list, move it to the begining
      ids.splice(index, 1)
      ids.unshift(id)
    } else {
      // In the case the id is not in the List, put it in the beginning and remove the last element
      ids.unshift(id)
      if (ids.length > maxLength) {
        ids.pop()
      }
    }
    return ids.join()
  }
}
