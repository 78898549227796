import { effect, Injectable } from '@angular/core'
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  IHttpConnectionOptions,
  LogLevel
} from '@microsoft/signalr'
import { apiEndpoint, isLiveMobile } from '@awork/environments/environment'
import { Observable, Subject, filter, map } from 'rxjs'
import { Signal, SignalEntityStore, SignalEntityType } from '@awork/_shared/services/signal-service/signal.model'
import { TimeTracking } from '@awork/features/time-tracking/models/time-tracking.model'
import { TimeTrackingStore } from '@awork/features/time-tracking/state/time-tracking.store'
import { Project } from '@awork/features/project/models/project.model'
import { ProjectStore } from '@awork/features/project/state/project.store'
import { Task } from '@awork/features/task/models/task.model'
import { TaskStore } from '@awork/features/task/state/task.store'
import { TaskStatusStore } from '@awork/features/task/state/task-status.store'
import { TaskListStore } from '@awork/features/task/state/task-list.store'
import { ChecklistItemStore } from '@awork/features/task/state/checklist-item.store'
import { User } from '@awork/features/user/models/user.model'
import { UserStore } from '@awork/features/user/state/user.store'
import { UserQuery } from '@awork/features/user/state/user.query'
import { Company } from '@awork/features/company/models/company.model'
import { CompanyStore } from '@awork/features/company/state/company.store'
import { AppQuery } from '@awork/core/state/app.query'
import { TaskList } from '@awork/features/task/models/task-list.model'
import { TaskStatus } from '@awork/features/task/models/task-status.model'
import { NotificationStore } from '@awork/framework/state/notification.store'
import { Notification } from '@awork/framework/models/notification.model'
import { AccountQuery } from '@awork/_shared/state/account.query'
import { ChecklistItem } from '@awork/features/task/models/checklist-item'
import { Absence } from '@awork/features/user/models/absence.model'
import { TimeBookingStore } from '@awork/features/planner/state/time-booking.store'
import { TimeBooking } from '@awork/features/planner/models/time-booking.model'

@Injectable({
  providedIn: 'root'
})
export class SignalService {
  private readonly url: string
  private hubConnection: HubConnection
  private signalReceived = new Subject<Signal>()
  private startTimeout: number
  private pingInterval: number
  private registered = false

  connectionId: string = null
  connected = new Subject<void>()
  disconnected$ = new Subject<void>()

  failConnectionDelay = 10000
  retryConnectionDelay = 500
  maxRetryConnectionDelay = 5 * 60000 // 5 minutes

  private excludedSignals: { entityName: SignalEntityType; propertyName: string }[] = []
  private currentUser = this.userQuery.queryCurrentUser()

  constructor(
    private appQuery: AppQuery,
    private accountQuery: AccountQuery,
    private userQuery: UserQuery
  ) {
    this.url = `${apiEndpoint}/notifications/websocketnotifications/`

    effect(() => {
      const isLoggedIn = this.appQuery.query('isLoggedIn')

      if (isLoggedIn() && (!this.hubConnection || this.hubConnection.state === HubConnectionState.Disconnected)) {
        setTimeout(() => {
          this.createConnection()
          this.startConnection()
        }, 300)
      } else if (!isLoggedIn()) {
        this.stopConnection()
        clearTimeout(this.startTimeout)
        clearInterval(this.pingInterval)
      }
    })
  }

  /**
   * Creates the signal connection
   */
  private createConnection(): void {
    // Stop (close) any open connection before creating a new one (this should not happen)
    this.stopConnection()

    let accessToken: string

    if (isLiveMobile) {
      accessToken = this.accountQuery.getAccount().accessToken
    }

    let url: string
    let options: IHttpConnectionOptions

    if (isLiveMobile) {
      url = `${this.url}?aw-mobile=true&jwt=${accessToken}`
    } else {
      url = this.url
      options = { accessTokenFactory: () => accessToken }
    }

    const hubConnectionBuilder = new HubConnectionBuilder()
      .withUrl(url, options)
      .withAutomaticReconnect(this.getRetryDelays())

    this.hubConnection = hubConnectionBuilder.configureLogging(LogLevel.None).build()
  }

  /**
   * Defines the reconnection retry delays
   * The delay increases exponentially until it reaches the maximum delay, in total it lasts for 24h approx.
   * @returns {number[]}
   */
  private getRetryDelays(): number[] {
    return new Array<number>(300).fill(0).map((ms: number, i: number) => {
      const expValue = this.retryConnectionDelay * Math.pow(i + 1, 2)
      return expValue < this.maxRetryConnectionDelay ? expValue : this.maxRetryConnectionDelay
    })
  }

  /**
   * Starts the signal connection
   */
  private startConnection(delay = 300): void {
    this.startTimeout = window.setTimeout(() => {
      this.hubConnection
        .start()
        .then(
          () => {
            this.connected.next()
            this.registerOnSignalEvents()
            this.sendPings()
            this.connectionId = this.hubConnection.connectionId

            // Reset delays
            this.failConnectionDelay = 10000
            this.retryConnectionDelay = 500
          },
          () => {
            this.createConnection()
            this.startConnection(this.failConnectionDelay)
            this.failConnectionDelay = this.failConnectionDelay * 2
          }
        )
        .catch(() => {})
    }, delay)
  }

  /**
   * Stops the signal connection
   */
  private stopConnection(): void {
    if (this.hubConnection) {
      this.hubConnection.stop().catch(() => {})

      // Resetting the flag so the new connection's 'on' event can be registered
      this.registered = false
    }
  }

  /**
   * Registers to signal events
   * Note: We need to register just once, even if a reconnection occurs
   * @param {string} name
   * @returns {Observable<Signal>}
   */
  private registerOnSignalEvents(name: string = 'EntityChange'): void {
    if (!this.registered) {
      this.hubConnection.on(name, (data: Signal) => {
        if (this.isExcludedSignal(data)) {
          return
        }

        this.signalReceived.next(data)
      })

      this.registered = true
    }
  }

  /**
   * Checks if the signal should be excluded.
   * It checks if the signal was triggered by the current user
   * and if the entity and property names in the change list match the excluded ones.
   * @param {Signal} signal
   * @returns {boolean}
   */
  isExcludedSignal(signal: Signal): boolean {
    if (signal.createdBy !== this.currentUser()?.id) {
      return false
    }

    return this.excludedSignals.some(
      excludedSignal =>
        signal.entityName === excludedSignal.entityName &&
        signal.changes.some(change => {
          return change.property.toLowerCase().includes(excludedSignal.propertyName.toLowerCase())
        })
    )
  }

  /**
   * Sends a ping every 10 minutes to prevent the connection to be closed
   */
  private sendPings(): void {
    if (this.pingInterval) {
      clearInterval(this.pingInterval)
    }

    this.pingInterval = window.setInterval(() => {
      if (this.hubConnection && this.hubConnection.state === HubConnectionState.Connected) {
        this.hubConnection.send('Ping').catch(() => {})
      }
    }, 60000 * 10)
  }

  /**
   * Gets an observable of signal of the specified entity
   * @param entityType
   * @param store - The store that needs to be updated
   * @param deleteSubject - Subject used for delete operations
   */
  getSignal(
    entityType: SignalEntityType,
    store?: SignalEntityStore,
    deleteSubject?: Subject<string>
  ): Observable<Signal> {
    const signal$ = this.signalReceived.pipe(
      filter(signal => signal.entityName === entityType),
      map(signal => new Signal(signal, this.userQuery.getCurrentUser()))
    )

    if (store || deleteSubject) {
      signal$.subscribe(signal => {
        // If a store is specified, update it with the signal's data
        if (store) {
          this.processSignal(signal, store)
        }

        // If the delete subject is specified and is a deleted operation, emit the deleted entity id
        if (deleteSubject && signal.operationType === 'deleted') {
          deleteSubject.next(signal.entityId)
        }
      })
    }

    return signal$
  }

  /**
   * Updates the store according to the signal received
   * @param signal
   * @param store
   */
  private processSignal(signal: Signal, store: SignalEntityStore): void {
    switch (signal.operationType) {
      case 'added':
      case 'updated':
        this.syncStore(signal, store)
        break
      case 'deleted':
        this.deleteStoreEntity(signal, store)
        break
    }
  }

  /**
   * Syncs the store with the signal's data
   * @param signal
   * @param store
   */
  private deleteStoreEntity(signal: Signal, store: SignalEntityStore): void {
    switch (signal.entityName) {
      case 'absence':
        store = store as UserStore
        const absence = signal.entity as Absence

        store.deleteAbsence(absence)
        break
      default:
        store.remove(signal.entityId)
        break
    }
  }

  /**
   * Syncs the store according to the signal's entity type
   * @param signal
   * @param store
   */
  private syncStore(signal: Signal, store: SignalEntityStore): void {
    switch (signal.entityName) {
      case 'project':
        store = store as ProjectStore
        const updatedProject = signal.entity as Project
        updatedProject.imageLastUpdatedOn = new Date()
        store.syncEntity(signal.entity as Project)
        break
      case 'task':
        store = store as TaskStore
        store.syncEntity(signal.entity as Task)
        break
      case 'timetracking':
        store = store as TimeTrackingStore
        store.syncEntity(signal.entity as TimeTracking)
        break
      case 'user':
        store = store as UserStore
        store.syncEntity(signal.entity as User)
        break
      case 'company':
        store = store as CompanyStore
        store.syncEntity(signal.entity as Company)
        break
      case 'tasklist':
        store = store as TaskListStore
        store.syncEntity(signal.entity as TaskList)
        break
      case 'taskstatus':
        store = store as TaskStatusStore
        store.syncEntity(signal.entity as TaskStatus)
        break
      case 'checklistitem':
        store = store as ChecklistItemStore
        store.syncEntity(signal.entity as ChecklistItem)
        break
      case 'notification':
        store = store as NotificationStore
        store.add(signal.entity as Notification)
        break
      case 'absence':
        store = store as UserStore
        const absence = signal.entity as Absence

        store.syncAbsence(absence)
        break
      case 'projecttimebooking':
        store = store as TimeBookingStore

        store.syncEntity(signal.entity as TimeBooking)
        break
    }
  }

  /**
   * Excludes a signal from being processed.
   * A signal is excluded if it was triggered by the current user and matches the excluded entity and property names.
   * @param {{ entityName: SignalEntityType, propertyName: string }} excludedSignal
   */
  excludeSignal(excludedSignal: { entityName: SignalEntityType; propertyName: string }): void {
    this.excludedSignals.push(excludedSignal)
  }
}
