import { SignalQuery } from '@awork/core/state/signal-store/signalQuery'
import { computed, Injectable, Signal } from '@angular/core'
import { AccountStore } from '@awork/_shared/state/account.store'
import { AccountState } from '@awork/_shared/models/account.model'
import { Observable, map } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class AccountQuery extends SignalQuery<AccountState> {
  static instance: AccountQuery // Used to query the store in models (user, company, etc.)

  constructor(protected store: AccountStore) {
    super(store)

    AccountQuery.instance = this
  }

  /**
   * Selects the account state
   */
  selectAccount(): Observable<AccountState> {
    return this.select().pipe(
      map(state => {
        return {
          ...state,
          refreshAt: new Date(state.refreshAt)
        }
      })
    )
  }

  /**
   * Gets the account state
   */
  getAccount(): AccountState {
    const state = this.getValue()

    return {
      ...state,
      refreshAt: new Date(state.refreshAt)
    }
  }

  /**
   * Selects the account's timezone
   */
  selectTimezone(): Observable<string> {
    return this.select().pipe(map(state => state.timezone || 'Europe/Berlin'))
  }

  /**
   * Gets the account's timezone
   */
  getTimezone(): string {
    return this.getValue().timezone || 'Europe/Berlin'
  }

  queryTimezone(): Signal<string> {
    return computed(() => {
      const accountState = this.query()
      return accountState().timezone || 'Europe/Berlin'
    })
  }
}
