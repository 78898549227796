import { AvatarPixelSizes, AvatarSize } from '../../../../_shared/types/avatar-sizes'
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core'
import { NgClass, NgOptimizedImage, NgStyle } from '@angular/common'

import { Size } from '@awork/_shared/types/size'
import { WithGlobals } from '../../../../_shared/classes/with-globals'
import { Workspace } from '@awork/features/workspace/models/workspace.model'

@Component({
  selector: 'aw-workspace-avatar',
  templateUrl: './workspace-avatar.component.html',
  styleUrls: ['./workspace-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgStyle, NgOptimizedImage]
})
export class WorkspaceAvatarComponent extends WithGlobals {
  // Inputs
  workspace = input<Workspace>()
  size = input<AvatarSize>(Size.m)
  showText = input<boolean>(true)
  multiline = input<boolean>(false)
  lines = input<number>(2)
  showMembersCount = input<boolean>(false)

  // Computed
  protected avatarSize = computed(() => AvatarPixelSizes[this.size()])
  protected imageUrl = computed(() => this.workspace().getImage(this.avatarSize(), this.avatarSize()))

  protected readonly avatarPixelSizes = AvatarPixelSizes
  protected readonly translations = q.translations.WorkspaceAvatarComponent
}
