import { ButtonColor, ButtonState, ButtonType } from './types'
import { ButtonSize, Size } from '@awork/_shared/types/size'
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core'
import { NgClass, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault, NgTemplateOutlet } from '@angular/common'
import { animate, keyframes, style, transition, trigger } from '@angular/animations'

import { Color } from '@awork/_shared/types/color'
import { DotsLoaderComponent } from '../../ui-help/dots-loader/dots-loader.component'
import { WithGlobals } from '../../../classes/with-globals'

@Component({
  selector: 'aw-button',
  templateUrl: './button.component.html',
  styleUrls: ['../base-button.scss', './button.component.scss'],
  standalone: true,
  animations: [
    trigger('scaleBig', [
      transition('void => *', [
        style({ transform: 'scale(0)' }),
        animate(
          '0.3s',
          keyframes([
            style({ transform: 'scale(0)', offset: 0 }),
            style({ transform: 'scale(1.5)', offset: 0.7 }),
            style({ transform: 'scale(0.8)', offset: 0.9 }),
            style({ transform: 'scale(1)', offset: 1 })
          ])
        )
      ])
    ])
  ],
  imports: [NgClass, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault, NgTemplateOutlet, DotsLoaderComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent extends WithGlobals implements OnChanges, OnInit {
  @Input() id: string
  @Input() type: ButtonType = 'primary'
  @Input() size: ButtonSize = Size.m
  @Input() leftIcon: string
  @Input() rightIcon: string
  @Input() color: ButtonColor = Color.Blue
  @Input() widthType: 'block' | 'auto' = 'auto'
  @Input() isSubmit: boolean
  @Input() formId: string
  @Input() text = ''
  @Input() state: ButtonState = 'default'
  @Input() loadingText: string = q.translations.common.loading

  @HostBinding('class.disabled')
  @Input()
  disabled = false

  @Output() onClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>()

  @ViewChild('btn') btn: ElementRef

  sizes = Size
  minWidth: number

  buttonClasses: string[] = []

  constructor(
    private cdr: ChangeDetectorRef,
    public elementRef: ElementRef
  ) {
    super()
  }

  ngOnInit(): void {
    this.buttonClasses = this.getClasses()

    this.cdr.markForCheck()
  }

  ngOnChanges(changes: SimpleChanges) {
    // set the width of the button when switching to the success state, to prevent shrinking
    if (changes.state?.currentValue === 'success' && this.minWidth === undefined) {
      this.minWidth = this.btn?.nativeElement?.offsetWidth

      this.cdr.detectChanges()
    }

    if (
      (changes.size && !changes.size.isFirstChange()) ||
      (changes.color && !changes.color.isFirstChange()) ||
      (changes.type && !changes.type.isFirstChange()) ||
      (changes.width && !changes.width.isFirstChange()) ||
      (changes.disabled && !changes.disabled.isFirstChange())
    ) {
      this.buttonClasses = this.getClasses()
    }
  }

  /**
   * Setting the classes to the button according to the color and style
   * @returns {string[]} - Ease css classes
   */
  private getClasses(): string[] {
    return [
      `btn--${this.color}`,
      `btn--${this.type}`,
      `btn--${this.size}`,
      `btn--${this.widthType}`,
      ...(this.disabled ? ['btn--disabled'] : [])
    ]
  }

  /**
   * Prevents the click events from propagation when the button is disabled or in a loading state
   * @param {MouseEvent} event
   */
  clickEventHandler(event: MouseEvent): void {
    if (this.disabled || this.state !== 'default') {
      event.preventDefault()
      event.stopImmediatePropagation()
    } else {
      this.onClick.emit(event)
    }
  }

  /**
   * Returns the correct color for the dot loader
   * @returns {Color}
   */
  getLoaderColor(): Color {
    switch (this.type) {
      case 'primary':
        return Color.AlwaysWhite
      case 'secondary':
        return this.color
      case 'tertiary':
        return Color.Steel
    }
  }
}
