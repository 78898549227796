import { addHours, subHours } from './date-fns-wrappers'

/**
 * Formats the date to a string which can be evaluated by the API
 */
export function formatDateForFilter(date: Date, includeTime = false): string {
  if (!date) {
    return ''
  }

  if (includeTime) {
    const index: number = date.toISOString().indexOf('.')
    return date.toISOString().slice(0, index)
  } else {
    date.setHours(12)
    return date.toISOString().slice(0, 10) + 'T00:00:00Z'
  }
}

/**
 * Builds the query params string
 * @param {Object} data
 * @returns {string}
 */
export function buildQueryString(data: Object): string {
  return Object.keys(data)
    .filter(key => data[key])
    .map(key => `${key}=${encodeURIComponent(data[key])}`)
    .join('&')
}

/**
 * Converts an url query string into an object
 * @param {string} queryString
 * @returns {Object}
 */
export function queryStringToObject(queryString: string): Object {
  if (queryString) {
    if (queryString.startsWith('?')) {
      queryString = queryString.slice(1)
    }

    return queryString
      .split('&') // split by query param
      .map(p => {
        // If the string ends with == or =
        // store this information and append it again
        // otherwise the Base64 string is broken.
        let appendEquals = ''
        if (p.split('=').length > 2) {
          if (p.endsWith('=')) {
            appendEquals = '='
          }
          if (p.endsWith('==')) {
            appendEquals = '=='
          }
        }

        const paramArray = p.split('=')
        paramArray[1] += appendEquals

        return paramArray
      }) // split by query name and value
      .filter(p => p[0] && p[1]) // get only queries with name and value
      .reduce((obj, pair) => {
        const [key, value] = pair.map(decodeURIComponent)
        return { ...obj, [key]: value }
      }, {})
  } else {
    return null
  }
}

export function removeQueryParam(uri: string, paramName: string): string {
  const urlParts = uri.split('?')
  if (urlParts.length <= 1) {
    return uri
  }
  const params = queryStringToObject(urlParts[1])
  params[paramName] = null
  return urlParts[0] + buildQueryString(params)
}

/**
 * Calculates the timezone offset in hours between a given date and a specified timezone.
 * @param {Date | string} date - The date for which to calculate the timezone offset. Can be a Date object or a string.
 * @param {string} timezone - The timezone to compare against UTC.
 * @returns {number} - The timezone offset in hours.
 */
export function getTzHoursOffset(date: Date | string, timezone: string): number {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  const tzTime = new Date(date.toLocaleString('en-US', { timeZone: timezone }))
  const utcTime = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }))
  const offsetInMinutes = (tzTime.getTime() - utcTime.getTime()) / (1000 * 60)
  const offsetHours = Math.floor(offsetInMinutes / 60)

  return offsetHours
}

/**
 * Adjusts a date for a specified timezone and returns it as an ISO string.
 *
 * @param {Date | string} date - The date to be adjusted. Can be a Date object or a string.
 * @param {string} timezone - The timezone to adjust the date for.
 * @returns {string} - The adjusted date as an ISO string.
 */
export function adjustDateForFilter(date: Date | string, timezone: string): string {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  const offset = getTzHoursOffset(date, timezone)

  if (offset > 0) {
    return subHours(date, offset).toISOString()
  } else if (offset < 0) {
    return addHours(date, offset).toISOString()
  }
  return date.toISOString()
}
