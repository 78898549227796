import { Injectable } from '@angular/core'
import {
  apiEndpoint,
  apiHostname,
  app,
  buildName,
  environment,
  releaseName,
  version
} from '@awork/environments/environment'
import { breadcrumbsIntegration, captureException, captureMessage, init, withScope } from '@sentry/angular'
import { datadogRum } from '@datadog/browser-rum'
import { UserQuery } from '@awork/features/user/state/user.query'
import { AppQuery } from '@awork/core/state/app.query'
import { AppStore } from '@awork/core/state/app.store'
import { HttpErrorResponse } from '@angular/common/http'
import { LogsStore } from '@awork/framework/state/logs.store'
import { AccountQuery } from '@awork/_shared/state/account.query'
import { WorkspaceQuery } from '@awork/features/workspace/state/workspace.query'
import isTest from '@awork/_shared/functions/is-test'
import sendBeacon from '@awork/_shared/functions/send-beacon'
import { ErrorType } from '@awork/_shared/functions/classify-error'
import getHostname from '@awork/_shared/functions/get-hostname'
import { ErrorItem, Level, SentryExtraInfo } from '@awork/_shared/services/log-service/types'
import { FeatureFlagQuery } from '@awork/_shared/state/feature-flag.query'
import { FeatureFlag } from '@awork/_shared/state/feature-flag.store'

@Injectable({
  providedIn: 'root'
})
export class LogService {
  errors: ErrorItem[] = []
  httpErrors: ErrorItem[] = []
  debug: ErrorItem[] = []
  appVersion: string
  static instance: LogService

  constructor(
    private workspaceQuery: WorkspaceQuery,
    private accountQuery: AccountQuery,
    private appQuery: AppQuery,
    private userQuery: UserQuery,
    private appStore: AppStore,
    private logsStore: LogsStore,
    private featureFlagQuery: FeatureFlagQuery
  ) {
    this.userQuery.setLogService?.(this)

    LogService.instance = this
  }

  /**
   * Stores debug information
   * @param error
   */
  logDebug(error: Error) {
    if (environment !== 'production') {
      this.logsStore.update(state => {
        let debug = [...state.debug]

        debug.unshift({ date: new Date(), error })
        debug = debug.slice(0, 100)

        return { debug }
      })
    }
  }

  /**
   * Stores the trace id of last request
   * @param {string} traceId
   * @param {boolean} error
   */
  logTrace(traceId: string, error: boolean): void {
    if (traceId && this.appQuery.getDebug()) {
      this.appStore.logTrace(traceId, error)
    }
  }

  /**
   * Logs the error in Sentry external service
   * @param {Error} error
   * @param {string} appVersion
   * @param {string} codeVersion
   * @param {ErrorType} errorType
   * @param {SentryExtraInfo} extraInfo
   */
  logError(
    error: Error,
    codeVersion?: string,
    appVersion?: string,
    errorType?: ErrorType,
    extraInfo?: SentryExtraInfo
  ): void {
    if (environment === 'local') {
      return
    }

    if (!(error instanceof Error)) {
      const internalErrorMessage = JSON.stringify(error)

      error = new Error(internalErrorMessage)
      error.name = 'INTERNAL_ERROR'
    }

    const user = this.userQuery.getCurrentUser()
    const workspace = this.workspaceQuery.getCurrentWorkspace()
    const account = this.accountQuery.getAccount()
    const traces = this.appQuery.getTraces()

    withScope(scope => {
      scope.setUser({
        id: user?.id,
        email: account?.email,
        username: user?.fullName
      })

      scope.setTag('buildName', buildName)
      scope.setTag('releaseName', releaseName)

      scope.setExtra('traceIds', traces.traceIds)
      scope.setExtra('traceIdsError', traces.traceIdsError)

      if (workspace) {
        scope.setExtra('workspace', { id: workspace.id, name: workspace.name })
      }

      if (this.appVersion) {
        scope.setExtra('appVersion', this.appVersion)
      }

      if (errorType) {
        scope.setTag('errorType', errorType)
      }

      if (extraInfo) {
        scope.setExtras(extraInfo)
      }

      captureException(error)
    })
  }

  /**
   * Logs a message in Sentry external service
   * @param {string} message
   * @param {string} componentTree
   * @param {SentryExtraInfo} extraInfo
   */
  logMessage(message: string, componentTree?: string, extraInfo?: SentryExtraInfo): void {
    if (environment === 'local') {
      return
    }

    const user = this.userQuery.getCurrentUser()
    const workspace = this.workspaceQuery.getCurrentWorkspace()
    const account = this.accountQuery.getAccount()
    const stackTrace = this.getStackTrace()

    withScope(scope => {
      scope.setUser({
        id: user?.id,
        email: account?.email,
        username: user?.fullName
      })

      scope.setTag('buildName', buildName)
      scope.setTag('releaseName', releaseName)

      if (workspace) {
        scope.setExtra('workspace', { id: workspace.id, name: workspace.name })
        scope.setExtra('component tree', componentTree)
      }

      if (this.appVersion) {
        scope.setExtra('appVersion', this.appVersion)
      }

      scope.setTag('errorType', 'message')

      if (extraInfo) {
        scope.setExtras(extraInfo)
      }

      if (stackTrace) {
        scope.setExtras({ stackTrace })
      }

      captureMessage(message)
    })
  }

  /**
   * Workaround to get a stacktrace for log messages in sentry
   * It creates a stack trace by creating a fake error
   * @returns {string}
   * @private
   */
  private getStackTrace(): string {
    const stackTrace = new Error()?.stack

    // Remove the first 3 lines of the stack trace:
    // One is from the Error constructor,
    // the other is from this function
    // and the third is from the logMessage function
    return stackTrace?.split?.('\n')?.slice?.(3).join?.('\n') || stackTrace
  }

  /**
   * Initializes Sentry SDK
   * @param codeVersion
   * @param appVersion
   */
  initSentry(codeVersion?: string, appVersion?: string): void {
    // Web init
    if (app === 'web' && !isTest()) {
      init({
        dsn: 'https://83a1c507839a4bc2a510d5b9262e1580@o394300.ingest.sentry.io/5556977',
        release: !codeVersion ? version : codeVersion,
        environment,
        defaultIntegrations: false,
        integrations: [
          breadcrumbsIntegration({
            console: false,
            dom: true,
            fetch: false,
            history: true,
            sentry: false,
            xhr: false
          })
        ],
        beforeSend(event) {
          const requestUrl = window.location.href

          event.request = {
            url: requestUrl,
            headers: {
              'User-Agent': navigator.userAgent
            }
          }
          event.platform = 'javascript'
          return event
        }
      })
    }

    this.appVersion = appVersion
  }

  /**
   * Save javascript errors to display them in console modal
   * @param error
   */
  saveError(error: Error): void {
    if (environment !== 'production' && error.message) {
      this.logsStore.update(state => {
        let errors = [...state.errors]

        errors.unshift({ date: new Date(), error })
        errors = errors.slice(0, 20)

        return { errors }
      })
    }
  }

  /**
   * Save http errors to display them in console modal
   * @param error
   */
  saveHttpError(error: HttpErrorResponse): void {
    if (environment !== 'production') {
      const traceId = error.headers ? error.headers.get('trace-id') : null

      this.logsStore.update(state => {
        let httpErrors = [...state.httpErrors]

        httpErrors.unshift({ date: new Date(), error, traceId })
        httpErrors = httpErrors.slice(0, 20)

        return { httpErrors }
      })
    }
  }

  /**
   * Sends information to log DNA
   * @param {Level} level
   * @param {string} line
   * @param {Error | HttpErrorResponse | any} error
   * @param {boolean} skipUser This flag is used to avoid circular dependencies when using this function in the userQuery
   * @param {boolean} addAccountData This flag is used to track info regarding user's account
   */
  sendLogDNA(
    level: Level,
    line: string,
    error?: Error | HttpErrorResponse | any,
    skipUser?: boolean,
    addAccountData?: boolean
  ): void {
    const user = skipUser ? null : this.userQuery.getCurrentUser()
    const workspace = this.workspaceQuery.getCurrentWorkspace()
    const account = this.accountQuery.getAccount()

    if (!isTest()) {
      switch (level) {
        case 'INFO':
          console.log(`INFO: ${line}`)
          break
        case 'WARN':
          console.warn(`WARN: ${line}`)
          break
        case 'ERROR':
          console.error(`ERROR: ${line}`)
          break
        case 'FATAL':
          console.error(`FATAL: ${line}`)
          break
      }

      let errorInfo: string

      if (error) {
        errorInfo = error instanceof HttpErrorResponse && error.headers ? error.headers.get('trace-id') : error.stack
      }

      // Logs should be disabled locally.
      if (environment !== 'local') {
        const hostname = getHostname()
        const url = `${apiEndpoint}/logs?tags=${environment}&hostname=${hostname}`
        const accountData = addAccountData
          ? { hasAccessToken: !!account.accessToken, hasRefreshToken: !!account.refreshToken }
          : {}

        const data = {
          lines: [
            {
              app: 'awork-app',
              env: environment,
              meta: {
                version,
                errorInfo,
                ...accountData,
                workspace: {
                  id: workspace?.id,
                  name: workspace?.name
                },
                user: {
                  id: user?.id,
                  email: account?.email,
                  username: user?.fullName
                },
                userAgent: navigator.userAgent
              },
              level,
              line
            }
          ]
        }
        const body = new Blob([JSON.stringify(data)], { type: 'application/json' })
        sendBeacon(url, body)
      }
    }
  }

  /**
   * Initializes Datadog RUM
   */
  initDatadogRum(): void {
    if (app !== 'web' || environment !== 'production' || isTest()) {
      return
    }

    const datadogRumSampleRate = this.featureFlagQuery.queryFlag(FeatureFlag.datadogRum)

    if (!datadogRumSampleRate()) {
      return
    }

    const datadogReplaySampleRate = this.featureFlagQuery.queryFlag(FeatureFlag.datadogReplay)
    const allowedTrackingUrlsRegex = new RegExp(`https://.*\\.${apiHostname.replace('.', '\\.')}/`)

    datadogRum.init({
      applicationId: '1fc312b7-6333-4385-8d08-5995328c67f2',
      clientToken: 'pub4a73c100e478297279fb8de37538eafc',
      site: 'datadoghq.eu',
      service: 'web-app',
      env: environment,
      version: version,
      traceSampleRate: 10,
      sessionSampleRate: datadogRumSampleRate(),
      sessionReplaySampleRate: datadogReplaySampleRate(),
      trackUserInteractions: false,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: [allowedTrackingUrlsRegex],
      beforeSend: (event, context) => {
        // Collect and send trace id from xhr requests
        if (
          event.type === 'resource' &&
          event.resource.type === 'xhr' &&
          event.resource.url.includes(apiHostname) &&
          context?.['xhr']
        ) {
          event.context.responseHeaders = {
            'trace-id': context['xhr'].getResponseHeader('trace-id')
          }
        }
        return true
      }
    })
  }
}
