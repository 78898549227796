import { catchError, map, Observable, of, tap, throwError } from 'rxjs'
import { Injectable } from '@angular/core'
import { apiEndpoint } from '@awork/environments/environment'
import {
  BetaFeatureSettings,
  ConnectSettings,
  GuestMigrationSetting,
  Workspace,
  WorkspaceSettings,
  WorkspaceUISetting
} from '@awork/features/workspace/models/workspace.model'
import { BrowserService } from '@awork/_shared/services/browser-service/browser.service'
import { ApiClient } from '@awork/_shared/services/api-client/ApiClient'
import { UserService } from '@awork/features/user/services/user-service/user.service'
import { HttpParams, HttpResponse } from '@angular/common/http'
import { WorkspaceQuery } from '@awork/features/workspace/state/workspace.query'
import { WorkspaceStore } from '@awork/features/workspace/state/workspace.store'
import { SubscriptionStore } from '@awork/_shared/state/subscription.store'
import { IQSubscription } from '@awork/_shared/models/subscription.model'
import {
  ApiRevenueResponse,
  apiWorkspaceSetting,
  InvitationParams,
  InvitationResponse,
  WorkspacePut
} from '@awork/features/workspace/services/workspace-service/types'

@Injectable({ providedIn: 'root' })
export class WorkspaceService {
  private readonly url: string
  newWorkspace: boolean // Stores if the user created a new Workspace, used to keep it selected

  constructor(
    private apiClient: ApiClient,
    private browserService: BrowserService,
    private userService: UserService,
    private workspaceStore: WorkspaceStore,
    private workspaceQuery: WorkspaceQuery,
    private subscriptionStore: SubscriptionStore
  ) {
    this.url = `${apiEndpoint}/`
  }

  /**
   * Unset the current workspace from the store
   */
  unsetCurrentWorkspace(): void {
    const currentWorkspace = this.workspaceQuery.getCurrentWorkspace()
    if (currentWorkspace) {
      this.workspaceStore.setActive(null)
    }
  }

  /**
   * Makes an API call to request the workspace information
   * @param {String} subdomain
   * @returns {Observable<Workspace>} - The workspace info, is null if there is some error (Ex.: Workspace not found)
   */
  getWorkspace(subdomain: string): Observable<Workspace> {
    return this.apiClient.get<Workspace>(`${this.url}subdomains/${subdomain}/workspace`).pipe(
      map(res => {
        if (res) {
          this.workspaceStore.upsert(res.id, res)
          this.workspaceStore.setActive(res.id)
          this.workspaceStore.setLoading(false)
        }

        return res
      }),
      catchError(() => {
        return of(null)
      })
    )
  }

  /**
   * Retrieves workspaces by account id
   * @param {string} accountId
   * @returns {Observable<Workspace[]>}
   */
  getByAccountId(accountId: string): Observable<Workspace[]> {
    return this.apiClient.get<Workspace[]>(`${this.url}workspaces/byaccountid/${accountId}`).pipe(
      map(workspaces => workspaces.map(workspace => new Workspace(workspace))),
      catchError(() => {
        return of(null)
      })
    )
  }

  /**
   * Makes an API call to request the workspace information by email
   * @param {String} email
   * @param {boolean} setWorkspace - If true, it will set the first workspace
   * @param {boolean} includePending - True to fetch workspaces with pending invitations
   * @returns {Observable<Workspace[]>} - The workspace information, is null if there is some error
   *                                   (ex.: Email not associated to any workspace)
   */
  getWorkspacesByEmail(email: string, setWorkspace = false, includePending = false): Observable<Workspace[]> {
    // If the workspace is newly created, keep it
    if (setWorkspace && !this.newWorkspace) {
      this.unsetCurrentWorkspace()
    }

    // New workspace is already selected, reset variable value
    if (this.newWorkspace) {
      this.newWorkspace = false
    }

    const params = includePending ? new HttpParams().set('includePending', 'true') : undefined

    return this.apiClient
      .get<Workspace[]>(`${this.url}userinworkspaces/${encodeURIComponent(email)}/workspaces`, { params })
      .pipe(
        map(workspaces => {
          if (workspaces && workspaces[0]) {
            workspaces = workspaces.map(workspace => new Workspace(workspace))

            let lastUsedWorkspace = workspaces.find(workspace => workspace.lastUsed)

            if (!lastUsedWorkspace) {
              lastUsedWorkspace = workspaces[0]
            }

            const subdomain = this.browserService.getSubdomain()
            let actualWorkspace: Workspace

            // If the user is in a custom subdomain, use it to select the correct workspace.
            if (subdomain && subdomain !== 'www') {
              actualWorkspace = workspaces.find(workspace => {
                return workspace.subdomains.some(sub => sub.name.toLowerCase() === subdomain.toLowerCase())
              })
            }

            this.workspaceStore.set(workspaces)

            if (!this.workspaceQuery.getActive() && setWorkspace) {
              this.workspaceStore.setCurrentWorkspace(actualWorkspace ? actualWorkspace.id : lastUsedWorkspace.id)
            }
          }

          return workspaces
        })
      )
  }

  /**
   * Makes an API call to update the workspace
   * If skipCreateDefaultWorkspaceData is true, we will not create the
   * default workspace data on the first workspace put call after an invitation
   * @param {Workspace} workspace - Workspace to be updated
   * @param {boolean} skipCreateDefaultWorkspaceData
   * @returns {Observable<Workspace>}
   */
  updateWorkspace(workspace: Workspace, skipCreateDefaultWorkspaceData = false): Observable<Workspace> {
    const originalWorkspace = this.workspaceQuery.queryEntity(workspace.id)
    const operationId = this.workspaceStore.history.startOperation({ type: 'update', entities: [originalWorkspace()] })

    this.workspaceStore.update(workspace.id, workspace)

    const workspacePut = this.mapPutModel(workspace, skipCreateDefaultWorkspaceData)

    return this.apiClient.put<Workspace>(`${this.url}workspaces/${workspace.id}/`, workspacePut).pipe(
      map(updatedWorkspace => {
        updatedWorkspace = new Workspace(updatedWorkspace)
        updatedWorkspace.goals = workspace.goals // properties get's lost in API otherwise
        updatedWorkspace.selfAttribution = workspace.selfAttribution // property get's lost in API otherwise
        updatedWorkspace.previousTool = workspace.previousTool // property get's lost in API otherwise

        this.workspaceStore.update(workspace.id, updatedWorkspace)

        this.workspaceStore.history.endOperation(operationId)

        return updatedWorkspace
      }),
      catchError(error => {
        if (operationId) {
          this.workspaceStore.history.undo(operationId)
        }

        return throwError(error)
      })
    )
  }

  /**
   * Makes an API call to send a workspace invite
   * @param {String} email
   * @param {String} invitationFlow
   * @param {InvitationParams} optionalParams
   * @returns {Observable<InvitationResponse>}
   */
  sendInvite(
    email: string,
    invitationFlow: string,
    optionalParams: InvitationParams = {}
  ): Observable<InvitationResponse> {
    let firstName: string, lastName: string

    if (optionalParams.name) {
      if (optionalParams.name.includes(' ')) {
        firstName = optionalParams.name.substr(0, optionalParams.name.indexOf(' '))
        lastName = optionalParams.name.substr(optionalParams.name.indexOf(' ') + 1)
      } else {
        firstName = optionalParams.name
      }
    }

    return this.apiClient
      .post<InvitationResponse>(`${this.url}invitations`, {
        email,
        invitationFlow,
        workspaceId: optionalParams.workspaceId,
        planId: optionalParams.planId,
        firstName,
        lastName,
        roleId: optionalParams.role?.id || null,
        teamIds: optionalParams.team ? [optionalParams.team.id] : undefined,
        gender: optionalParams.gender,
        position: optionalParams.position,
        title: optionalParams.title,
        password: optionalParams.password,
        isPaidTrial: optionalParams.isPaidTrial || undefined,
        connectInviteCode: optionalParams.connectInviteCode || undefined
      })
      .pipe(
        map(res => {
          if (res.workspace) {
            res.workspace = new Workspace(res.workspace)
            this.workspaceStore.upsert(res.workspace.id, res.workspace)
            this.workspaceStore.setCurrentWorkspace(res.workspace.id)
            this.newWorkspace = true
          } else if (optionalParams.workspaceId) {
            this.workspaceStore.setCurrentWorkspace(optionalParams.workspaceId)
          } else if (res.userId) {
            // Get invited user information and store it
            this.userService.fetchUser(res.userId).subscribe()

            // Decrease the remaining seats
            this.subscriptionStore.updateRemainingSeats()
          }

          return res
        })
      )
  }

  /**
   * Makes an API call to send a accept workspace invite
   * @param {string} invitationCode
   * @param {string} referralCode
   * @returns {Observable<HttpResponse<string>>}
   */
  acceptInvitation(invitationCode: string, referralCode?: string): Observable<HttpResponse<string>> {
    return this.apiClient.post<HttpResponse<string>>(
      `${this.url}invitations/accept`,
      {
        invitationCode,
        referralCode
      },
      {
        observe: 'response'
      }
    )
  }

  /**
   * Function which is responsible for sending an email to an user
   * @param {string} userId
   * @returns {Observable<string>}
   */
  resendEmail(userId: string): Observable<string> {
    const body: Object = { userId }
    return this.apiClient.post<string>(`${this.url}invitations/resend`, body)
  }

  /**
   * Makes an API call to resend the invitation email
   * @param {string} email
   * @returns {Observable<void>}
   */
  resendInvitationEmail(email: string): Observable<void> {
    return this.apiClient.post<void>(`${this.url}invitations/${email}/resend`, {})
  }

  /**
   * Makes an API call to downgrade the workspace to the specified plan.
   * @param {string} planId
   * @param {string[]} roleIdsToAdminRole
   * @return {Observable<void>}
   */
  downgradePlan(planId: string, roleIdsToAdminRole: string[]): Observable<void> {
    const workspace = this.workspaceQuery.getCurrentWorkspace()

    return this.apiClient.post<void>(`${this.url}workspaces/${workspace.id}/downgrade`, {
      planId,
      roleIdsToAdminRole
    })
  }

  /**
   * Makes an API call to start the delete workspace process
   * @returns {Observable<void>}
   */
  startDeleteWorkspace(): Observable<void> {
    const workspace = this.workspaceQuery.getCurrentWorkspace()

    return this.apiClient.post<void>(`${this.url}workspaces/${workspace.id}/startdelete`, {})
  }

  /**
   * Makes an API call to delete the workspace
   * @returns {Observable<void>}
   */
  deleteWorkspace(code: string): Observable<void> {
    const workspace = this.workspaceQuery.getCurrentWorkspace()

    return this.apiClient.post<void>(`${this.url}workspaces/${workspace.id}/delete`, {
      deleteWorkspaceCode: code
    })
  }

  /**
   * Maps the properties required for the POST model
   * @param {Workspace} workspace
   * @param {boolean} skipCreateDefaultWorkspaceData
   * @return {WorkspacePut}
   */
  private mapPutModel(workspace: Workspace, skipCreateDefaultWorkspaceData: boolean): WorkspacePut {
    const workspaceModel = new Workspace({
      name: workspace.name,
      description: workspace.description,
      type: workspace.type,
      size: workspace.size,
      language: workspace.language,
      allowCredentialsLogin: workspace.allowCredentialsLogin,
      allowSocialLogin: workspace.allowSocialLogin,
      allowSSOLogin: workspace.allowSSOLogin,
      allowAutomaticScimUserBooking: workspace.allowAutomaticScimUserBooking,
      selfAttribution: workspace.selfAttribution,
      goals: workspace.goals,
      previousTool: workspace.previousTool,
      sameDomainSignupEnabled: workspace.sameDomainSignupEnabled,
      sameDomainSignupRoleId: workspace.sameDomainSignupRoleId,
      sameDomainSignupDomains: workspace.sameDomainSignupDomains || []
    })

    return Object.assign(workspaceModel, { skipCreateDefaultWorkspaceData }) as WorkspacePut
  }

  /**
   * Checks if the specified custom subdomain is valid and available and can be used b the workspace.
   * Returns NoContent if the subdomain is valid and available, BadRequest otherwise.
   * @param workspaceId
   * @param customSubdomain
   */
  checkCustomWorkspaceSubdomain(workspaceId: string, customSubdomain: string) {
    return this.apiClient.post<void>(`${this.url}subdomains/${workspaceId}/checkcustomsubdomain`, {
      name: customSubdomain
    })
  }

  /**
   * Sets the custom subdomain of the workspace.
   * A workspace always has one generated subdomain and can have one additional,
   * custom subdomain. If a custom subdomain is set, it will be used by default.
   * @param workspaceId
   * @param customSubdomain
   */
  setCustomWorkspaceSubdomain(workspaceId: string, customSubdomain: string) {
    return this.apiClient.post<void>(`${this.url}subdomains/${workspaceId}/setcustomsubdomain`, {
      name: customSubdomain
    })
  }

  /**
   * Gets the workspace settings
   * @param {string} workspaceId
   * @returns {Observable<WorkspaceSettings>}
   */
  getWorkspaceSettings(workspaceId: string): Observable<WorkspaceSettings> {
    return this.apiClient.get<apiWorkspaceSetting[]>(`${this.url}workspaces/${workspaceId}/settings`).pipe(
      map(settings => {
        // map the settings to the workspace settings model
        const workspaceSettings = settings.reduce((acc, setting) => {
          let settingValue

          try {
            settingValue = JSON.parse(setting.value)
          } catch (e) {
            settingValue = {}
          }

          return { ...acc, [setting.key]: settingValue }
        }, {}) as WorkspaceSettings

        const workspaceState = this.workspaceQuery.getActive() as Workspace
        workspaceState.settings = workspaceSettings
        this.workspaceStore.upsert(workspaceId, workspaceState)

        return workspaceSettings
      })
    )
  }

  /**
   * Updates the workspace settings
   * @param {string} workspaceId
   * @param {string} key
   * @param {BetaFeatureSettings} setting
   * @returns {Observable<void>}
   */
  setWorkspaceSettings(
    workspaceId: string,
    key: string,
    setting: BetaFeatureSettings | WorkspaceUISetting | ConnectSettings | GuestMigrationSetting
  ): Observable<void> {
    const workspaceSetting = { key, value: JSON.stringify(setting) }

    return this.apiClient.post<void>(`${this.url}workspaces/${workspaceId}/settings`, workspaceSetting).pipe(
      tap(() => {
        const workspaceState = this.workspaceQuery.getActive() as Workspace
        workspaceState.settings[key] = setting

        this.workspaceStore.upsert(workspaceId, workspaceState)
      })
    )
  }

  /**
   * Gets the annual predicted revenue
   * @param {string} teamType
   * @param {string} teamSize
   * @param {string} email
   * @param {string[]} goals
   * @returns {Observable<Number>}
   */
  getAnnualPredictedRevenue(teamType: string, teamSize: string, email: string, goals: string[]): Observable<number> {
    let params: HttpParams = ApiClient.getQueryParams({})
    params = params.append('teamType', teamType)
    params = params.append('teamSize', teamSize)
    params = params.append('email', email)
    params = params.append('goals', goals.toString())

    return this.apiClient
      .get<ApiRevenueResponse>(`${this.url}workspaces/annualpredictedrevenue`, { params: params })
      .pipe(
        map(revenueResult => {
          return revenueResult.revenue
        })
      )
  }

  /**
   * Returns the workspaces of the current user with all the subscription information
   */
  fetchMyWorkspaceWithSubscriptions(): Observable<(Workspace & IQSubscription)[]> {
    return this.apiClient.get<(Workspace & IQSubscription)[]>(`${this.url}me/workspaces`)
  }
}
