interface ISubscriptionPlan {
  id: string
  isBookable: boolean
  features: PlanFeature[]
  pricing: PlanPricing[]
  year: number
  tier: PlanTier
}

export interface PlanAllowedChange {
  planId: string
  isImmediate: boolean
}

export interface PlanFeature {
  id: FeatureId
  available: boolean
  maxCount: number
}

export interface PlanPricing {
  planId: string
  currencyCode: Currency
  termInMonths: PlanTermMonths
  pricePerUser: number
  allowedChanges: PlanAllowedChange[]
}

export enum PlanTermMonths {
  Monthly = 1,
  Yearly = 12,
  Sixteen = 16,
  BiYearly = 24
}

export enum PlanTier {
  Basic = 1,
  Standard = 2,
  Professional = 3,
  Enterprise = 4,
  EnterprisePlus = 5
}

export enum FeatureId {
  /**
   * Projects and limits to the number of projects.
   */
  Projects = 'projects',

  /**
   * Project Autopilot.
   */
  Autopilot = 'autopilot',

  /**
   * Project automations.
   */
  Automations = 'automations',

  /**
   * User absences.
   */
  Absences = 'absences',

  /**
   * Private projects.
   */
  PrivateProjects = 'privateProjects',

  /**
   * Global time reports.
   */
  GlobalTimeReports = 'globalTimeReports',

  /**
   * Workspace-wide time tracking settings.
   */
  TimeTrackingSettings = 'timeTrackingSettings',

  /**
   * Team structure in the workspace settings.
   */
  TeamStructure = 'teamStructure',

  /**
   * Custom fields for tasks.
   */
  CustomFields = 'customFields',

  /**
   * Custom roles and permissions.
   */
  CustomRoles = 'customRoles',

  /**
   * Custom subdomain for the workspace.
   */
  CustomSubdomain = 'customSubdomain',

  /**
   * Project and task templates.
   */
  Templates = 'templates',

  /**
   * The planner workload charts and calculations.
   */
  PlannerWorkload = 'plannerWorkload',

  /**
   * The ability to invite users to Connect, disabled in basic connect plan.
   */
  ConnectInvites = 'connectInvites',

  /**
   * Regular integrations such as Slack, Google, etc.
   */
  RegularIntegrations = 'regularIntegrations',

  /**
   * Advanced integrations such as Personio.
   */
  AdvancedIntegrations = 'advancedIntegrations',

  /**
   * Workspace-wide data import for tasks and project data.
   */
  DataImport = 'dataImport',

  /**
   * SSO and SCIM.
   */
  Sso = 'sso'
}

export enum PlanId {
  EnterprisePlus2025 = 'enterpriseplus-2025',
  Enterprise2025 = 'enterprise-2025',
  Professional2025 = 'professional-2025',
  Standard2025 = 'standard-2025',
  Basic2025 = 'basic-2025',
  Connect2025 = 'connect-2025',
  Connect2024 = 'connect-2024',
  Enterprise2023 = 'enterprise-2023',
  Business2023 = 'business-2023',
  Team2023 = 'team-2023',
  AworkBusiness = 'awork-business',
  AworkStandard = 'awork-standard',
  AworkEnterprise = 'awork-enterprise',
  QStandard = 'q-standard',
  QEnterprise = 'q-enterprise',
  // ------------- Pricing plans: Legacy --------------- //
  Internal = 'internalfree',
  Free = 'free',
  NewFree = 'free-new',
  LegacyStandard = 'standard',
  LegacyEnterprise = 'enterprise',
  LegacyBuhl = 'buhl'
}

export enum Currency {
  USD = 'USD',
  CHF = 'CHF',
  GBP = 'GBP',
  EUR = 'EUR'
}

export enum CurrencySymbol {
  EUR = '€',
  CHF = 'CHF',
  GBP = '£',
  USD = '$'
}

export enum CurrencySymbolPlacement {
  Left,
  Right
}

export const Currencies = {
  [Currency.EUR]: {
    symbol: CurrencySymbol.EUR,
    currency: Currency.EUR,
    abbreviationPlacement: CurrencySymbolPlacement.Right,
    symbolPlacement: CurrencySymbolPlacement.Right
  },
  [Currency.CHF]: {
    symbol: CurrencySymbol.CHF,
    currency: Currency.CHF,
    abbreviationPlacement: CurrencySymbolPlacement.Right,
    symbolPlacement: CurrencySymbolPlacement.Right
  },
  [Currency.GBP]: {
    symbol: CurrencySymbol.GBP,
    currency: Currency.GBP,
    abbreviationPlacement: CurrencySymbolPlacement.Left,
    symbolPlacement: CurrencySymbolPlacement.Left
  },
  [Currency.USD]: {
    symbol: CurrencySymbol.USD,
    currency: Currency.USD,
    abbreviationPlacement: CurrencySymbolPlacement.Left,
    symbolPlacement: CurrencySymbolPlacement.Left
  }
}

export enum CustomDomainAddon {
  EUR = 'custom-domain-eur',
  USD = 'custom-domain-usd',
  CHF = 'custom-domain-chf',
  GBP = 'custom-domain-gbp'
}

export const PlanCustomDomainAddons = {
  [Currency.EUR]: {
    currency: Currency.EUR,
    addonId: CustomDomainAddon.EUR
  },
  [Currency.CHF]: {
    currency: Currency.CHF,
    addonId: CustomDomainAddon.CHF
  },
  [Currency.GBP]: {
    currency: Currency.GBP,
    addonId: CustomDomainAddon.GBP
  },
  [Currency.USD]: {
    currency: Currency.USD,
    addonId: CustomDomainAddon.USD
  }
}

/**
 * The model of the subscription plan.
 * These plans are the ones available from the API.
 */
export class SubscriptionPlan implements ISubscriptionPlan {
  id: PlanId
  isBookable: boolean
  features: PlanFeature[]
  pricing: PlanPricing[]
  year: number
  tier: PlanTier

  constructor(subscriptionPlan: Partial<ISubscriptionPlan>) {
    Object.assign(this, subscriptionPlan)
  }
}
