import { fromEvent, Subject, Subscription, delay, filter, takeUntil, debounceTime } from 'rxjs'
import { BrowserService, WINDOWSIZE } from '@awork/_shared/services/browser-service/browser.service'
import { Injectable, OnDestroy, Input, NgZone, Renderer2, RendererFactory2, Inject } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { DOCUMENT } from '@angular/common'
import { isStoryBook } from '@awork/_shared/functions/is-storybook'
import { ModalComponent } from '../../components/layout/modal/modal.component'
import { OnboardingPopupService } from '../../../features/onboarding/components/onboarding-popup/onboarding-popup.service'

@Injectable({ providedIn: 'root' })
export class ModalService implements OnDestroy {
  static instance: ModalService
  public modals: Array<ModalComponent> = [] // List of active modals
  private scrollPositions: Array<number> = [] // List of scroll positions of modals (same index as modal index)
  private escKeyPress: Subscription

  @Input() showHeader = true

  autoOpenModal = new Subject<string>()
  autoModalOpened = new Subject<boolean>()
  modalRemoved = new Subject<void>()
  quickActionOpen?: HTMLElement

  private renderer: Renderer2

  constructor(
    private _browserService: BrowserService,
    private zone: NgZone,
    private router: Router,
    private route: ActivatedRoute,
    private rendererFactory: RendererFactory2,
    private onboardingPopupService: OnboardingPopupService,
    @Inject(DOCUMENT) private document
  ) {
    ModalService.instance = this
    this.renderer = this.rendererFactory.createRenderer(null, null)

    this.zone.runOutsideAngular(() => {
      // Listed to esc keyup event and close the modal
      this.escKeyPress = fromEvent<KeyboardEvent>(document, 'keyup')
        .pipe(
          debounceTime(300),
          filter(keyEvent => {
            const keyCode = keyEvent.key
            return (
              keyCode === 'Escape' &&
              keyEvent.returnValue &&
              this.modals.filter(modal => modal.showCloseButton).length > 0 // Exclude modals without close button
            )
            // the returnValue is false in case an popup has just been closed
          })
        )
        .subscribe(keyEvent => {
          this.zone.run(() => {
            const modals = this.modals.filter(modal => modal.showCloseButton)
            modals[modals.length - 1].hide(true)
          })
        })
    })

    this.initAutoOpenModal()
  }

  ngOnDestroy() {
    if (this.escKeyPress && !this.escKeyPress.closed) {
      this.escKeyPress.unsubscribe()
    }
  }

  /**
   * Adds the modal to the list of modals
   * @param {ModalComponent} modal - The modal component
   */
  public addModal(modal: ModalComponent): void {
    if (modal) {
      // TODO: Not quite sure if we still need to do this

      // Sentry issue sometimes renderer was undefined
      if (!this.renderer) {
        this.renderer = this.rendererFactory.createRenderer(null, null)
      }

      if (!isStoryBook()) {
        // moving the modal to the body DOM element to ensure nested modals are visible if the parent is being hidden
        this.renderer.appendChild(this.document.body, modal.elementRef.nativeElement)
      }

      this.modals.push(modal)
      this.onboardingPopupService.clear()

      // disable background scrolling if at least one modal is shown
      if (this.modals.length === 1) {
        if (this._browserService.lastDeviceCategory !== WINDOWSIZE.SMARTPHONE) {
          this._browserService.setBodyScrolling(false)
        }
        this._browserService.blurMainContent(true)
      }

      // hide other modals
      if (this.modals.length > 1) {
        this.modals[this.modals.length - 2].setVisibility(false)
        this.scrollPositions.push(this.modals[this.modals.length - 2].getScrollPosition())
      }
    }
  }

  /**
   * Removes the modal from the list of modals
   * @param {ModalComponent} modal - The modal component
   */
  public removeModal(modal: ModalComponent): void {
    if (modal) {
      this.modals = this.modals.filter(mod => mod !== modal)

      // Show the last modal hidden if it's after a quickAction element or
      // no quickAction elements are rendered
      if (this.shouldSetLastModalVisibility()) {
        // setting the last modal to visible
        this.modals[this.modals.length - 1].setVisibility(true)
        // setting the scroll position
        setTimeout(() => {
          if (this.modals[this.modals.length - 1]) {
            this.modals[this.modals.length - 1].setScrollPosition(this.scrollPositions[this.modals.length - 1])
            this.scrollPositions.pop() // remove the again
          }
        })
      }

      // enabled background scrolling
      if (this.modals.length === 0) {
        if (this._browserService.lastDeviceCategory !== WINDOWSIZE.SMARTPHONE) {
          this._browserService.setBodyScrolling(true)
        }

        if (!this.quickActionOpen) {
          this._browserService.blurMainContent(false)
        }
      }

      // Remove the modal from the body DOM element
      try {
        this.renderer.removeChild(this.document.body, modal.elementRef.nativeElement, true)
      } catch (_) {}

      this.modalRemoved.next()
    }
  }

  /**
   * Checks if the last modal visibility should be set, by checking if there is
   * an open quickAction component and if the last modal is after the quickAction
   * in the DOM tree, otherwise don't show it yet.
   * @returns {boolean}
   * @private
   */
  private shouldSetLastModalVisibility(): boolean {
    if (!this.modals.length) {
      return false
    }

    if (!this.quickActionOpen) {
      return true
    }

    const lastModalEl = this.modals[this.modals.length - 1].elementRef.nativeElement
    const isLastModalAfterQuickAction =
      this.quickActionOpen.compareDocumentPosition(lastModalEl) === Node.DOCUMENT_POSITION_FOLLOWING

    return isLastModalAfterQuickAction
  }

  /**
   * Hides all modals (with autoHide = true)
   */
  public hideAllModals(): void {
    setTimeout(() => {
      this.modals
        .filter(modal => modal.autoHide)
        .forEach(modal => {
          // Time out necessary because of the change detection.
          // And it looks good with the delay as well ;)
          setTimeout(() => {
            modal.hide()
          }, 500)
        })
    }, 100)
  }

  /**
   * Emits the modal's name that should be opened based on
   * URL's query param
   */
  initAutoOpenModal(): void {
    // Look for 'modal' query param
    this.route.queryParams.pipe(filter(params => params['modal'] || params['m'])).subscribe(params => {
      // Wait until navigation end (plus 1ms of delay) to prevent modal's auto closing
      this.router.events
        .pipe(
          filter(event => event instanceof NavigationEnd),
          takeUntil(this.autoModalOpened),
          delay(1)
        )
        .subscribe(() => {
          const modal = params['modal'] || params['m']
          this.autoOpenModal.next(modal)
        })
    })

    // Remove the modal query param when the desired modal is opened
    this.autoModalOpened.pipe(filter(modalOpened => !!modalOpened)).subscribe(() => {
      this.router.navigate([], { queryParams: {}, replaceUrl: true })
    })
  }

  /**
   * Hides the last shown modal
   */
  hideLastModal(): void {
    if (this.modals.length) {
      const lastModal = this.modals[this.modals.length - 1]
      lastModal.setVisibility(false)
      this.scrollPositions.push(lastModal.getScrollPosition())
    }
  }

  /**
   * Shows the last hidden modal
   */
  showLastModal(): void {
    if (this.modals.length) {
      const lastModal = this.modals[this.modals.length - 1]
      lastModal.setVisibility(true)

      setTimeout(() => {
        lastModal.setScrollPosition(this.scrollPositions[this.modals.length - 1])
        this.scrollPositions.pop()
      })
    }

    this._browserService.blurMainContent(!!this.quickActionOpen)
  }
}
