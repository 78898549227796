<figure class="avt" [ngClass]="'avt--' + size()">
  @if (imageUrl()) {
    <img
      priority
      class="avt__image"
      [ngSrc]="imageUrl()"
      [attr.alt]="workspace().name"
      [width]="avatarSize()"
      [height]="avatarSize()"
    />
  }

  @if (showText()) {
    <figcaption
      class="avt__text"
      [class.avt__text--multiline]="multiline()"
      [class.avt__text--multi-text]="showMembersCount() && workspace().memberCount"
      [style.min-height.px]="avatarSize()"
    >
      @if (workspace().name) {
        <span
          class="avt__text__primary"
          [ngStyle]="{'-webkit-line-clamp': lines()}"
          [attr.title]="workspace().name"
        >
          {{ workspace().name }}
        </span>
      } @else {
        <span
          class="avt__text__primary aw-2-txt--italic aw-2-txt--light-steel"
          [ngStyle]="{'-webkit-line-clamp': lines()}"
          [attr.title]="translations.noName"
        >
          {{ translations.noName }}
        </span>
      }

      @let membersCount = workspace().memberCount;
      @if (showMembersCount() && membersCount) {
        <span class="avt__text__secondary">
          {{ membersCount > 1 ?
            translate(translations.membersCount, {members: membersCount}) :
            translations.memberCount
          }}
        </span>
      }
    </figcaption>
  }
</figure>
