<button
  class="btn-icon"
  type="button"
  [ngClass]="iconButtonClasses"
  [awTooltip]="tooltip"
  [actionTooltip]="true"
  [fromTop]="toolTipFromTop"
>
  <i class="aw-2-icn">{{ isSelected && selectedIcon ? selectedIcon : icon }}</i>
</button>
