import { ChangeDetectionStrategy, Component, ComponentRef, ElementRef, input, output, viewChild } from '@angular/core'

import { ButtonComponent } from '../../../../_shared/components/buttons/button/button.component'
import { Color } from '@awork/_shared/types/color'
import { ConnectedPosition } from '@angular/cdk/overlay'
import { DynamicRefService } from '../../../../_shared/services/dynamic-ref-service/dynamic-ref.service'
import { ONBOARDING_POPUP_POSITION } from '../../../../_shared/components/layout/popup/positions'
import { OnboardingPopup } from './types'
import { PopupComponent } from '../../../../_shared/components/layout/popup/popup.component'
import { Size } from '@awork/_shared/types/size'
import { UpdateableInputs } from '@awork/_shared/types/updateable-inputs'
import { Variant } from '../../../../_shared/components/layout/popup/variants'
import { WithGlobals } from '../../../../_shared/classes/with-globals'
import getLanguage from '@awork/_shared/functions/get-language'

@Component({
  selector: 'aw-onboarding-popup',
  templateUrl: './onboarding-popup.component.html',
  styleUrls: ['./onboarding-popup.component.scss'],
  standalone: true,
  imports: [PopupComponent, ButtonComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OnboardingPopupComponent extends WithGlobals {
  // Inputs
  popup = input.required<OnboardingPopup>()
  origin = input<ElementRef | HTMLElement>({} as ElementRef)
  position = input<ConnectedPosition>(ONBOARDING_POPUP_POSITION)
  popupWidth = input<number>()

  // Outputs
  onShow = output<void>()
  onHide = output<void>()
  onNext = output<void>()
  onBack = output<void>()

  // ViewChild
  popupRef = viewChild.required<PopupComponent>('popupRef')

  protected readonly variants = Variant
  protected readonly sizes = Size
  protected readonly colors = Color
  protected readonly lang = getLanguage()

  /**
   * Show the popup component
   */
  show(): void {
    this.onShow.emit()
    this.popupRef().show()
  }

  /**
   * Hide the popup component
   */
  hide(): void {
    this.onHide.emit()
    this.popupRef().hide()
  }

  /**
   * Shows or hides the popup component
   */
  toggle(): void {
    this.popupRef().toggle()
  }

  /**
   * Handles the click on "Back"
   */
  back(): void {
    this.onBack.emit()
    this.hide()
  }

  /**
   * Handles the click on "Next"
   */
  next(): void {
    this.onNext.emit()
    this.hide()
  }

  /**
   * Renders the onboarding popup component
   * @param partialPopupInputs Partial inputs for the popup component
   * @returns The popup component
   */
  static renderPopup(
    partialPopupInputs: UpdateableInputs<OnboardingPopupComponent> = {}
  ): [ComponentRef<OnboardingPopupComponent>, OnboardingPopupComponent] {
    const [popupRef, popupComponent] = DynamicRefService.instance.create(OnboardingPopupComponent)

    Object.keys(partialPopupInputs).forEach(key => {
      popupRef.setInput(key, partialPopupInputs[key])
    })

    popupComponent.onHide.subscribe(() => {
      popupRef.destroy()
    })
    return [popupRef, popupComponent]
  }
}
