import { NgClass } from '@angular/common'
import { ChangeDetectionStrategy, Component, computed, ElementRef, input, viewChild } from '@angular/core'
import { Color, MainColor } from '@awork/_shared/types/color'
import { Size } from '@awork/_shared/types/size'

@Component({
  selector: 'aw-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  standalone: true,
  imports: [NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressBarComponent {
  progress = input(0) // 0 - 100
  secondaryProgress = input(0) // 0 - 100
  size = input<Size.s | Size.m>(Size.m)
  overflow = input<boolean>(false)
  color = input<MainColor>(Color.Blue)
  lightColor = input<boolean>(false)
  label = input<string>('')

  progressBar = viewChild<ElementRef>('progressBar')

  overflowWidth = computed(() => this.getOverflowWidth())
  progressWidth = computed(() => this.getProgressWidth())
  secondaryProgressWidth = computed(() => this.getSecondaryProgressWidth())
  totalProgress = computed(() => this.progress() + this.secondaryProgress())

  protected readonly sizes = Size

  /**
   * Sets the width of the primary progress bar
   * @withSignal {number} progress
   */
  private getProgressWidth(): number {
    return this.progress() / 100
  }

  /**
   * Sets the width of the secondary progress bar
   * @withSignal {number} secondaryProgress
   */
  private getSecondaryProgressWidth(): number {
    return this.secondaryProgress() / 100
  }

  /**
   * Sets the overflow width of the progress bar when total progress exceeds 100%
   */
  private getOverflowWidth(): number {
    const totalProgress = this.progress() + this.secondaryProgress()
    if (totalProgress <= 100) {
      return 0
    }
    const overflow = totalProgress - 100
    return Math.round((overflow / totalProgress) * 100)
  }
}
