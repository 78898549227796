import {
  fromEvent,
  merge,
  Observable,
  ReplaySubject,
  startWith,
  debounceTime,
  distinctUntilChanged,
  refCount,
  publishReplay,
  share,
  filter,
  mapTo,
  map,
  shareReplay,
  throttleTime,
  Subject,
  tap
} from 'rxjs'
import { computed, ElementRef, Injectable, NgZone, Signal, signal, WritableSignal } from '@angular/core'

import { getPixelRatio } from '@awork/_shared/functions/browser-infos'
import { addDays, endOfWeek, isAfter, isWeekend, isWithinRange } from '@awork/_shared/functions/date-fns-wrappers'
import { isFriday } from 'date-fns'
import { getBrowserInfo } from '@awork/_shared/functions/browser-info'
import { mobileRegex, tabletRegex } from '@awork/_shared/static-data/regular-expressions'
import isTest from '@awork/_shared/functions/is-test'
import { createResizeObserver, isResizeObserverAvailable } from '@awork/_shared/functions/resize-observable'

declare var Intercom: any

export enum WINDOWSIZE {
  SMARTPHONE,
  TABLET,
  DESKTOP,
  LARGEDESKTOP,
  EXTRALARGEDESKTOP
}

export enum DeviceCategory {
  Mobile = 'mobile',
  Tablet = 'tablet',
  Desktop = 'desktop'
}

const getWindowSize = () => ({
  height: window.innerHeight,
  width: window.innerWidth
})

const createWindowSize$ = () =>
  fromEvent(window, 'resize').pipe(map(getWindowSize), startWith(getWindowSize()), publishReplay(1), refCount())

@Injectable({ providedIn: 'root' })
export class BrowserService {
  static instance: BrowserService
  private _doc: Document
  private _win: Window
  private _hasTouch: boolean = undefined
  private _hasMouse: boolean = undefined
  private pixeFactor: number

  public lastDeviceCategory: WINDOWSIZE

  // browser window sizes
  width$: Observable<number>
  height$: Observable<number>

  private mainViewWidth$ = new Subject<number>()

  // browser device size type
  deviceCategory$: Observable<WINDOWSIZE>
  private _deviceSize: WritableSignal<WINDOWSIZE>

  private windowSizeObserver: ResizeObserver

  // online state
  online$: Observable<boolean>

  // browser window resize
  resize$: Observable<any>

  // browser or window visibility (focus) change event observable
  visibilityChange$: Observable<boolean> = new Observable<boolean>()

  private _contentSize$ = new ReplaySubject<{ width: number; height: number }>(1)
  private contentSize = signal({ width: 0, height: 0 })

  private contentResizeObserver: ResizeObserver
  private mainContentResizeObserver: ResizeObserver
  private _isMainContentWidescreen$: Observable<boolean>
  private _mainContentDeviceCategory$: Observable<WINDOWSIZE>

  // document click
  documentClick$: Observable<HTMLElement>
  documentPointerUp$: Observable<PointerEvent>

  private scrollingElement: Element
  private menuScrollingElement: Element
  private listScrollingElement: Element
  private detailScrollingElement: Element
  private popUpScrollingElement: Element
  private mainViewElementRef: ElementRef<HTMLElement>
  scrollingElement$ = new ReplaySubject<Element>(1)
  listScrollingElement$ = new ReplaySubject<Element>(1)
  detailScrollingElement$ = new ReplaySubject<Element>(1)

  // Emojis used for time tracking title
  titleRunningEmojis = ['🕐', '🕑', '🕒', '🕓', '🕔', '🕕', '🕖', '🕗', '🕘', '🕙', '🕚', '🕛']
  titlePausedEmoji = '⏸️'

  // Dark Mode Signal
  isDarkMode$: WritableSignal<boolean> = signal(false)

  constructor(private zone: NgZone) {
    BrowserService.instance = this
    this.observeDeviceSize()

    const windowSize$ = createWindowSize$()
    this.width$ = (windowSize$.pipe(map(size => size?.width)) as Observable<number>).pipe(
      distinctUntilChanged(),
      debounceTime(200),
      shareReplay(1)
    )

    this.height$ = (windowSize$.pipe(map(size => size?.height)) as Observable<number>).pipe(
      distinctUntilChanged(),
      debounceTime(200)
    )

    this.deviceCategory$ = this.width$.pipe(map(width => this.getDeviceCategoryBySize(width))) as Observable<WINDOWSIZE>

    this.online$ = merge(fromEvent(window, 'online').pipe(mapTo(true)), fromEvent(window, 'offline').pipe(mapTo(false)))

    // check if browser is a touch device by attaching an touch start
    // event handlet and removing it again
    const win = this.getWindow()
    if (win) {
      this.lastDeviceCategory = this.getDeviceCategoryBySize(win.innerWidth)

      this.zone.runOutsideAngular(() => {
        const that = this
        if (this._hasTouch === undefined || this._hasMouse === undefined) {
          this._hasTouch = false
          this._hasMouse = true
          win.addEventListener('touchstart', function handleTouch() {
            that._hasTouch = true
            that._hasMouse = false

            win.removeEventListener('touchstart', handleTouch)
          })
        }
      })
    }

    this.disableZoomOnMobileDevices()

    this.resize$ = windowSize$

    // Creating an observable for click events
    const doc = this.getDocument()
    if (doc) {
      this.zone.runOutsideAngular(() => {
        this.documentClick$ = merge(
          fromEvent<MouseEvent>(doc, 'mousedown').pipe(map(event => event.target as HTMLElement)),
          fromEvent<MouseEvent>(doc, 'mouseup').pipe(map(event => event.target as HTMLElement)),
          fromEvent<TouchEvent>(doc, 'touchstart').pipe(map(event => event.touches[0].target as HTMLElement)),
          fromEvent<TouchEvent>(doc, 'click').pipe(map(event => event.target as HTMLElement))
        ).pipe(
          throttleTime(100),
          filter(el => el !== null),
          share()
        )

        this.documentPointerUp$ = fromEvent<PointerEvent>(doc, 'pointerup')
      })
    }

    // Creating an observable for visibility change events. Visible returns true.
    this.visibilityChange$ = merge(
      fromEvent(this.getDocument(), 'visibilitychange').pipe(map(() => !this.getDocument().hidden)),
      fromEvent(this.getWindow(), 'focus').pipe(map(() => true)),
      fromEvent(this.getWindow(), 'blur').pipe(map(() => false))
    )

    // subscribe here to the resize observable to ensure the signal "contentSize" is firing
    this.contentResize$.subscribe()
  }

  private setContentResizeObservable(): void {
    const scrollingElement = this.listScrollingElement || this.scrollingElement

    if (!scrollingElement || !window.ResizeObserver || isTest()) {
      return
    }

    if (this.contentResizeObserver) {
      this.contentResizeObserver.disconnect()
    }

    this.contentResizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      entries.forEach(entry => {
        if (entry.borderBoxSize?.length) {
          const { inlineSize: width, blockSize: height } = entry.borderBoxSize[0]
          this._contentSize$.next({ width, height })
        } else if (entry.contentRect) {
          const { width, height } = entry.contentRect
          this._contentSize$.next({ width, height })
        }
      })
    })

    this.contentResizeObserver.observe(scrollingElement)
  }

  get contentResize$(): Observable<{ width: number; height: number }> {
    return this._contentSize$.pipe(
      throttleTime(200, undefined, { leading: true, trailing: true }),
      distinctUntilChanged((prev, curr) => prev.width === curr.width && prev.height === curr.height),
      tap(size => this.contentSize.set(size))
    )
  }

  /**
   * Determines if the main content is widescreen
   * @returns {Observable<boolean>}
   */
  get isMainContentWidescreen$(): Observable<boolean> {
    if (this._isMainContentWidescreen$) {
      return this._isMainContentWidescreen$
    }

    return (this._isMainContentWidescreen$ = this.contentResize$.pipe(
      map(size => {
        return this.getDeviceCategoryBySize(size.width) === WINDOWSIZE.EXTRALARGEDESKTOP
      }),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true })
    ))
  }

  /**
   * Determines if the main content is widescreen
   * @returns {Signal<boolean>}
   */
  get isMainContentWidescreen(): Signal<boolean> {
    return computed(() => {
      const size = this.contentSize()
      return this.getDeviceCategoryBySize(size.width) === WINDOWSIZE.EXTRALARGEDESKTOP
    })
  }

  /**
   * Gets the main content size
   * This is the size of the container either the list in split view or the main scrolling element
   * @returns {Signal<{ width: number; height: number }>}
   */
  get mainContentSize(): Signal<{ width: number; height: number }> {
    return this.contentSize.asReadonly()
  }

  /**
   * Gets the device category according to the main content size
   * @returns {Observable<WINDOWSIZE>}
   */
  get mainContentDeviceCategory$(): Observable<WINDOWSIZE> {
    if (this._mainContentDeviceCategory$) {
      return this._mainContentDeviceCategory$
    }

    return (this._mainContentDeviceCategory$ = this.contentResize$.pipe(
      map(size => {
        return this.getDeviceCategoryBySize(size.width)
      }),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true })
    ))
  }

  get isMobileScreen(): boolean {
    return this.lastDeviceCategory === WINDOWSIZE.SMARTPHONE
  }

  get isTabletScreen(): boolean {
    return this.lastDeviceCategory === WINDOWSIZE.TABLET
  }

  get isDesktopScreen(): boolean {
    return (
      this.lastDeviceCategory === WINDOWSIZE.DESKTOP ||
      this.lastDeviceCategory === WINDOWSIZE.LARGEDESKTOP ||
      this.lastDeviceCategory === WINDOWSIZE.EXTRALARGEDESKTOP
    )
  }

  fireResize(): void {
    const win = this.getWindow()
    if (win) {
      win.dispatchEvent(new Event('resize'))
    }
  }

  disableZoomOnMobileDevices(): void {
    const win = this.getWindow()
    if (win) {
      win.addEventListener('gestureend', (evt: any) => {
        if (evt.scale !== 1) {
          evt.preventDefault()
          document.body.style.transform = 'scale(1)'
        }
      })
    }
  }

  getDeviceCategoryBySize(width: number): WINDOWSIZE {
    let category: WINDOWSIZE
    if (width < 768) {
      category = WINDOWSIZE.SMARTPHONE
    } else if (width >= 768 && width < 992) {
      category = WINDOWSIZE.TABLET
    } else if (width >= 992 && width < 1200) {
      category = WINDOWSIZE.DESKTOP
    } else if (width >= 1200 && width < 1920) {
      category = WINDOWSIZE.LARGEDESKTOP
    } else {
      category = WINDOWSIZE.EXTRALARGEDESKTOP
    }
    this.lastDeviceCategory = category
    return category
  }

  /**
   * Gets the device category based on the user agent
   * @returns {DeviceCategory}
   */
  getDeviceCategory(): DeviceCategory {
    if (mobileRegex.test(navigator.userAgent)) {
      return DeviceCategory.Mobile
    }

    if (tabletRegex.test(navigator.userAgent)) {
      return DeviceCategory.Tablet
    }

    return DeviceCategory.Desktop
  }

  /**
   * Enable or disable background scrolling. Good when opening modals etc.
   * @param value Boolean if scrolling should be enabled or disabled
   */
  public setBodyScrolling(value: boolean) {
    this.zone.runOutsideAngular(() => {
      const doc = this.getDocument()
      if (doc && doc.body && doc.documentElement) {
        if (!value) {
          doc.body.style.top = -doc.documentElement.scrollTop + 'px'
          doc.body.classList.add('aw-lyt--overflow-hidden')
          doc.documentElement.classList.add('aw-lyt--overflow-hidden')
        } else {
          doc.body.classList.remove('aw-lyt--overflow-hidden')
          doc.documentElement.classList.remove('aw-lyt--overflow-hidden')
          doc.documentElement.scrollTop = -Number(doc.body.style.top.replace('px', ''))
          doc.body.style.top = 'initial'
        }
      }
    })
  }
  /**
   * Returns a boolean if scrolling is enabled or not
   */
  public getBodyScrolling(): boolean {
    const doc = this.getDocument()
    if (doc) {
      return !doc.body.classList.contains('aw-lyt--overflow-hidden')
    }
    return true
  }

  /**
   * Returns the document object of the browser
   */
  public getDocument(): Document {
    if (!this._doc) {
      this._doc = document
    }
    return this._doc
  }

  /**
   * Returns the document object of the browser
   */
  public getWindow(): Window {
    if (!this._win) {
      this._win = window
    }
    return this._win
  }

  /**
   * Returns true if the device is a touch device
   */
  public isTouchDevice(): boolean {
    return this._hasTouch
  }

  /**
   * Returns true if the device is a touch device
   */
  public isTouchOnlyDevice(): boolean {
    const hasTouch = 'ontouchend' in document
    return (this._hasTouch || hasTouch) && !this._hasMouse
  }

  /**
   * Returns true is device is IOs
   * @returns {boolean}
   */
  public isIOsDevice(): boolean {
    const isSafari = this.getBrowserName() === 'safari'
    const hasTouch = this.isTouchDevice() || 'ontouchend' in document

    return isSafari && hasTouch
  }

  public isIPadDevice(): boolean {
    // @ts-ignore
    return /iPad/.test(navigator.userAgent) && !window.MSStream
  }

  public isIPhone(): boolean {
    // @ts-ignore
    return /iPhone/.test(navigator.userAgent) && !window.MSStream
  }

  /**
   * Returns true if the device is a touch device
   */
  public isMouseDevice(): boolean {
    return this._hasMouse
  }

  public getSubdomain(): string {
    const host = this.getWindow().location.hostname
    const regex = /(.*?)\.(?=[^\/]*\..{2,5})/i

    const result = host.match(regex)

    return result ? result[1] : null
  }

  /**
   * Determines if the actual subdomain is a custom one
   * @return {boolean}
   */
  public isCustomSubdomain(): boolean {
    const sd = this.getSubdomain() ? this.getSubdomain().toLowerCase() : null
    return sd && sd !== 'www' && sd !== 'login' && sd !== 'integrations' && sd !== 'app'
  }

  /**
   * Checks the pixel radius of the screen the browser is running it and returns the number.
   * Used for images.
   */
  public getPixelRatioFactor(): number {
    if (!this.pixeFactor) {
      const win = this.getWindow()
      this.pixeFactor = getPixelRatio(win)
    }
    return this.pixeFactor
  }

  public blurMainContent(blur: boolean, allFramework = true) {
    this.zone.runOutsideAngular(() => {
      const doc = this.getDocument()
      if (doc) {
        let lytEl = doc.getElementById('aw-lyt')
        if (!allFramework) {
          lytEl = doc.getElementById('aw-lyt__main')
        }
        if (lytEl) {
          if (blur) {
            lytEl.classList.add('blur')
          } else {
            lytEl.classList.remove('blur')
          }
        }
      }
    })
  }

  /**
   * Returns the timezone of the current browser
   */
  getTimezone(): string {
    try {
      return Intl.DateTimeFormat().resolvedOptions().timeZone || 'Europe/Berlin'
    } catch {
      return 'Europe/Berlin'
    }
  }

  /**
   * Sets the title of the app
   * @param title
   */
  setAppTitle(title: string): void {
    const doc = this.getDocument()
    if (doc) {
      if (title && title.trim() !== '') {
        doc.title = `awork • ${title}`.trim()
      } else {
        doc.title = `awork`
      }
    }
  }

  /**
   * Opens the help
   */
  openHelp(userId: string, searchParam?: string): void {
    let searchQueryParam: string = ''
    if (searchParam) {
      searchQueryParam = `&q=${searchParam}`
    }

    const win = this.getWindow()
    if (win) {
      win.open(`${q.translations.menu.supportUrl}?uid=${userId}${searchQueryParam}`, '_blank')
    }
  }

  /**
   * Opens the support chat
   * @returns {boolean} returns false if there was an error initialising the chat
   */
  showSupportChat(): boolean {
    // TODO: check workspace plan
    try {
      Intercom('show')
      return true
    } catch (ex) {
      return false
    }
  }

  /**
   * Returns the user agent name
   */
  getBrowserName(): string {
    const browser = getBrowserInfo()
    return browser.name?.toLowerCase()
  }

  /**
   * Checks if the support workspace is currently available
   * Has some weaknesses, but does its job
   */
  isSupportCurrentlyOnline(): boolean {
    const todayInUserTime = new Date()
    return this.isSupportOnlineAtDate(todayInUserTime)
  }

  /**
   * Returns true, if given date is in our support hours in DE
   * @param {Date} todayInUserTime
   * @returns {boolean}
   */
  isSupportOnlineAtDate(todayInUserTime: Date): boolean {
    const { supportStart, supportEnd } = this.getSupportDateRange(todayInUserTime)

    // support is only weekdays (1 - 5)
    const utcSupportDayOftheWeek = todayInUserTime.getUTCDay()
    if (utcSupportDayOftheWeek > 0 && utcSupportDayOftheWeek < 6) {
      if (isWithinRange(todayInUserTime, supportStart, supportEnd)) {
        return true
      }
    }

    return false
  }

  /**
   * Returns the next date support is online
   * @param {Date} today
   * @returns {Date}
   */
  nextSupportOnlineDate(today: Date): Date {
    const { supportEnd } = this.getSupportDateRange(today)
    let nextDate = today

    if (isWeekend(today) || (isFriday(today) && isAfter(today, supportEnd))) {
      nextDate = addDays(endOfWeek(today, 1), 1)
    } else if (isAfter(today, supportEnd)) {
      nextDate = addDays(today, 1)
    }

    return nextDate
  }

  /**
   * Returns the supportStart and supportEnd dates with the correct timezone
   * @param {Date} todayInUserTime
   */
  getSupportDateRange(todayInUserTime: Date): { supportStart: Date; supportEnd: Date } {
    // Define the last Sunday in March and the last Sunday in October for the given year
    const getLastSunday = (month: number, year: number) => {
      const lastDay = new Date(Date.UTC(year, month + 1, 0))
      lastDay.setUTCDate(lastDay.getUTCDate() - lastDay.getUTCDay())
      return lastDay
    }

    const year = todayInUserTime.getUTCFullYear()
    const dstStart = getLastSunday(2, year) // March (2 for zero-indexed month)
    const dstEnd = getLastSunday(9, year) // October (9 for zero-indexed month)

    // Check if the current date is within DST
    const isDST = todayInUserTime >= dstStart && todayInUserTime < dstEnd

    // Set the timezone offset based on DST status
    const CESTTimeZoneOffset = isDST ? 2 : 1

    const supportStart = new Date(
      Date.UTC(
        todayInUserTime.getUTCFullYear(),
        todayInUserTime.getUTCMonth(),
        todayInUserTime.getUTCDate(),
        9 - CESTTimeZoneOffset,
        0,
        0
      )
    )

    const supportEnd = new Date(supportStart.getTime() + 8 * 60 * 60 * 1000) // supportEnd is 8 hours after supportStart

    return { supportStart, supportEnd }
  }

  /**
   * Sets the current main scrolling element
   * @param {Element} element
   */
  setScrollingElement(element: Element): void {
    this.scrollingElement = element
    this.setContentResizeObservable()
    this.scrollingElement$.next(element)
  }

  /**
   * Gets the current main scrolling element
   * @returns {Element}
   */
  getScrollingElement(): Element {
    return this.scrollingElement ? this.scrollingElement : this.getDocument().scrollingElement
  }

  setMainViewElementRef(elementRef: ElementRef<HTMLElement>): void {
    this.mainViewElementRef = elementRef

    if (isTest()) {
      return
    }

    if (this.mainContentResizeObserver) {
      this.mainContentResizeObserver.disconnect()
    }

    this.mainContentResizeObserver = new ResizeObserver(entries => {
      entries.forEach(entry => {
        this.mainViewWidth$.next(entry.contentRect.width)
      })
    })

    this.mainContentResizeObserver.observe(elementRef.nativeElement)
  }

  getMainViewElementRef(): ElementRef<HTMLElement> {
    return this.mainViewElementRef
  }

  selectMainViewWidth(): Observable<number> {
    return this.mainViewWidth$.pipe(distinctUntilChanged(), debounceTime(200))
  }

  /**
   * Sets the menu scrolling element
   * @param {Element} element
   */
  setMenuScrollingElement(element: Element): void {
    this.menuScrollingElement = element
  }

  /**
   * Gets the current list scrolling element (used in split view)
   * @returns {Element}
   */
  getListScrollingElement(): Element {
    return this.listScrollingElement
  }

  /**
   * Sets the list scrolling element
   * @param {Element} element
   */
  setListScrollingElement(element: Element): void {
    this.listScrollingElement = element
    this.setContentResizeObservable()
    this.listScrollingElement$.next(element)
  }

  /**
   * Gets the current detail page scrolling element (used in split view)
   * @returns {Element}
   */
  getDetailScrollingElement(): Element {
    return this.detailScrollingElement
  }

  /**
   * Sets the detail page scrolling element
   * @param {Element} element
   */
  setDetailScrollingElement(element: Element): void {
    this.detailScrollingElement = element
    this.detailScrollingElement$.next(element)
  }

  /**
   * Gets the current popup scrolling element
   * @returns {Element}
   */
  getPopUpScrollingElement(): Element {
    return this.popUpScrollingElement
  }

  /**
   * Sets the popUp scrolling element
   * @param {Element} element
   */
  setPopUpScrollingElement(element: Element): void {
    this.popUpScrollingElement = element
  }

  /**
   * Gets the menu scrolling element
   * @returns {Element}
   */
  getMenuScrollingElement(): Element {
    return this.menuScrollingElement ? this.menuScrollingElement : this.getDocument().scrollingElement
  }

  /**
   * Determines if the browser has dark mode set
   */
  isBrowserDarkMode(): boolean {
    try {
      return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
    } catch (_) {
      return false
    }
  }

  /**
   * Determines if the app has dark mode set
   */
  isDarkModeActive(): boolean {
    return this.isDarkMode$()
  }

  get isMobilePhone(): boolean {
    return (
      !!window.navigator.userAgent.match(/Android/) ||
      !!navigator.userAgent.match(/iPhone/) ||
      !!navigator.platform.match(/iPhone/)
    )
  }

  /**
   * Gets the device's OS
   * @returns {string}
   */
  getOS(): string {
    const userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod']

    if (macosPlatforms.indexOf(platform) !== -1) {
      return 'Mac'
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      return 'iOS'
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      return 'Windows'
    } else if (/Android/.test(userAgent)) {
      return 'Android'
    } else if (/Linux/.test(platform)) {
      return 'Linux'
    } else {
      return 'Unknown'
    }
  }

  /**
   * Determines if the device is from apple
   */
  get isAppleDevice(): boolean {
    return ['Mac', 'iOS'].includes(this.getOS())
  }

  /**
   * Observes the device size and sets the deviceSize signal
   */
  private observeDeviceSize() {
    this._deviceSize = signal<WINDOWSIZE>(this.getDeviceCategoryBySize(window.innerWidth))

    if (!isResizeObserverAvailable()) {
      return
    }

    this.windowSizeObserver = createResizeObserver((entries: ResizeObserverEntry[]) => {
      entries.forEach(entry => {
        const { width } = entry.contentRect
        this._deviceSize.set(this.getDeviceCategoryBySize(width))
      })
    })

    this.windowSizeObserver.observe(document.body)
  }

  /**
   * Getter for the deviceSize signal
   */
  get deviceSize(): Signal<WINDOWSIZE> {
    return this._deviceSize.asReadonly()
  }

  /**
   * Adds the 'grabbing' class to the body element
   */
  addGrabbingClass(): void {
    document.body.classList.add('grabbing')
  }

  /**
   * Removes the 'grabbing' class from the body element
   */
  removeGrabbingClass(): void {
    document.body.classList.remove('grabbing')
  }

  /**
   * Adds the 'ew-resize' cursor class to the body element
   */
  addEwResizeCursorClass(): void {
    document.body.classList.add('dragging-ew')
  }

  /**
   * Removes the 'ew-resize' cursor class from the body element
   */
  removeEwResizeCursorClass(): void {
    document.body.classList.remove('dragging-ew')
  }
}
