<aw-popup
  #popupRef
  [variant]="variants.Onboarding"
  [zIndex]="10000"
  [widthType]="'block'"
  [showBackground]="false"
  [hasBackdrop]="false"
  [hideOnEnter]="false"
  [hideOnEscape]="false"
  [hasCaret]="true"
  [origin]="origin()"
  [position]="position()"
  [popupWidth]="popupWidth()"
  (showing)="onShow.emit()"
  (hiding)="onHide.emit()"
>
<div class="aw-2-p--xs aw-2-flx aw-2-flx--column aw-2-flx--gap-s">
  <div class="aw-2-flx aw-2-flx--column aw-2-flx--gap-s">
    <div class="content">
      @if (popup().title) {
        <div class="aw-2-txt--night aw-2-txt--bold aw-2-mb--xs" [innerHTML]="popup().title"></div>
      }
    
      @if (popup().text) {
        <div class="aw-2-txt--steel aw-2-txt--s" [class.aw-2-mr--xxl]="!popup().title">{{ popup().text }}</div>
      }
    </div>
  </div>

  @if (popup().media) {
    <div class="media-wrapper">
      @if (popup().type === 'video') {
        <video autoplay loop [src]="q.assetsUrl + popup().media[lang]"></video>
      } @if (popup().type === 'image') {
        <img [src]="q.assetsUrl + popup().media[lang]" />
      }
    </div>
  }

  <div class="aw-2-flx aw-2-flx--row aw-2-flx--justify-space-between aw-2-flx--gap-m">
    @if (popup().back) {
      <aw-button [type]="'tertiary'" [text]="popup().back" [color]="colors.Purple" (click)="back()" />
    }

    @if (popup().next) {
      <aw-button 
        [class.aw-2-flx--flex-1]="!popup().back" 
        [type]="popup().back && !popup().isLast 
          ? 'secondary' 
          : 'primary'" 
        [widthType]="popup().back 
          ? 'auto' 
          : 'block'" 
        [text]="popup().next" 
        [color]="colors.Purple" 
        (click)="next()" 
      />
    }
  </div>

  <i class="close aw-2-icn aw-2-icn--s" (click)="hide()">close</i>
</div>
</aw-popup>
