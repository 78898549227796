import { Injectable, Pipe, PipeTransform } from '@angular/core'
import { SECONDS_IN_WORKING_DAY, secondsToTimeObject } from '@awork/_shared/functions/time-operations'
import { SettingsQuery } from '@awork/framework/state/settings.query'

type DisplayMode = 'onlyDuration' | 'onlyUnit' | 'default'

@Pipe({
  name: 'dynamicDuration',
  standalone: true
})
@Injectable({
  providedIn: 'root'
})
export class DynamicDurationPipe implements PipeTransform {
  constructor(private settingsQuery: SettingsQuery) {}

  transform(
    durationInSeconds: number,
    secondDuration: number = null,
    displayMode: DisplayMode = 'default',
    daysPrecision = 1
  ): string {
    const dynamicDurationFormatSetting = this.settingsQuery.getDynamicDurationSetting()
    const hasSecondDuration = !!secondDuration

    if (!durationInSeconds) {
      durationInSeconds = 0
    }

    switch (dynamicDurationFormatSetting) {
      case 'decimal_days':
        return this.formatDecimalDays(durationInSeconds, !hasSecondDuration, displayMode, daysPrecision)
      case 'hours_minutes':
        return this.formatHoursMinutes(durationInSeconds, !hasSecondDuration, displayMode)
      case 'auto':
      default:
        return this.formatAuto(durationInSeconds, secondDuration, displayMode)
    }
  }

  /**
   * Formats the duration in days
   * @param {number} duration
   * @param {boolean} showUnit
   * @param {DisplayMode} displayMode
   * @returns {string}
   */
  formatDecimalDays(duration: number, showUnit: boolean, displayMode: DisplayMode, daysPrecision = 1): string {
    const durationInDays = duration / SECONDS_IN_WORKING_DAY
    const [integer, decimals] = durationInDays.toString().split('.')

    // show decimals if not every decimal place is 0
    const showDecimal =
      decimals?.length &&
      decimals
        .slice(0, daysPrecision)
        .split('')
        .some(decimal => decimal !== '0')
    const roundedDuration = showDecimal ? durationInDays.toFixed(daysPrecision) : integer

    const durationString = `${roundedDuration}`.replace('.', ',')

    return this.applyDisplayMode(durationString, 'd', showUnit, displayMode)
  }

  /**
   * Formats duration in hours:minutes
   * @param {number} duration
   * @param {boolean} showUnit
   * @param {DisplayMode} displayMode
   * @returns {string}
   */
  formatHoursMinutes(duration: number, showUnit: boolean, displayMode: DisplayMode): string {
    const roundedDuration = Math.round(duration / 60) * 60
    const { hours, minutes } = secondsToTimeObject(roundedDuration)

    const minutesString = minutes > 0 && minutes < 10 ? `0${Math.abs(minutes)}` : `${Math.abs(minutes)}`
    const durationString = `${hours}${minutes ? ':' + minutesString : ''}`

    return this.applyDisplayMode(durationString, 'h', showUnit, displayMode)
  }

  /**
   * Formats duration as decimal days or hours according to the duration
   * @param {number} duration
   * @param {number} secondDuration
   * @param {DisplayMode} displayMode
   * @returns {string}
   */
  formatAuto(duration: number, secondDuration: number, displayMode: DisplayMode, daysPrecision = 1): string {
    const { hours } = secondsToTimeObject(duration)
    const { hours: secondDurationHours } = secondsToTimeObject(secondDuration)

    const showAsDays = hours > 8
    const showSecondDurationAsDays = secondDurationHours > 8
    // Show units only when there is a second duration that has a different unit
    const showUnit = !secondDuration || showAsDays !== showSecondDurationAsDays

    if (showAsDays) {
      return this.formatDecimalDays(duration, showUnit, displayMode, daysPrecision)
    } else {
      const durationString = this.formatHoursMinutes(duration, false, null)
      // Force to hide unit when in cases where the duration is 0 and there is a second duration
      if (durationString === '0' && secondDuration) {
        return durationString
      }

      return this.applyDisplayMode(durationString, 'h', showUnit, displayMode)
    }
  }

  /**
   * Applies display mode configuration to formatted duration
   * @param {string} duration
   * @param {string} unit
   * @param {boolean} showUnit
   * @param {DisplayMode} displayMode
   * @returns {string}
   */
  applyDisplayMode(duration: string, unit: string, showUnit: boolean, displayMode: DisplayMode): string {
    if (displayMode === 'onlyDuration' || !showUnit) {
      return duration
    }

    if (displayMode === 'onlyUnit') {
      return unit
    }

    return duration + unit
  }
}
