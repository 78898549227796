import { Injectable } from '@angular/core'
import { apiEndpoint, isLiveMobile } from '@awork/environments/environment'
import { ApiClient, QueryParams } from '@awork/_shared/services//api-client/ApiClient'
import { FileUpload, FileUploadStatus } from '@awork/_shared/models/file-upload.model'
import {
  catchError,
  filter,
  forkJoin,
  map,
  mergeMap,
  Observable,
  of,
  ReplaySubject,
  Subject,
  switchMap,
  takeUntil,
  tap
} from 'rxjs'
import { Company } from '@awork/features/company/models/company.model'
import { ProjectTemplate } from '@awork/features/project/models/project-template.model'
import { HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http'
import { sortByAsc } from '@awork/_shared/functions/lodash'
import { getPixelRatio } from '@awork/_shared/functions/browser-infos'
import { UserStore } from '@awork/features/user/state/user.store'
import { UserQuery } from '@awork/features/user/state/user.query'
import { CompanyStore } from '@awork/features/company/state/company.store'
import { WorkspaceStore } from '@awork/features/workspace/state/workspace.store'
import { AccountState } from '@awork/_shared/models/account.model'
import { AccountQuery } from '@awork/_shared/state/account.query'
import { ProjectStore } from '@awork/features/project/state/project.store'
import { TimeTrackingReport } from '@awork/features/time-tracking/models/time-tracking-report.model'
import { FileExternal } from '@awork/_shared/models/file-external.model'
import { TrackingService } from '@awork/_shared/services/tracking-service/tracking.service'
import { TrackingEvent } from '@awork/_shared/services/tracking-service/events'
import {
  BlobFile,
  UploadInfo,
  ValidExportEntityTypes,
  ValidFileEntities,
  ValidFileEntityTypes,
  ValidImageFileEntities,
  ValidImageFileEntityTypes
} from '@awork/_shared/services/file-service/types'

/* eslint-enable max-len */

@Injectable({ providedIn: 'root' })
export class FileService {
  private readonly url: string
  private readonly globalUrl: string
  private readonly temporaryUrl: string
  private readonly imageUrl: string

  // one hour
  private readonly fileTimeout = 30000 * 120

  currentFetchedFiles: FileUpload[] = []
  fetchedFiles = new ReplaySubject<FileUpload[]>(1)
  fileUploaded = new Subject<FileUpload>()
  fileUploading = new Subject<FileUpload>()
  fileDeleted = new Subject<FileUpload>()
  fileUploadLimit = new Subject<void>()
  fileUploadCanceled = new Subject<FileUpload>()
  currentEntity: {
    entityName?: ValidFileEntityTypes
    entityId?: string
  } = {}

  constructor(
    private apiClient: ApiClient,
    private userStore: UserStore,
    private companyStore: CompanyStore,
    private workspaceStore: WorkspaceStore,
    private projectStore: ProjectStore,
    private accountQuery: AccountQuery,
    private userQuery: UserQuery,
    private trackingService: TrackingService
  ) {
    this.url = `${apiEndpoint}`
    this.globalUrl = `${apiEndpoint}/files`
    this.temporaryUrl = `${apiEndpoint}/temporaryfiles`
    this.imageUrl = `${apiEndpoint}/files/images`
  }

  // Temporary files
  /**
   * Returns all the temporary files of the current user
   * @param {QueryParams} queryParams
   * @returns {Observable<HttpResponse<FileUpload[]>>}
   */
  getTemporaryFiles(queryParams?: QueryParams): Observable<FileUpload[]> {
    const params = ApiClient.getQueryParams(queryParams)
    return this.apiClient
      .get<FileUpload[]>(`${this.temporaryUrl}`, {
        params,
        timeout: this.fileTimeout
      })
      .pipe(
        filter(temporaryAttachments => Array.isArray(temporaryAttachments)),
        map(temporaryAttachments => temporaryAttachments.map(attachment => new FileUpload(attachment)))
      )
  }

  /**
   * Adds file to currentFetched files if file belongs to the current entity
   * @param {FileUpload} fileUpload
   * @returns {boolean} true if the file is saved in the service currentFetchedFiles
   * @private
   */
  private saveFileInFetchedFiles(fileUpload: FileUpload): boolean {
    const { entityId, entityName } = this.currentEntity

    const isCompatibleEntity =
      entityName &&
      (fileUpload.entityType === entityName || (fileUpload.entityType === 'tasks' && entityName === 'projects'))
    const fileBelongsToEntity = isCompatibleEntity && fileUpload.entityId === entityId

    if (fileUpload.type === 'temp' || !fileBelongsToEntity) {
      return false
    }

    const isFileInArray = this.currentFetchedFiles.find(file => file.id === fileUpload.id)

    if (!isFileInArray) {
      this.currentFetchedFiles.push(fileUpload)
      this.fetchedFiles.next(this.currentFetchedFiles)
    }

    return true
  }

  /**
   * Updates the files references and subjects on creation
   * @param {FileUpload} savedFile
   */
  onFileCreated(savedFile: FileUpload): void {
    const isSavedInFetchedFiles = this.saveFileInFetchedFiles(savedFile)

    if (isSavedInFetchedFiles) {
      this.fileUploaded.next(savedFile)
    }
  }

  /**
   * Updates the files references and subjects on deletion
   * @param {FileUpload} deletedFile
   */
  onFileDeleted(deletedFile: FileUpload): void {
    this.currentFetchedFiles = this.currentFetchedFiles.filter(file => file.id !== deletedFile.id)
    this.fetchedFiles.next(this.currentFetchedFiles)
    this.fileDeleted.next(deletedFile)
  }

  /**
   * Returns the temporary file
   * @param {string} fileId - The id of the file
   * @returns {Observable<FileUpload>}
   */
  getTemporaryFile(fileId: string): Observable<FileUpload> {
    return this.apiClient
      .get<FileUpload>(`${this.temporaryUrl}/${fileId}`)
      .pipe(map(temporaryAttachment => new FileUpload(temporaryAttachment)))
  }

  /**
   * Fetches the preview of a given file
   * It will throw an error if the preview was not created successfully
   * @returns {Observable<void>}
   */
  fetchPDFPreview(fileId: string): Observable<Blob> {
    const url = this.getPDFPreviewLink(fileId)

    return this.apiClient.get<Blob>(url, { responseType: 'blob' })
  }

  getPDFPreviewLink(fileId: string): string {
    let queryOptions = '?inline=true'

    if (isLiveMobile) {
      queryOptions = queryOptions + '&'
      const account: AccountState = this.accountQuery.getAccount()
      if (account) {
        queryOptions += new URLSearchParams({ jwt: account.accessToken, 'aw-mobile': 'true' } as any).toString()
      }
    }

    return `${this.globalUrl}/${fileId}/pdf${queryOptions}`
  }

  /**
   * Sends the file to the API
   * @param {FileUpload} fileUpload
   * @returns {Observable<FileUpload>}
   */
  private sendFile(fileUpload: FileUpload): Observable<FileUpload> {
    fileUpload.progress = 0
    fileUpload.status = FileUploadStatus.Uploading

    // Use the new file upload flow
    if (fileUpload.uploadInfo) {
      return this.apiClient
        .put<HttpEvent<FileUpload>>(fileUpload.uploadInfo.uploadURL, fileUpload.file, {
          reportProgress: true,
          observe: 'events',
          headers: new HttpHeaders().set('ngsw-bypass', 'true').set('x-ms-blob-type', 'BlockBlob'),
          timeout: this.fileTimeout
        })
        .pipe(
          switchMap(event => {
            return event.type === HttpEventType.Response
              ? this.apiClient
                  .post<FileUpload>(this.getUploadUrl(fileUpload, true), {
                    uploadId: fileUpload.uploadInfo.uploadId,
                    fileName: fileUpload.name
                  })
                  .pipe(
                    map(newFileUpload => {
                      fileUpload = new FileUpload({ ...fileUpload, ...newFileUpload })
                      return this.trackFileUploadProgress(fileUpload, event)
                    })
                  )
              : of(this.trackFileUploadProgress(fileUpload, event))
          }),
          catchError(() => {
            fileUpload.status = FileUploadStatus.Error

            // Update the file upload status in the progress component
            this.fileUploading.next(fileUpload)

            return of(fileUpload)
          }),
          // Cancel the upload if canceled === true
          takeUntil(
            this.fileUploadCanceled.pipe(
              filter(canceledFileUpload => canceledFileUpload.id === fileUpload.id),
              tap(() => {
                fileUpload.status = FileUploadStatus.Canceled
                this.fileUploading.next(fileUpload)
              })
            )
          )
        )
    }

    return this.apiClient
      .post<HttpEvent<FileUpload>>(this.getUploadUrl(fileUpload), this.getFormData(fileUpload.file), {
        reportProgress: true,
        observe: 'events',
        headers: new HttpHeaders().set('ngsw-bypass', 'true'),
        timeout: this.fileTimeout
      })
      .pipe(
        map(event => {
          return this.trackFileUploadProgress(fileUpload, event)
        }),
        catchError(() => {
          fileUpload.status = FileUploadStatus.Error

          // Update the file upload status in the progress component
          this.fileUploading.next(fileUpload)

          return of(fileUpload)
        }),
        // Cancel the upload if canceled === true
        takeUntil(
          this.fileUploadCanceled.pipe(
            filter(canceledFileUpload => canceledFileUpload.id === fileUpload.id),
            tap(() => {
              fileUpload.status = FileUploadStatus.Canceled
              this.fileUploading.next(fileUpload)
            })
          )
        )
      )
  }

  /**
   * Sends the file to the API to convert it to PDF
   * @returns {Observable<FileUpload>}
   * @param file
   */
  fileToPdf(file: File): Observable<any> {
    return this.apiClient.post(`${this.url}/files/to/pdf`, this.getFormData(file), {
      responseType: 'blob',
      observe: 'response'
    })
  }

  /**
   * Updates a file in the API
   * @param {File} file
   * @param {string} url
   * @returns {Observable<FileUpload>}
   */
  private updateFile(file: File, url: string): Observable<FileUpload> {
    return this.apiClient
      .put<FileUpload>(url, this.getFormData(file))
      .pipe(map(fileUpload => new FileUpload(fileUpload)))
  }

  /**
   * Sends the new temporary file
   * @param {File} attachment - The attachment to be sent
   * @param {ValidFileEntityTypes} entityName - The name of the entity
   * @param {ValidFileEntities} entity - The entity to be used to link the temp file
   * @returns {Observable<FileUpload>}
   */
  sendTemporaryFile(
    attachment: File,
    entityName?: ValidFileEntityTypes,
    entity?: ValidFileEntities
  ): Observable<FileUpload> {
    const fileUpload = new FileUpload({
      filename: attachment.name,
      mimeType: attachment.type,
      name: attachment.name,
      file: attachment,
      type: 'temp'
    })

    if (entityName && entity) {
      fileUpload.entityId = entity.id
      fileUpload.entity = entity
      fileUpload.entityType = entityName
    }

    return this.sendFile(fileUpload)
  }

  /**
   * Updates the temporary file
   * @param {string} fileId - The id of the file
   * @param {File} attachment - The attachment to be updated
   * @returns {Observable<FileUpload>}
   */
  updateTemporaryFile(fileId: string, attachment: File): Observable<FileUpload> {
    return this.updateFile(attachment, `${this.temporaryUrl}/${fileId}`)
  }

  /**
   * Sets the temporary file to global or entity file
   * @param {FileUpload} tempFile
   * @param {ValidFileEntityTypes} entityType
   * @param {Task | Project | Comment} entity
   * @returns {Observable<void>}
   */
  setTemporaryFileToGlobalOrEntityFile(
    tempFile: FileUpload,
    entityType: ValidFileEntityTypes,
    entity: ValidFileEntities
  ): Observable<void> {
    return this.apiClient
      .post<void>(`${this.temporaryUrl}/${tempFile.id}/setentity`, {
        entityType,
        entityId: entity.id
      })
      .pipe(
        tap(() => {
          tempFile.type = 'entity'
          tempFile.entity = entity
          tempFile.entityId = entity.id
          tempFile.entityType = entityType

          this.onFileCreated(tempFile)
        })
      )
  }

  // Entity files
  /**
   * Returns all the files of that particular entity
   * @param {ValidFileEntityTypes} entityName
   * @param {string} entityId
   * @param {QueryParams} queryParams
   * @param {boolean} addFilesToSubject
   * @returns {Observable<FileUpload[]>}
   */
  getEntityFiles(
    entityName: ValidFileEntityTypes,
    entityId: string,
    queryParams?: QueryParams,
    addFilesToSubject = true
  ): Observable<FileUpload[]> {
    const params = ApiClient.getQueryParams(queryParams)
    return this.apiClient
      .get<FileUpload[]>(`${this.url}/${entityName}/${entityId}/files`, {
        params
      })
      .pipe(
        filter(entityAttachments => Array.isArray(entityAttachments)),
        map(entityAttachments => {
          entityAttachments = sortByAsc(entityAttachments, file => file.createdOn)
          const entityFiles = entityAttachments.map(attachment => new FileUpload(attachment))

          if (addFilesToSubject) {
            this.currentEntity = {
              entityName,
              entityId
            }

            this.currentFetchedFiles = entityFiles
            this.fetchedFiles.next(this.currentFetchedFiles)
          }

          return entityFiles
        })
      )
  }

  /**
   * Gets all of the files belonging to a projects, including from its tasks
   * @param {string} projectId
   * @returns {Observable<FileUpload[]>}
   */
  fetchAllProjectFiles(projectId: string): Observable<FileUpload[]> {
    return this.apiClient.get<FileUpload[]>(`${this.url}/projects/${projectId}/allfiles`).pipe(
      filter(files => Array.isArray(files)),
      map(files => {
        const sortedFiles = sortByAsc(files, file => file.createdOn)
        this.currentFetchedFiles = sortedFiles.map(file => {
          const user = this.userQuery.getUser(file.createdBy)
          file.username = user?.shortName

          return new FileUpload(file)
        })

        this.currentEntity = {
          entityName: 'projects',
          entityId: projectId
        }
        this.fetchedFiles.next(this.currentFetchedFiles)
        return this.currentFetchedFiles
      })
    )
  }

  /**
   * Returns the entity file
   * @param {ValidFileEntityTypes} entityName - The name of the entity
   * @param {string} entityId - The id of the entity
   * @param {string} fileId - The id of the file
   * @returns {Observable<FileUpload>}
   */
  getEntityFile(entityName: ValidFileEntityTypes, entityId: string, fileId: string): Observable<FileUpload> {
    return this.apiClient.get<FileUpload>(`${this.url}/${entityName}/${entityId}/files/${fileId}`).pipe(
      map(entityAttachment => {
        const fetchedFile = new FileUpload(entityAttachment)
        this.saveFileInFetchedFiles(fetchedFile)

        return fetchedFile
      })
    )
  }

  /**
   * Returns an url to the image content if the task/project has an attached image (the first best)
   * @param {ValidFileEntityTypes} entityName - The name of the entity
   * @param {string} entityId - The id of the entity
   * @returns {Observable<FileUpload>}
   */
  getEntityPreviewImagePath(entityName: ValidFileEntityTypes, entityId: string): Observable<string> {
    const queryParams: QueryParams = {
      filterBy: `mimeType eq 'image/jpeg' or mimeType eq 'image/png' or mimeType eq 'image/gif'`
    }
    let imagePath: string

    return this.getEntityFiles('tasks', entityId, queryParams, false).pipe(
      map(files => {
        if (files.length > 0) {
          // use the first found image
          const imageFile = files[0]

          // set the crop options
          const pixelRatio = getPixelRatio()
          let queryOptions: any = {
            crop: false,
            width: Math.floor(240 * pixelRatio),
            height: Math.floor(150 * pixelRatio),
            v: new Date(imageFile.updatedOn).getTime()
          }

          // To fetch the file also locally
          if (isLiveMobile) {
            const account: AccountState = this.accountQuery.getAccount()
            if (account) {
              queryOptions = {
                ...queryOptions,
                jwt: account.accessToken,
                'aw-mobile': 'true'
              }
            }
          }

          // fetch the image
          // eslint-disable-next-line max-len
          imagePath = `${apiEndpoint}/${entityName}/${entityId}/files/${imageFile.id}/download?${new URLSearchParams(
            queryOptions
          ).toString()}`
        }
        return imagePath
      }),
      catchError(() => of(imagePath))
    )
  }

  /**
   * saves entity files according to the files type
   * @param {ValidFileEntityTypes} entityName
   * @param {ValidFileEntities} entity
   * @param {FileExternal[] | FileList} attachments
   * @returns {Observable<FileUpload[]>}
   */
  sendEntityFiles(
    entityName: ValidFileEntityTypes,
    entity: ValidFileEntities,
    attachments: FileExternal[] | FileList
  ): Observable<FileUpload[]> {
    const isExternalFiles = !!(attachments?.[0] as FileExternal)?.externalFileUrl
    let filesUploaded$: Observable<FileUpload[]>

    if (!isExternalFiles) {
      filesUploaded$ = forkJoin(
        Array.from(attachments as FileList).map(file => this.sendEntityFile(entityName, entity, file))
      )

      return filesUploaded$.pipe(tap(() => this.trackFileUpload(entityName, isExternalFiles)))
    } else {
      return this.sendEntityExternalFiles(entityName, entity, attachments)
    }
  }

  /**
   * saves entity external files
   * @param {ValidFileEntityTypes} entityName
   * @param {ValidFileEntities} entity
   * @param {FileExternal[] | FileList} attachments
   * @returns {Observable<FileUpload[]>}
   */
  sendEntityExternalFiles(
    entityName: ValidFileEntityTypes,
    entity: ValidFileEntities,
    attachments: FileExternal[] | FileList
  ): Observable<FileUpload[]> {
    let provider = null
    let filesUploaded$: Observable<FileUpload[]>
    const $getFiles =
      entityName === 'tasks' ? this.getEntityFiles(entityName, entity.id) : this.fetchAllProjectFiles(entity.id)

    return $getFiles.pipe(
      mergeMap(allFiles => {
        const externalFiles = attachments as FileExternal[]
        const uniqueFiles = externalFiles.filter(
          externalFile => !allFiles.some(file => file.externalFileUrl === externalFile.externalFileUrl)
        )

        filesUploaded$ = uniqueFiles.length ? this.sendExternalFiles(uniqueFiles, entityName, entity.id) : of([])
        provider = (attachments?.[0] as FileExternal)?.externalProvider

        return filesUploaded$.pipe(tap(() => this.trackFileUpload(entityName, true, provider)))
      })
    )
  }

  /**
   * Tracks creation of entity file
   * @param {string} entityName
   * @param {boolean} isExternalFiles
   * @param {string} externalProvider
   */
  private trackFileUpload(entityName: string, isExternalFiles: boolean, externalProvider?: string): void {
    // track event
    let trackingEntityName: TrackingEvent
    if (entityName === 'projects') {
      trackingEntityName = TrackingEvent.projectFileAttached
    } else if (entityName === 'tasks') {
      trackingEntityName = TrackingEvent.taskFileAttached
    }

    if (trackingEntityName) {
      this.trackingService.trackEvent(trackingEntityName, {
        source: isExternalFiles && externalProvider ? externalProvider : 'local'
      })
    }
  }

  /**
   * Sends the entity file
   * This follows the new file upload flow (https://www.notion.so/awork-io/New-File-Upload-183e4260401a80fa8c0dce73d07b397d)
   * @param {ValidFileEntityTypes} entityName - The name of the entity
   * @param {ValidFileEntities} entity - The entity of the file
   * @param {File} attachment - The attachment to be sent
   * @returns {Observable<FileUpload>}
   */
  sendEntityFile(
    entityName: ValidFileEntityTypes,
    entity: ValidFileEntities,
    attachment: File
  ): Observable<FileUpload> {
    return this.generateUploadUrl().pipe(
      switchMap(uploadInfo => {
        const fileUpload = new FileUpload({
          filename: attachment.name,
          entityId: entity.id,
          entityType: entityName,
          mimeType: attachment.type,
          name: attachment.name,
          file: attachment,
          entity,
          type: 'entity',
          uploadInfo
        })

        return this.sendFile(fileUpload)
      })
    )
  }

  /**
   * Gets the information to upload a file directly to the blob storage
   * @returns {Observable<UploadInfo>}
   */
  private generateUploadUrl(): Observable<UploadInfo> {
    return this.apiClient.post<UploadInfo>(`${this.globalUrl}/generateuploadurl`, {})
  }

  /**
   * Updates the entity file
   * @param {ValidFileEntityTypes} entityName - The name of the entity
   * @param {string} entityId - The id of the entity
   * @param {string} fileId - The id of the file
   * @param {File} attachment - The attachment to be updated
   * @returns {Observable<FileUpload>}
   */
  updateEntityFile(
    entityName: ValidFileEntityTypes,
    entityId: string,
    fileId: string,
    attachment: File
  ): Observable<FileUpload> {
    return this.updateFile(attachment, `${this.url}/${entityName}/${entityId}/files/${fileId}`)
  }

  /**
   * Deletes the entity file
   * @param {ValidFileEntityTypes} entityName - The name of the entity
   * @param {string} entityId - The id of the entity
   * @param {FileUpload} attachment - The attachment to be deleted
   * @returns {Observable<void>}
   */
  deleteEntityFile(entityName: ValidFileEntityTypes, entityId: string, attachment: FileUpload): Observable<void> {
    return this.apiClient.delete<void>(`${this.url}/${entityName}/${entityId}/files/${attachment.id}`).pipe(
      tap(() => {
        this.onFileDeleted(attachment)
      })
    )
  }

  /**
   * Change the entity of the file
   * @param {ValidFileEntityTypes} entityName - The type of the entity
   * @param {string} fileId - The id of the file
   * @param {ValidFileEntityTypes} newEntityName - The new entity type of the file
   * @param {string} newEntityId - The new id of the entity of the file
   * @returns {Observable<void>}
   */
  changeFileEntity(
    entityName: ValidFileEntityTypes,
    entityId: string,
    fileId: string,
    newEntityName: ValidFileEntityTypes,
    newEntityId: string
  ): Observable<void> {
    return this.apiClient.post<void>(`${this.url}/${entityName}/${entityId}/files/${fileId}/changeentity`, {
      entityId: newEntityName,
      entityType: newEntityName
    })
  }

  // TODO: these methods are not being used, we should remove it
  // Global files
  /**
   * Returns all the global files
   * @param {QueryParams} queryParams
   * @returns {Observable<FileUpload[]>}
   */
  getGlobalFiles(queryParams?: QueryParams): Observable<FileUpload[]> {
    const params = ApiClient.getQueryParams(queryParams)
    return this.apiClient
      .get<FileUpload[]>(`${this.temporaryUrl}`, {
        params
      })
      .pipe(
        filter(globalAttachments => Array.isArray(globalAttachments)),
        map(globalAttachments => globalAttachments.map(attachment => new FileUpload(attachment)))
      )
  }

  /**
   * Returns the global file
   * @param {string} fileId - The id of the file
   * @returns {Observable<FileUpload>}
   */
  getGlobalFile(fileId: string): Observable<FileUpload> {
    return this.apiClient
      .get<FileUpload>(`${this.globalUrl}/${fileId}`)
      .pipe(map(globalAttachment => new FileUpload(globalAttachment)))
  }

  /**
   * Sends the global file
   * @param {File} attachment - The attachment to be sent
   * @returns {Observable<FileUpload>}
   */
  sendGlobalFile(attachment: File): Observable<FileUpload> {
    const fileUpload = new FileUpload({
      filename: attachment.name,
      mimeType: attachment.type,
      name: attachment.name,
      file: attachment,
      type: 'global'
    })

    return this.sendFile(fileUpload)
  }

  /**
   * Updates the global file
   * @param {string} fileId - The id of the file
   * @param {File} attachment - The attachment to be updated
   * @returns {Observable<FileUpload>}
   */
  updateGlobalFile(fileId: string, attachment: File): Observable<FileUpload> {
    return this.updateFile(attachment, `${this.globalUrl}/${fileId}`)
  }

  /**
   * Deletes the global file
   * @param {string} fileId - The id of the file
   * @returns {Observable<void>}
   */
  deleteGlobalFile(attachment: FileUpload): Observable<void> {
    return this.apiClient.delete<void>(`${this.globalUrl}/${attachment.id}`).pipe(
      tap(() => {
        this.onFileDeleted(attachment)
      })
    )
  }

  /**
   * Changes the global file to an entity file
   * @param {string} fileId - The id of the file
   * @param {string} entityType - The type of the entity
   * @param {string} entityId - The id of the entity
   * @returns {Observable<void>}
   */
  changeGlobalFileEntity(fileId: string, entityType: ValidFileEntityTypes, entityId: string): Observable<void> {
    return this.apiClient.post<void>(`${this.globalUrl}/${fileId}/changeentity`, { entityType, entityId })
  }

  /**
   * Converts the content of the CSV or excel file to json
   * @param fileUpload
   */
  convertFileContentToJson(fileUpload: FileUpload): Observable<FileUpload> {
    fileUpload.progress = 0
    fileUpload.status = FileUploadStatus.Uploading

    return this.apiClient
      .post<HttpEvent<FileUpload>>(`${this.globalUrl}/to/json/`, this.getFormData(fileUpload.file), {
        reportProgress: true,
        observe: 'events'
      })
      .pipe(
        map(event => {
          return this.trackFileUploadProgress(fileUpload, event)
        }),
        catchError(() => {
          fileUpload.status = FileUploadStatus.Error

          // Update the file upload status in the progress component
          this.fileUploading.next(fileUpload)

          return of(fileUpload)
        }),
        // Cancel the upload if canceled === true
        takeUntil(
          this.fileUploadCanceled.pipe(
            filter(canceledFileUpload => canceledFileUpload.id === fileUpload.id),
            tap(() => {
              fileUpload.status = FileUploadStatus.Canceled
              this.fileUploading.next(fileUpload)
            })
          )
        )
      )
  }

  /**
   * Generates an excel export for the given queryParams and sends it to the user via email
   * @param {ValidExportEntityTypes} entityName - The name of the entity
   * @param {string} filterExpression - The filter string
   * @returns {Observable<void>}
   */
  sendEntityExport(entityName: ValidExportEntityTypes, filterExpression: string): Observable<void> {
    let entity = entityName as string
    if (entityName.toLocaleLowerCase().includes('timetracking')) {
      entity = 'timeentries'
    }

    let filterUrl = entity
    if (filterExpression) {
      filterUrl = `${filterUrl}?filterby=${filterExpression}`
    }

    return this.apiClient.post<void>(`${this.globalUrl}/${entity}/generateexport`, { filterUrl })
  }

  // Images

  /**
   * Uploads the entity's image
   * @param {ValidImageFileEntities} entity - The entity whose image is to be uploaded
   * @param {ValidImageFileEntityTypes} entityName - The name of the entity
   * @param {BlobFile} image - Image file
   * @param {string} imageUrl - Image url
   * @returns {Observable<FileUpload>}
   */
  uploadEntityImage(
    entity: ValidImageFileEntities,
    entityName: ValidImageFileEntityTypes,
    image: BlobFile,
    imageUrl?: string
  ): Observable<FileUpload> {
    let call$: Observable<FileUpload>

    if (image) {
      const formData: FormData = new FormData()
      formData.append('file', image.blob, image.name)
      formData.append('name', image.name)
      formData.append('type', this.getFormDataTypeForEntityImage(entityName))

      call$ = this.apiClient.post<FileUpload>(`${this.imageUrl}/${entityName}/${entity.id}`, formData)
    } else {
      call$ = this.apiClient.post<FileUpload>(`${this.imageUrl}/${entityName}/${entity.id}/byurl`, {
        url: imageUrl,
        name: this.getFileNameForEntityImageByUrl(entityName)
      })
    }

    return call$.pipe(
      map(response => {
        if (!(entity instanceof ProjectTemplate)) {
          entity.hasImage = true
        }

        entity.updatedOn = response ? response.updatedOn : new Date()
        this.updateEntityInStore(entity, entityName)
        return response
      })
    )
  }

  /**
   * Deletes the entity's image
   * @param {ValidImageFileEntities} entity - The entity whose image is to be deleted
   * @param {ValidImageFileEntityTypes} entityName - The name of the entity
   * @returns {Observable<void>}
   */
  deleteEntityImage(entity: ValidImageFileEntities, entityName: ValidImageFileEntityTypes): Observable<void> {
    return this.apiClient.delete<void>(`${this.imageUrl}/${entityName}/${entity.id}`).pipe(
      map(() => {
        if (!(entity instanceof ProjectTemplate)) {
          entity.hasImage = false
        }

        this.updateEntityInStore(entity, entityName)
      })
    )
  }

  /**
   * Retries the file upload
   * @param fileUpload
   */
  retryUpload(fileUpload: FileUpload) {
    return this.sendFile(fileUpload)
  }

  /**
   * Generates the upload url based on the file and entity type
   * @param {FileUpload} fileUpload
   * @param {boolean} byUploadId
   */
  private getUploadUrl(fileUpload: FileUpload, byUploadId?: boolean): string {
    switch (fileUpload.type) {
      case 'temp':
        return this.temporaryUrl
      case 'global':
        return this.globalUrl
      case 'entity':
        const baseUrl = `${this.url}/${fileUpload.entityType}/${fileUpload.entityId}`
        return byUploadId ? `${baseUrl}/files/byuploadid` : `${baseUrl}/files`
    }
  }

  /**
   * Get form data for the attachment/file
   * @param {File} attachment
   * @returns {FormData} formData
   */
  private getFormData(attachment: File): FormData {
    const formData: FormData = new FormData()
    formData.append('file', attachment, attachment.name)
    formData.append('name', attachment.name)
    formData.append('filename', attachment.name)
    return formData
  }

  /**
   * Updates the entity in the store when the user adds/deletes an image to the entity
   * @param {ValidImageFileEntities} entity - The entity which has been updated
   * @param {ValidImageFileEntityTypes} entityName - The name of the entity
   */
  private updateEntityInStore(entity: ValidImageFileEntities, entityName: ValidImageFileEntityTypes): void {
    switch (entityName) {
      case 'companies':
        const companyEntity = entity as Company
        this.companyStore.update(companyEntity.id, companyEntity)
        this.projectStore.updateCompanyLogo(companyEntity.id)
        break
      case 'users':
        this.userStore.update(entity.id, entity)
        break
      case 'workspaces':
        this.workspaceStore.update(entity.id, entity)
        break
      case 'projects':
        this.projectStore.updateProjectLogo(entity.id)
        break
    }
  }

  /**
   * Returns the form data type for the entity image
   * @param {ValidImageFileEntityTypes} entityName - The name of the entity
   * @returns {string} - Form data type for the entity
   */
  private getFormDataTypeForEntityImage(entityName: ValidImageFileEntityTypes): string {
    switch (entityName) {
      case 'companies':
        return 'company-image'
      case 'users':
        return 'user-profile-image'
      case 'workspaces':
        return 'workspace-profile-image'
      default:
        return 'entity-image'
    }
  }

  /**
   * Returns the file name for the entity image
   * @param {ValidImageFileEntityTypes} entityName - The name of the entity
   * @returns {string} - File name for the entity
   */
  private getFileNameForEntityImageByUrl(entityName: ValidImageFileEntityTypes): string {
    switch (entityName) {
      case 'companies':
        return 'company-logo.png'
      default:
        return 'entity-image.png'
    }
  }

  /**
   * Downloads a file by url
   */
  downloadFile(url: string): void {
    let queryOptions = ''

    // To fetch the file also locally
    if (isLiveMobile) {
      queryOptions = '?'
      const account: AccountState = this.accountQuery.getAccount()
      if (account) {
        queryOptions += new URLSearchParams({ jwt: account.accessToken, 'aw-mobile': 'true' } as any).toString()
      }
    }

    window.open(`${apiEndpoint}${url}${queryOptions}`, '_self')
  }

  /**
   * Downloads a file's blob by url
   * @param {string} url
   * @returns {Observable<Blob>}
   */
  downloadBlob(url: string): Observable<Blob> {
    return this.apiClient.get<Blob>(url, { responseType: 'blob' })
  }

  /**
   * Track upload progress
   * @param fileUpload
   * @param event
   */
  trackFileUploadProgress(fileUpload: FileUpload, event: HttpEvent<FileUpload>): FileUpload {
    if (event.type === HttpEventType.UploadProgress) {
      // Calculate the progress in %
      fileUpload.progress = (event.loaded * 100) / event.total
    } else if (event.type === HttpEventType.Response) {
      // Map the response from API
      Object.assign(fileUpload, event.body)

      fileUpload.progress = 100
      fileUpload.status = FileUploadStatus.Complete
      fileUpload.username = this.userQuery.getCurrentUser()?.shortName

      this.onFileCreated(new FileUpload(fileUpload))

      // If the file is temporary, fileUpload has an entity and has to be linked (user saved the entity)
      // make the API call to link the temp file to the entity
      if (fileUpload.type === 'temp' && fileUpload.entity && fileUpload.needsLink) {
        this.setTemporaryFileToGlobalOrEntityFile(fileUpload, fileUpload.entityType, fileUpload.entity).subscribe()
      }
    }

    // Show the file upload progress toast
    this.fileUploading.next(fileUpload)

    return fileUpload
  }

  /**
   * Generates an excel export for Time Tracking report
   * @param {TimeTrackingReport} timeTrackingReport
   * @param {'raw' | 'original'} exportMode
   * @param {boolean} tempReport - True to send the actual (unsaved) report
   * @returns {Observable<void>}
   */
  sendGenerateReportingExport(
    timeTrackingReport: TimeTrackingReport,
    exportMode: 'raw' | 'original',
    tempReport: true
  ): Observable<void> {
    if (tempReport) {
      // remove id from the request to avoid failures in API as it expects an id
      if (timeTrackingReport.id === null) {
        delete timeTrackingReport.id
      }

      return this.apiClient.post<void>(`${this.globalUrl}/timeReports/generateExport`, {
        exportMode,
        timeReport: timeTrackingReport
      })
    } else {
      return this.apiClient.post<void>(`${this.globalUrl}/timeReports/${timeTrackingReport.id}/generateExport`, {
        exportMode
      })
    }
  }

  /**
   * Send Task List Export
   * @param {'projects' | 'me/taskviews' | 'me/allavailabletasks'} entity
   * @param {string} entityId
   * @param {'tasks' | 'projecttasks'} target
   */
  sendGenerateTaskListExport(
    entity: 'projects' | 'me/taskviews' | 'me/allavailabletasks',
    entityId: string,
    target: 'tasks' | 'projecttasks'
  ): Observable<void> {
    if (entity === 'me/allavailabletasks') {
      return this.apiClient.post<void>(`${this.globalUrl}/tasks/generateexport`, {
        filterUrl: `${entity}?${entityId}`
      })
    }

    return this.apiClient.post<void>(`${this.globalUrl}/tasks/generateexport`, {
      filterUrl: `${entity}/${entityId}/${target}`
    })
  }

  /**
   * Sends External files in a batch
   * @param {FileExternal[]} fileExternals
   * @param {ValidFileEntityTypes} entityName
   * @param {string} entityId
   * @returns {Observable<void>}
   */
  sendExternalFiles(
    fileExternals: FileExternal[],
    entityName: ValidFileEntityTypes,
    entityId: string
  ): Observable<FileUpload[]> {
    return this.apiClient.post<FileUpload[]>(`${this.url}/${entityName}/${entityId}/externalfiles`, fileExternals).pipe(
      tap(files => {
        const user = this.userQuery.getCurrentUser()

        files.map(file => {
          file.username = user?.shortName
          file = new FileUpload(file)

          this.onFileCreated(file)

          return file
        })
      })
    )
  }
}
