import { Injectable } from '@angular/core'
import { EntitySignalStore } from '@awork/core/state/signal-store/entitySignalStore'
import { SubscriptionPlan } from '@awork/_shared/models/subscription-plan.model'

@Injectable({ providedIn: 'root' })
export class SubscriptionPlanStore extends EntitySignalStore<SubscriptionPlan> {
  constructor() {
    super({
      name: 'subscriptionPlan',
      entityConstructor: subscriptionPlan => new SubscriptionPlan(subscriptionPlan)
    })
  }
}
