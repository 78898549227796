/**
 * Creates a map of all values of the array mapped by the specified property.
 * This is useful for looking-up in constant time whether an array contains an
 * object based on the property value.
 *
 * Example:
 * ```
 * > asMapBy([{ id: 1 }, { id: 2 }], 'id')
 * Map { 1 => true, 2 => true }
 * ```
 */
export function asMapBy<T, K extends keyof T>(array: T[], prop: K): Map<T[K], true> {
  const tuples = array.map(element => [element[prop], true] as [T[K], true])
  return new Map(tuples)
}

/**
 * Creates an array of all values that are included in both given arrays,
 * using same-value-zero equality comparisons for the specified property.
 *
 * Example:
 * ```
 * > intersectBy([{ id: 1 }, { id: 2 }], [{ id: 1 }, { id: 3 }], 'id')
 * [{ id: 1 }]
 * ```
 */
export function intersectBy<T, K extends keyof T>(array1: T[] = [], array2: T[] = [], prop: K): T[] {
  const valuesToBeKept = asMapBy(array1, prop)
  return array2.filter(element => {
    const value = element[prop]
    return valuesToBeKept.get(value)
  })
}

/**
 * Creates an array of all values from the first array without all values
 * from the second array, using same-value-zero equality comparisons for
 * the specified property.
 *
 * Example:
 * ```
 * > excludeBy([{ id: 1 }, { id: 2 }], [{ id: 1 }], 'id')
 * [{ id: 2 }]
 * ```
 */
export function excludeBy<T, K extends keyof T>(array1: T[] = [], array2: T[] = [], prop: K): T[] {
  const valuesToBeExcluded = asMapBy(array2, prop)
  return array1.filter(element => {
    const value = element[prop] ? element[prop] : element['id']
    return !valuesToBeExcluded.get(value)
  })
}

/**
 * Creates an array of all unique values that are included in the given array,
 * using same-value-zero equality comparisons for the specified property.
 *
 * Example:
 * ```
 * > uniqueBy([{ id: 1 }, { id: 2 }, { id: 1 }], 'id')
 * [{ id: 1 }, { id: 2 }]
 * ```
 */
export function uniqueBy<T, K extends keyof T>(array: T[] = [], prop: K): T[] {
  const seenValues = new Map<T[K], true>()
  return array.filter(element => {
    if (!element) {
      return false
    }

    const value = element[prop]
    const seen = seenValues.get(value)
    if (seen) {
      return false
    }

    seenValues.set(value, true)
    return true
  })
}

/**
 * Creates an array of all unique values that are included in the given array,
 *
 * Example:
 * ```
 * > uniqueBy([1, 2, 1 ])
 * [1, 2]
 * ```
 */
export function unique<T>(array: T[] = []): T[] {
  const seenValues = new Map<T, true>()
  return array.filter(element => {
    const seen = seenValues.get(element)
    if (!seen) {
      seenValues.set(element, true)
      return true
    } else {
      return false
    }
  })
}

/**
 * Creates an array of all unique values that are included in the given array.
 * The uniqueness of the items is determined by the predicate function.
 * @param {T[]} array
 * @param {(a: T, b: T) => boolean} predicate
 */
export function uniqueByPredicate<T>(array: T[], predicate: (a: T, b: T) => boolean): T[] {
  return array.filter((item, index, self) => index === self.findIndex(otherItem => predicate(item, otherItem)))
}

/**
 * Helper to filter all duplicates
 * Usage: on arr.filter() to get rid of all duplicates
 * @param value
 * @param index
 * @param self
 */
export function filterOutDuplicates(value, index, self) {
  return self.indexOf(value) === index
}

/**
 * Shuffles the items of a given array
 * @param array
 */
export function shuffleArray<T>(array: T[]): T[] {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    const temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
  return array
}

/**
 * Determines if the arrays are equal by quick checking.
 * @param {T[]} arrayA
 * @param {T[]} arrayB
 * @returns {boolean}
 */
export function areArraysEqualQuickCheck<T>(arrayA: T[], arrayB: T[]): boolean {
  if (arrayA === arrayB) {
    return true
  }

  if (!Array.isArray(arrayA) || !Array.isArray(arrayB)) {
    return false
  }

  if (!arrayA.length && !arrayB.length) {
    return true
  }

  if (arrayA.length !== arrayB.length) {
    return false
  }

  return undefined
}

/**
 * Determines if the arrays are equal by checking the item values
 * @param {T[]} arrayA
 * @param {T[]} arrayB
 * @param {boolean} ignoreOrder
 * @returns {boolean}
 */
export function areArraysEqual<T>(arrayA: T[], arrayB: T[], ignoreOrder?: boolean): boolean {
  const quickCheck = areArraysEqualQuickCheck(arrayA, arrayB)

  if (quickCheck !== undefined) {
    return quickCheck
  }

  if (ignoreOrder) {
    const arrayASet = new Set<T>(arrayA)
    return arrayB.every(item => arrayASet.has(item))
  }

  return arrayA.every((itemA, i) => itemA === arrayB[i])
}

/**
 * Divides an array into a specified number of equal parts.
 * @param {T[]} array
 * @param {number} numParts
 * @returns {T[][]}
 */
export function divideArray<T>(array: T[], numParts: number): T[][] {
  const result: T[][] = []
  const partSize = Math.ceil(array.length / numParts)

  for (let i = 0; i < array.length; i += partSize) {
    result.push(array.slice(i, i + partSize))
  }

  return result
}
