import { isDevelopCriterion, isLocalhostCriterion } from '@awork/_shared/functions/criteria/environment.criteria'

import { Criterion } from '@awork/_shared/functions/criteria/types'
import { InjectionToken } from '@angular/core'
import { Team } from './feature-flag.teams'
import { isAnyCriterionTrue } from '@awork/_shared/functions/criteria/composition.criteria'
import { isTeamAllowedCriterion } from '../functions/criteria/user.criteria'

export enum Feature {
  ExampleFeature = 'exampleFeature',
  NpsToastScheduling = 'npsToastScheduling',
  SkipAccountServiceUserCheck = 'skipAccountServiceUserCheck',
  ConnectDiscount = 'connectDiscount',
  OnboardingPlannerChecklist = 'onboardingPlannerChecklist',
  ConnectOnboardingChecklistGift = 'connectOnboardingChecklistGift'
}

export type FeatureFlags = { [key in Feature]: Criterion[] }
const featureFlags: FeatureFlags = {
  [Feature.ExampleFeature]: [isDevelopCriterion],
  [Feature.SkipAccountServiceUserCheck]: [() => true], // @Framework team, please remove
  [Feature.NpsToastScheduling]: [isAnyCriterionTrue([isDevelopCriterion, isLocalhostCriterion])],
  [Feature.ConnectDiscount]: [() => true],
  [Feature.OnboardingPlannerChecklist]: [
    isAnyCriterionTrue([isDevelopCriterion, isLocalhostCriterion, isTeamAllowedCriterion([Team.Growth])])
  ],
  [Feature.ConnectOnboardingChecklistGift]: [() => true]
}

export const FeatureFlagsToken = new InjectionToken<FeatureFlags>('FeatureFlags', {
  providedIn: 'root',
  factory: () => featureFlags
})
