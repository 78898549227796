import { Time } from '@angular/common'
import { User } from '@awork/features/user/models/user.model'
import { Project } from '@awork/features/project/models/project.model'
import { Company } from '@awork/features/company/models/company.model'
import { Task } from '@awork/features/task/models/task.model'
import {
  applyDifferenceToTime,
  ExtendedTime,
  stringToTime,
  stripMilliseconds,
  timeToString
} from '@awork/_shared/functions/time-operations'
import {
  addSeconds,
  differenceInSeconds,
  format,
  isValid,
  parseISO,
  setHours,
  setMinutes,
  setSeconds
} from '@awork/_shared/functions/date-fns-wrappers'
import { WorkType } from '@awork/features/task/models/work-type.model'
import { ProjectMember } from '@awork/features/project/models/project-member.model'
import { convertToUTC, convertToTimezone } from '@awork/_shared/functions/date-operations'

export enum ValidTimeTrackingEntityTypesList {
  project = 'project',
  user = 'user',
  task = 'task',
  company = 'company'
}

export type ValidTimeTrackingEntityTypes = keyof typeof ValidTimeTrackingEntityTypesList
export type TimelineFilters = 'week' | 'month' | 'year'
export type AggregationOptions = 'day' | 'week' | 'month'

export enum TimeTrackingStatuses {
  billed = 'billed',
  notBilled = 'notBilled',
  notBillable = 'notBillable'
}

export interface Break {
  startDate: Date
  endDate?: Date
  duration?: number
}

export interface TimeTrackingEntityAggregatedTimes {
  date: Date | string
  totalDuration: number
  typeOfWorkId?: string
  isBillable?: boolean
  isBilled?: boolean
  projectId?: string
  userId?: string
  companyId?: string
  secondaryDuration?: number
}

interface ITimeTracking {
  id: string
  note?: string
  timezone: string
  startDate?: string | Date
  endDate?: string | Date
  isDateOnly?: boolean
  startTimeUtc?: Time | string
  endTimeUtc?: Time | string
  startDateLocal?: Date | string
  endDateLocal?: Date | string
  startTimeLocal?: Time | string
  endTimeLocal?: Time | string
  duration?: number
  isBillable?: boolean
  isBilled?: boolean
  typeOfWorkId: string
  typeOfWork: WorkType
  userId: string
  user: User
  taskId?: string
  task?: Task
  projectId?: string
  project?: Project
  companyId?: string
  company?: Company
  suggestionType?: string
  updatedByUser?: User
  createdByUser?: User
  createdOn?: Date
  updatedOn?: Date
  resourceVersion?: number
  modifiedOn?: number
  breakDuration?: number
  breaks?: Break[]
  isExternal?: boolean
}

export class TimeTracking implements ITimeTracking {
  id: string
  note?: string
  timezone: string
  startDate?: string | Date
  endDate?: string | Date
  isDateOnly: boolean = true
  duration?: number
  isBillable?: boolean
  isBilled?: boolean
  typeOfWorkId: string
  typeOfWork: WorkType
  userId: string
  user: User
  taskId?: string
  task?: Task
  projectId?: string
  project?: Project
  companyId?: string
  company?: Company
  suggestionType?: string
  updatedByUser?: User
  createdByUser?: User
  createdOn?: Date
  updatedOn?: Date
  resourceVersion?: number
  toBeDeleted?: boolean
  modifiedOn?: number
  breakDuration?: number
  breaks?: Break[]
  isExternal?: boolean

  // TODO: Keep deprecated properties for backwards compatibility
  /**
   * @deprecated Use `startDate` instead.
   */
  startTimeUtc?: Time | string
  /**
   * @deprecated Use `endDate` instead.
   */
  endTimeUtc?: Time | string
  /**
   * @deprecated Use `startDate` instead.
   */
  startDateLocal?: Date | string
  /**
   * @deprecated Use `endDate` instead.
   */
  endDateLocal?: Date | string
  /**
   * @deprecated Use `startDate` instead.
   */
  startTimeLocal?: Time | string
  /**
   * @deprecated Use `endDate` instead.
   */
  endTimeLocal?: Time | string

  constructor(spec: Partial<ITimeTracking>) {
    Object.assign(this, spec)

    this.setDates()
    this.setTimes()
    this.mapProperties()

    if (!this.breakDuration && this.breaks) {
      this.setBreakDuration()
    }

    if (!!this.startTimeLocal) {
      this.isDateOnly = false
    }
  }

  /**
   * Returns true if the time tracking is active (running or paused)
   */
  get isActive(): boolean {
    return this.duration === 0 && !this.endTimeUtc
  }

  /**
   * Returns true if the time tracking is running
   */
  get isRunning(): boolean {
    return this.isActive && !this.isPaused
  }

  /**
   * Returns true if the time tracking is paused
   */
  get isPaused(): boolean {
    return this.isActive && !!this.currentBreak
  }

  /**
   * Sets the break duration based on the sum of the break durations
   */
  setBreakDuration(): void {
    this.breakDuration = this.breaks.reduce(
      (sum, timeBreak) => (timeBreak.duration ? sum + timeBreak.duration : sum),
      0
    )
  }

  /**
   * Returns the duration of the current break
   */
  get currentBreakDuration(): number {
    return differenceInSeconds(new Date(), this.currentBreak?.startDate) || 0
  }

  /**
   * Returns the total duration of the current and past breaks
   */
  get totalBreakDuration(): number {
    return (this.breakDuration || 0) + this.currentBreakDuration
  }

  /**
   * Returns the duration + total break duration
   */
  get totalDuration(): number {
    return this.duration + (this.totalBreakDuration || 0)
  }

  get currentBreak(): Break {
    return this.breaks?.find(b => !b.endDate)
  }

  get bestDescription(): string {
    if (this.task) {
      return this.task.name
    } else if (this.note && this.note.length > 1) {
      return this.note
    } else {
      return ''
    }
  }

  /**
   * Calculates the current duration of the running time tracking
   */
  get runningDuration(): number {
    if (this.isActive && this.startTimeLocal) {
      const runningStartTime =
        typeof this.startTimeLocal === 'string'
          ? (stringToTime(this.startTimeLocal, true) as ExtendedTime)
          : { ...this.startTimeLocal, seconds: 0 }
      const runningStartDateTime = new Date(this.startDateLocal as string)

      runningStartDateTime.setHours(runningStartTime.hours)
      runningStartDateTime.setMinutes(runningStartTime.minutes)
      runningStartDateTime.setSeconds(runningStartTime.seconds)
      return differenceInSeconds(new Date(), runningStartDateTime) - this.totalBreakDuration
    }

    return 0
  }

  set startTime(time: string) {
    if (!time) {
      return
    }
    const [hours, minutes, seconds] = time.split(':').map(Number)
    let date = parseISO(this.startDate)
    date = setHours(date, hours)
    date = setMinutes(date, minutes)
    date = setSeconds(date, seconds)
    this.startDate = date
  }

  get startTime(): string {
    if (!this.startDate || !isValid(this.startDate)) {
      return ''
    }
    const date = parseISO(this.startDate)

    return format(date, 'HH:mm:ss')
  }

  get endTime(): string {
    if (!this.endDate || !isValid(this.endDate)) {
      return ''
    }
    const date = parseISO(this.endDate)
    return format(date, 'HH:mm:ss')
  }

  get status(): TimeTrackingStatuses {
    if (this.isBilled) {
      return TimeTrackingStatuses.billed
    } else if (this.isBillable) {
      return TimeTrackingStatuses.notBilled
    } else {
      return TimeTrackingStatuses.notBillable
    }
  }

  /**
   * Create an empty time tracking with default values
   * @returns {TimeTracking}
   */
  static createEmpty(timezone: string) {
    return new TimeTracking({
      duration: 3600, // 1 hour default
      timezone,
      startDate: new Date().toISOString(),
      endDate: new Date().toISOString(),
      isBillable: false,
      isBilled: false
    } as TimeTracking)
  }

  /**
   * Sets the start and end times for both UTC and local time.
   * If the object is date-only, the function returns immediately.
   * Strips milliseconds from the start and end times if they are strings.
   * If the duration is set, calculates the missing start or end times based on the total duration.
   */
  setTimes() {
    if (this.isDateOnly) {
      return
    }
    if (this.startTimeUtc && typeof this.startTimeUtc === 'string') {
      this.startTimeUtc = stripMilliseconds(this.startTimeUtc as string)
    }

    if (this.startTimeLocal && typeof this.startTimeLocal === 'string') {
      this.startTimeLocal = stripMilliseconds(this.startTimeLocal as string)
    }

    if (this.endTimeUtc && typeof this.endTimeUtc === 'string') {
      this.endTimeUtc = stripMilliseconds(this.endTimeUtc as string)
    }

    if (this.endTimeLocal && typeof this.endTimeLocal === 'string') {
      this.endTimeLocal = stripMilliseconds(this.endTimeLocal as string)
    }

    if (this.duration) {
      if (!this.startTimeLocal && this.endTimeLocal) {
        this.startTimeLocal = applyDifferenceToTime(this.endTimeLocal, this.totalDuration * -1)
        this.startTimeUtc = applyDifferenceToTime(this.endTimeUtc, this.totalDuration * -1)
      } else if (!this.endTimeLocal && this.startTimeLocal) {
        this.endTimeLocal = applyDifferenceToTime(this.startTimeLocal, this.totalDuration)
        this.endTimeUtc = applyDifferenceToTime(this.startTimeUtc, this.totalDuration)
      }
    }
  }

  /**
   * Calculates the total duration from an array of time tracking objects.
   *
   * @param {TimeTracking[]} timeTrackings - An array of TimeTracking objects.
   * @returns {number} The total duration of all time tracking objects.
   */
  static getTimeTrackingsDuration(timeTrackings: TimeTracking[]): number {
    return timeTrackings.reduce((total, timeTracking) => total + timeTracking.duration, 0)
  }

  /**
   * Sets the end date based on the start date and duration.
   * If the end date is not set and the duration and start date are available,
   * or if forceUpdate is true, or if the entry is a V1 entry, the end date is calculated.
   *
   * @param {boolean} [forceUpdate=false] - If true, forces the update of the end date.
   */
  setEndDate(forceUpdate = false): void {
    // Date after migration
    if ((!this.endDate && this.duration && this.startDate) || forceUpdate || this.isV1Entry) {
      const startDate = !!this.startDate ? new Date(this.startDate) : convertToUTC(new Date(), this.timezone)

      this.endDate = !this.isDateOnly
        ? addSeconds(startDate, this.totalDuration).toISOString()
        : startDate.toISOString()
    }
  }

  /**
   * Sets the local start and end dates based on the UTC dates and the specified timezone.   *
   * @param {boolean} [forceUpdate=false] - If true, forces the update of the end date.
   */
  private setLocalDates(forceUpdate = false): void {
    // Set the initial endDateLocal based on the endDate
    if (!this.endDateLocal && isValid(this.endDate)) {
      const newEndDate = this.endDate ? convertToTimezone(this.endDate, this.timezone) : this.endDateLocal

      if (!!newEndDate && isValid(newEndDate)) {
        this.endDateLocal = newEndDate
        this.endTimeLocal = !this.isDateOnly ? format(newEndDate, 'HH:mm:ss') : undefined
      }
    }

    // Set the initial startDateLocal based on the startDate

    if (!this.startDateLocal && isValid(this.startDate)) {
      const newStartDate = this.startDate ? convertToTimezone(this.startDate, this.timezone) : this.startDateLocal

      if (!!newStartDate && isValid(newStartDate)) {
        this.startDateLocal = newStartDate
        this.startTimeLocal = !this.isDateOnly ? format(newStartDate, 'HH:mm:ss') : undefined
      }
    }

    // Local Dates calculations
    this.localDatesCalculations(forceUpdate)
  }

  /**
   * Updates the local date properties (`startDateLocal` and `endDateLocal`) to ensure they are
   * properly converted to the specified timezone or calculated based on other properties.
   * @param {boolean} [forceUpdate=false] - Forces the recalculation of `endDateLocal` even if it is already set.
   * @private
   */
  private localDatesCalculations(forceUpdate = false): void {
    if (this.startDateLocal && !(this.startDateLocal instanceof Date)) {
      this.startDateLocal = convertToTimezone(new Date(this.startDateLocal), this.timezone)
    }

    if (this.endDateLocal && !(this.endDateLocal instanceof Date)) {
      this.endDateLocal = convertToTimezone(new Date(this.endDateLocal), this.timezone)
    } else if ((!this.endDateLocal && this.duration && this.startDateTimeLocal) || forceUpdate) {
      this.endDateLocal = addSeconds(this.startDateTimeLocal, this.totalDuration)
    }
  }

  /**
   * Sets the end date and local dates for the time tracking entry.
   * If the createdOn date is set, it converts it to a Date object.
   *
   * @param {boolean} [forceUpdate=false] - If true, forces the update of the end date and local dates.
   */
  setDates(forceUpdate = false) {
    this.setEndDate(forceUpdate)
    this.setLocalDates(forceUpdate)

    if (this.createdOn) {
      this.createdOn = new Date(this.createdOn)
    }
  }

  get startDateTimeUtc(): Date {
    return this.getDateTime('start')
  }

  get startDateTimeLocal(): Date {
    return this.getDateTime('start', false)
  }

  get endDateTimeLocal(): Date {
    return this.getDateTime('end', false)
  }

  get projectMember(): ProjectMember {
    return this.project?.members?.find(({ userId }) => userId === this.userId)
  }

  /* Checks if the time entry was created in V1 without start and end date
   */
  get isV1Entry(): boolean {
    let endDateStr = this.endDate
    let startDateStr = this.startDate

    if (this.endDate instanceof Date && !isNaN(this.endDate.getTime())) {
      endDateStr = this.endDate.toISOString()
    }

    if (this.startDate instanceof Date && !isNaN(this.startDate.getTime())) {
      startDateStr = this.startDate.toISOString()
    }
    return endDateStr === startDateStr && !!this.duration
  }

  /**
   * Gets the start/end date with the start/end time
   * @param {'start' | 'end'} period
   * @param {boolean} utc
   * @return {Date}
   */

  getDateTime(period: 'start' | 'end', utc = true): Date {
    let date: string | Date
    let time: string | Time

    if (period === 'start') {
      date = utc ? this.startDate : this.startDateLocal
      time = utc ? this.startTimeUtc : this.startTimeLocal
    } else if (period === 'end') {
      date = utc ? this.endDate : this.endDateLocal
      time = utc ? this.endTimeUtc : this.endTimeLocal
    }

    if (date && time) {
      const newDate = new Date(date as Date)
      let newTime: Time
      if (typeof time === 'string') {
        newTime = stringToTime(time as string)
      } else {
        newTime = time
      }

      newDate.setHours(newTime.hours)
      newDate.setMinutes(newTime.minutes)
      newDate.setSeconds(0)

      return newDate
    } else if (date) {
      const newDate = new Date(date as Date)

      newDate.setHours(0)
      newDate.setMinutes(0)
      newDate.setSeconds(0)

      return newDate
    }

    return null
  }

  /**
   * Maps the nested properties
   */
  mapProperties(): void {
    if (this.user) {
      this.user = new User(this.user, 'TimeTracking.mapProperties')
    }

    if (this.project) {
      this.project = Project.mapProject(this.project)
    }

    if (this.task) {
      this.task = Task.mapTask(this.task)
    }

    if (this.breaks) {
      this.breaks = this.breaks.map(timeBreak => ({
        ...timeBreak,
        startDate: new Date(timeBreak.startDate),
        endDate: timeBreak.endDate ? new Date(timeBreak.endDate) : undefined
      }))
    }
  }

  /**
   * Returns true if the time tracking doesn't have a project
   * @returns {boolean}
   */
  get isPrivate(): boolean {
    return !this.project && !this.task?.project
  }

  /**
   * Sets the `isDateOnly` property based on the start and end times.
   * It also sets the start and end times based on the `isDateOnly` property.
   */
  static setIsDateOnly(timeTracking: Partial<ITimeTracking>): void {
    const hasStartAndEndTime = !!timeTracking.startTimeLocal && !!timeTracking.endTimeLocal
    timeTracking.isDateOnly = !hasStartAndEndTime

    const times = {
      startTime:
        typeof timeTracking.startTimeLocal === 'string'
          ? timeTracking.startTimeLocal
          : timeToString(timeTracking.startTimeLocal),
      endTime:
        typeof timeTracking.endTimeLocal === 'string'
          ? timeTracking.endTimeLocal
          : timeToString(timeTracking.endTimeLocal)
    }

    timeTracking.startTimeLocal = timeTracking.isDateOnly ? undefined : times.startTime
    timeTracking.endTimeLocal = timeTracking.isDateOnly ? undefined : times.endTime
  }
}
