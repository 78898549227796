import { ComponentRef, Injectable, inject, signal } from '@angular/core'
import { OnboardingPopup, OnboardingPopupKey } from './types'

import { ConnectedPosition } from '@angular/cdk/overlay'
import { ONBOARDING_POPUP_POSITION } from '../../../../_shared/components/layout/popup/positions'
import { OnboardingPopupComponent } from './onboarding-popup.component'
import { PopupService } from '../../../../_shared/components/layout/popup/popup.service'
import { Router } from '@angular/router'
import { take } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class OnboardingPopupService {
  private popupService = inject(PopupService)
  private router = inject(Router)

  private readonly _popup = signal<{
    key: OnboardingPopupKey
    component: OnboardingPopupComponent
    ref: ComponentRef<OnboardingPopupComponent>
  }>(undefined)
  readonly popup = this._popup.asReadonly()

  /**
   * Add an onboarding popup with the given key if it hasn't been dismissed
   * @param key Unique identifier for the onboarding popup
   * @param popup The onboarding popup
   */
  show(key: OnboardingPopupKey, popup: OnboardingPopup, position: ConnectedPosition = ONBOARDING_POPUP_POSITION): void {
    if (this._popup()?.key === key) {
      return
    }

    this.clear()

    const [ref, component] = OnboardingPopupComponent.renderPopup({ popup, position })

    this.router.events.pipe(take(1)).subscribe(() => {
      this.clear()
    })

    this._popup.set({ key, component, ref })
    this.popupService.addPopup(component)
  }

  /**
   * Remove all onboarding popups
   */
  clear(): void {
    this._popup()?.ref.destroy()
    this.popupService.removePopup(this._popup()?.component)

    this._popup.set(undefined)
  }
}
