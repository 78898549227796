import { computed, Injectable, Signal } from '@angular/core'
import { EntitySignalQuery } from '@awork/core/state/signal-store/entitySignalQuery'
import {
  SubscriptionPlan,
  FeatureId,
  PlanFeature,
  PlanId,
  PlanPricing,
  PlanTier,
  CustomDomainAddon,
  PlanCustomDomainAddons
} from '@awork/_shared/models/subscription-plan.model'
import { SubscriptionPlanStore } from '@awork/_shared/state/subscription-plan.store'
import { SubscriptionQuery } from '@awork/_shared/state/subscription.query'
import { Order } from '@awork/core/state/signal-store/types'

interface Filters {
  newPlans: boolean
  bookable: boolean
  includeConnectPlans: boolean
}

@Injectable({ providedIn: 'root' })
export class SubscriptionPlanQuery extends EntitySignalQuery<SubscriptionPlan> {
  constructor(
    protected store: SubscriptionPlanStore,
    private subscriptionQuery: SubscriptionQuery
  ) {
    super(store)
  }

  /**
   * Gets the subscription plans
   * @param { Partial<Filters>} filters
   * @returns {Signal<SubscriptionPlan[]>}
   */
  queryPlans(filters: Partial<Filters> = {}): Signal<SubscriptionPlan[]> {
    return computed(() => {
      const plans = this.queryAll({
        filterBy: plan =>
          this.filterByNew(plan, filters) &&
          this.filterByBookable(plan, filters) &&
          this.filterByConnectPlans(plan, filters),
        sortBy: 'tier',
        sortByOrder: Order.ASC
      })

      return this.mapEntities(plans())
    })
  }

  /**
   * Gets the subscription plan of the current plan
   * @returns {Signal<SubscriptionPlan>}
   */
  queryCurrentPlan(): Signal<SubscriptionPlan> {
    return computed(() => {
      const currentPlan = this.subscriptionQuery.querySubscription()

      const plan = this.queryAll({
        filterBy: plan => plan.pricing.some(p => p.planId === currentPlan().planId)
      })

      return this.mapEntity(plan()?.[0])
    })
  }

  /**
   * Queries a subscription plan that matches the provided pricing id
   * @param {string} pricingId
   * @returns {Signal<SubscriptionPlan>}
   */
  queryPlanByPricingId(pricingId: string): Signal<SubscriptionPlan> {
    return computed(() => {
      const plan = this.queryAll({
        filterBy: plan => plan.pricing.some(p => p.planId === pricingId)
      })

      return this.mapEntity(plan()?.[0])
    })
  }

  /**
   * Gets the pricing of the current plan
   * @returns {Signal<PlanPricing>}
   */
  queryCurrentPlanPricing(): Signal<PlanPricing> {
    return computed(() => {
      const currentPlan = this.subscriptionQuery.querySubscription()

      const plans = this.queryAll()

      // Find the pricing with the same planId as the current plan
      for (const plan of plans()) {
        const pricing = plan.pricing.find(p => p.planId === currentPlan().planId)
        if (pricing) {
          return pricing
        }
      }

      return undefined
    })
  }

  /**
   * Gets the pricing of the plan with the given planId that matches the current plan's currency
   * @param {PlanId} planId
   * @returns {Signal<PlanPricing[]>}
   */
  queryAvailablePricing(planId: PlanId): Signal<PlanPricing[]> {
    return computed(() => {
      const plan = this.queryEntity(planId)
      const currentPlanPricing = this.queryCurrentPlanPricing()

      return plan()?.pricing.filter(p => p.currencyCode === currentPlanPricing().currencyCode)
    })
  }

  /**
   * Gets the restricted feature from the current plan
   * @param {FeatureId} featureId
   * @returns {Signal<PlanFeature>}
   */
  queryPlanFeature(featureId: FeatureId): Signal<PlanFeature> {
    return computed(() => {
      const plan = this.queryCurrentPlan()

      return plan()?.features.find(feature => feature.id === featureId)
    })
  }

  /**
   * Determines if a feature is available in the current plan
   * @param {FeatureId} featureId
   * @param {number} currentCount
   * @returns {Signal<boolean>}
   */
  queryIsPlanFeatureAvailable(featureId: FeatureId, currentCount?: number): Signal<boolean> {
    return computed(() => {
      const currentSubscription = this.subscriptionQuery.querySubscription()

      if (currentSubscription()?.planId?.includes(PlanId.Internal)) {
        return true
      }

      const planFeature = this.queryPlanFeature(featureId)

      if (currentCount !== undefined) {
        return (
          planFeature()?.available && (planFeature().maxCount > currentCount || planFeature().maxCount === undefined)
        )
      }

      return planFeature()?.available
    })
  }

  /**
   * Gets the higher plan with the feature available.
   * If the feature is available in the current plan but has a maxCount, it will return a plan with a higher maxCount.
   * If the feature is available in the current plan but has no maxCount, it will return the current plan.
   * If the feature is not available in the current plan, it will return the first plan with the feature available.
   * @param {FeatureId} featureId
   * @returns {Signal<SubscriptionPlan>}
   */
  queryHigherPlanWithFeatureAvailable(featureId: FeatureId): Signal<SubscriptionPlan> {
    return computed(() => {
      const currentPlanFeature = this.queryPlanFeature(featureId)
      const plans = this.queryPlans({ newPlans: true, bookable: true })

      if (currentPlanFeature()?.available && currentPlanFeature().maxCount) {
        return plans().find(plan => {
          const feature = plan.features.find(feature => feature.id === featureId)
          return (
            feature?.available && (feature?.maxCount > currentPlanFeature().maxCount || feature?.maxCount === undefined)
          )
        })
      } else if (currentPlanFeature()?.available) {
        const currentPlan = this.queryCurrentPlan()
        return currentPlan()
      }

      return plans().find(plan => {
        const feature = plan.features.find(feature => feature.id === featureId)
        return feature?.available
      })
    })
  }

  /**
   * Determines if the plan is enterprise tier
   * @param {PlanId} planId
   * @returns {Signal<boolean>}
   */
  queryIsPlanEnterpriseTier(planId: PlanId): Signal<boolean> {
    return computed(() => {
      const plan = this.queryEntity(planId)

      return plan()?.tier === PlanTier.Enterprise || plan()?.tier === PlanTier.EnterprisePlus
    })
  }

  /**
   * Determines if the plan is connect
   * @returns {Signal<boolean>}
   */
  queryIsCurrentPlanConnect(): Signal<boolean> {
    return computed(() => {
      const currentPlan = this.queryCurrentPlan()

      return currentPlan()?.id.includes('connect')
    })
  }

  /**
   * Determines the custom domain addon that applies for the current subscription pricing
   * @returns {Signal<CustomDomainAddon>}
   */
  queryPlanCustomDomainAddon(): Signal<CustomDomainAddon> {
    return computed(() => {
      const currentPlanPricing = this.queryCurrentPlanPricing()

      return PlanCustomDomainAddons[currentPlanPricing().currencyCode]?.addonId
    })
  }

  /**
   * Determines if a plan is an old plan
   * @param {SubscriptionPlan} plan
   * @returns {boolean}
   */
  isOldPlan(plan: SubscriptionPlan): boolean {
    return this.filterByNew(plan, { newPlans: false })
  }

  /**
   * Determines if a plan is a new plan
   * @param {SubscriptionPlan} plan
   * @returns {boolean}
   */
  isNewPlan(plan: SubscriptionPlan): boolean {
    return this.filterByNew(plan, { newPlans: true })
  }

  /**
   * Filters the plans by new plans
   * @param {SubscriptionPlan} plan
   * @param {Partial<Filters>} filters
   * @returns {boolean}
   */
  private filterByNew(plan: SubscriptionPlan, filters: Partial<Filters>): boolean {
    if (filters?.newPlans === undefined) {
      return true
    }

    const newPlansIdentifier = '2025'
    const isNewPlan = plan.id.includes(newPlansIdentifier)

    return filters.newPlans ? isNewPlan : !isNewPlan
  }

  /**
   * Filters the plans by bookable
   * @param {SubscriptionPlan} plan
   * @param {Partial<Filters>} filters
   * @returns {boolean}
   */
  private filterByBookable(plan: SubscriptionPlan, filters: Partial<Filters>): boolean {
    if (filters?.bookable === undefined) {
      return true
    }

    return filters.bookable ? plan.isBookable : !plan.isBookable
  }

  /**
   * Filters the plans by include connect plans option
   * @param {SubscriptionPlan} plan
   * @param {Partial<Filters>} filters
   * @returns {boolean}
   */
  private filterByConnectPlans(plan: SubscriptionPlan, filters: Partial<Filters>): boolean {
    if (filters?.includeConnectPlans === undefined) {
      return true
    }

    const isConnectPlan = plan.id === PlanId.Connect2024 || plan.id === PlanId.Connect2025

    return filters.includeConnectPlans ? isConnectPlan : !isConnectPlan
  }
}
