import { SubscriptionState, SubscriptionStore } from '@awork/_shared/state/subscription.store'
import { computed, Injectable, Signal } from '@angular/core'
import { map, Observable } from 'rxjs'
import { QSubscription } from '@awork/_shared/models/subscription.model'
import {
  FeatureRestrictions,
  teamFeatureRestrictions,
  businessFeatureRestrictions,
  connectFeatureRestrictions
} from '../models/feature-restriction.types'
import { Plan, PlanTier } from '../models/subscription.types'
import isTest from '@awork/_shared/functions/is-test'
import { SignalQuery } from '@awork/core/state/signal-store/signalQuery'
import { PlanStatus } from '../models/subscription.types'

@Injectable({ providedIn: 'root' })
export class SubscriptionQuery extends SignalQuery<SubscriptionState> {
  constructor(protected store: SubscriptionStore) {
    super(store)
  }

  selectSubscription(): Observable<QSubscription> {
    return this.select().pipe(map(state => new QSubscription(state)))
  }

  getSubscription(): QSubscription {
    return new QSubscription(this.getValue())
  }

  /**
   * Gets the current subscription
   */
  querySubscription(): Signal<QSubscription> {
    return computed(() => {
      const subscription = this.query()
      return new QSubscription(subscription())
    })
  }

  /**
   * Determines if the current plan is paid
   * @returns {boolean}
   */
  isPaidPlan(): boolean {
    const plan = this.getSubscription()

    if (!plan) {
      return true
    }

    return !plan.isPlan(Plan.NewFree) && !plan.isPlan(Plan.Free) && !plan.isConnectPlan
  }

  /**
   * Determines if the free premium trial is expired
   */
  isTrialExpired(): boolean {
    const plan = this.getSubscription()

    return (
      this.isPaidPlan() &&
      plan.trialEndDays === 0 &&
      plan.status === PlanStatus.Cancelled &&
      plan.previousStatus === PlanStatus.InTrial
    )
  }

  /**
   * Determines if the current plan is in trial
   */
  isInTrial(): boolean {
    const plan = this.getSubscription()

    if (!plan) {
      return false
    }

    return this.isPaidPlan() && !!plan.isPlanStatus(PlanStatus.InTrial) && !!(plan.trialEndDays > 0)
  }

  /**
   * Determines if the current plan is Enterprise
   */
  isEnterprise(): boolean {
    const plan = this.getSubscription()

    return plan.planId?.includes('enterprise')
  }

  /**
   * Determines if the current plan has a feature available
   * @param awSubscriptionFeature
   */
  isFeatureAvailable(awSubscriptionFeature: FeatureRestrictions) {
    const plan = this.getSubscription()
    const subscriptionHasNoPlan = !plan?.planId

    // Prevent check in test environment if subscription is not set
    // otherwise, the subscription needs to be mocked in every test where this function is called
    if (subscriptionHasNoPlan && isTest()) {
      return true
    }

    if (subscriptionHasNoPlan) {
      return false
    }

    const planTier = plan.getPlanTier()

    switch (planTier) {
      case PlanTier.Free:
      case PlanTier.Enterprise:
        return true
      case PlanTier.Team:
        return (
          !teamFeatureRestrictions.includes(awSubscriptionFeature) &&
          !businessFeatureRestrictions.includes(awSubscriptionFeature)
        )
      case PlanTier.Business:
        // This is a special case when the user is basic connect but are currently
        // in the business plan trial
        if (plan.isBasicConnectInTrial) {
          return !connectFeatureRestrictions.includes(awSubscriptionFeature)
        }

        return !businessFeatureRestrictions.includes(awSubscriptionFeature)
      case PlanTier.Connect:
        return !connectFeatureRestrictions.includes(awSubscriptionFeature)
    }
  }
}
